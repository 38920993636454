import { ownerAddForm, updateClient, owners, deleteOwner, isCurrentUserAdmin } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Edit from '@mui/icons-material/Edit';
import Delete  from '@mui/icons-material/Delete';
import EventNoteIcon from '@mui/icons-material/EventNote'
import { MenuItem, Box, Typography, TextField, Select } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import Download from "@mui/icons-material/Download";
import EditingOwnerModal from "./editingownermodal.component";
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice her

const Owners = (props) => {
    const [showModalEditing, setShowModalEditing] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [tagList, setTagList] = useState();
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [currentRow, setCurrentRow] = useState({});
    const [tableData, setTableData] = useState([]);
    const [countryList, setCountryList] = useState({});
    const [apiData, setApiData] = useState({
        countryList: []
    })
    const [formData, setFormData] = useState({
        id: "",
        fiscalCode:"",
        firstname: "",
        lastname: "",
        propertyNames:"",
        email: "",
        cell: "",
        address: "",
        city: "",
        formErrors: { email: '', cell: '' },
        fromComponent: "ownersList"
    });

   
    const realoadList = (data) => {
        setTagList(data);
    };


    useEffect(() => {
        dispatch(owners())
            .then((data) => {
                setTagList(data);
                setLoading(false);
            })
            .catch(() => {
                console.log("Error");
            });
            dispatch(ownerAddForm())
            .then((data) => {
                setApiData(data);
            })
            .catch(() => {
                console.log("Error");
            });
    }, []);

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event)
                            : cell.column.id === 'cell' && cell.value !== ""
                                ? validateCell(event)
                                : false
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `Il campo ${cell.column.columnDef.header} non è valido`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [{
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false
        },
        
        {
            accessorKey: 'propertyNames',
            header: 'Proprietà'
        },
        {
            accessorKey: 'fiscalCode',
            header: 'C.F.'
        },
        {
            accessorKey: 'firstname',
            header: 'Nome'
        },
        {
            accessorKey: 'lastname',
            header: 'Cognome'
        },
        {
            accessorKey: 'email',
            header: 'Email',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'email',
            }),
        },
        {
            accessorKey: 'cell',
            header: 'Cellulare',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell)
            })
        },
        {
            accessorKey: 'address',
            header: 'Indirizzo'
        }
            ,
        {
            accessorKey: 'city',
            header: 'città'
        }

        ],
        [getCommonEditTextFieldProps],
    );

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        tagList[row.index] = values;;
        if (values['cell'] === "") {
            values['cell'] = null;
        }
        dispatch(updateClient(values))
            .then((data) => {
                //window.location.reload();
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        setTagList([...tagList]);
        exitEditingMode();
    };



    const validateCell = (e) => {
        var passwdRex = /^\d+$/;
        let ret = true;
        let validate = formData.formErrors;
        if (passwdRex.test(e.target.value)) {
            validate.cell = 'has-success';
        } else {
            validate.cell = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };


    const validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = formData.formErrors;

        if (emailRex.test(e.target.value)) {
            validate.email = 'has-success';
        } else {
            validate.email = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const toggleModalEditing = (row) => {

        if (row != null && row.original) {
            setFormData({
                ...formData,
                id: row.original.id,
                fiscalCode: row.original.fiscalCode,
                firstname: row.original.firstname,
                lastname: row.original.lastname,
                email: row.original.email,
                cell: row.original.cell,
                address: row.original.address,
                countryId: row.original.countryId,
                country: row.original.country,
                regionId: row.original.regionId,
                region: row.original.region,
                cityId: row.original.cityId,
                city: row.original.city,
                info: row.original.info,
                fromComponent: "ownersList"
            });
        } else {
            setFormData({
                id: "",
                firstname: "",
                lastname: "",
                fiscalCode:"",
                email: "",
                cell: "",
                info: "",
                address:"",
                country: "",
                countryId: "",
                city: "",
                cityId: "",
                region: "",
                regionId: "",
                formErrors: { email: '', cell: '' },
                fromComponent: "ownersList"
            });
        }
        setShowModalEditing(!showModalEditing)
    };
    
    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(`Sei sicuro di voler cancellare il proprietario ${row.getValue('lastname')}`)
            ) {
                return;
            }
            dispatch(deleteOwner(row.getValue('id')))
                .then((data) => {
                    dispatch(owners())
                    .then((data) => {
                        realoadList(data);
                    })
                    .catch(() => {
                        console.log("Error");
                    });
                })
                .catch(() => {
                    console.log("Error");
                });
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData],
    );

    const handleDownloadProperty = (rows) => {
        if(!isCurrentUserAdmin()) {
            alert("Operazione non consentita");
            return;
        }
        if (rows.length == 0) {
            alert("Non è stato selezionato alcun record!");
            return;
        }
        let selRows = [];
        let keys = Object.keys(rowSelection);
        keys.forEach(key => {
            if (rowSelection[key]) {
                selRows.push(rows[key])
            }
        });
        if (selRows.length == 0) {
            csvExporter.generateCsv(rows.map((row) => {
                let newrow = {
                    id: row.id,
                    fiscalCode: row.original.fiscalCode,
                    firstname: row.original.firstname,
                    lastname: row.original.lastname,
                    email: row.original.email,
                    cell: row.original.cell,
                    address: row.original.address,
                    city: row.original.city,
                }

                return newrow;
            }
            ));
        } else {
            csvExporter.generateCsv(selRows.map((row) => {
                let newrow = {
                    id: row.id,
                    fiscalCode: row.original.fiscalCode,
                    firstname: row.original.firstname,
                    lastname: row.original.lastname,
                    email: row.original.email,
                    cell: row.original.cell,
                    address: row.original.address,
                    city: row.original.city,
                }

                return newrow;
            }));
        }
    }


    if (isLoading) {
        return <div className="Owners">Loading...</div>;
    }

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={tagList}
                initialState={{ columnVisibility: { id: false } }}
                enableRowSelection
                editingMode="modal"
                enableEditing
                defaultColumn= {{
                    maxSize: 400,
                    minSize: 10,
                    size: 180, //default size is usually 180
                  }}
                  enableColumnResizing
                  columnResizeMode='onChange'
                onEditingRowSave={handleSaveRowEdits}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '', //change header text
                        size: 100 //make actions column wider
                    },
                }}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                        <IconButton onClick={() => {if(row.original.editable)  {toggleModalEditing(row)}}}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Cancella">
                                    <IconButton color="error" onClick={() =>{if(row.original.deletable) {handleDeleteRow(row)}}}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={(table) => {

                    return (
                        <div>
                            <Tooltip arrow title="Download">
                                <IconButton onClick={() => handleDownloadProperty(table.table.getSelectedRowModel().rows)}>
                                    <Download />
                                </IconButton>
                            </Tooltip>

                        </div>
                    );
                }}
            />
            <EditingOwnerModal showModalEditing={showModalEditing} toggleModalEditing={toggleModalEditing} formData={formData} setFormData={setFormData} currentRow={currentRow} apiData={apiData} setApiData={setApiData} validateCell={validateCell} validateEmail={validateEmail} realoadList={realoadList}/>

        </div>

    );
}

export default Owners;