import { useState, React } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { checkNumberWithCf, findNumerByFiscalCode } from '../actions/admin';
import { useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import { Box } from '@mui/material';

const localState = {
  cf: ""
};
const FiscalCode = () => {

  const dispatch = useDispatch()
  const [numberList, setNumberList] = useState([]);
  const [first, setFirst] = useState({});
  var form = {};
  function onChangeCf(e) {
    localState.cf = e.target.value;
  }
  const handleFiscalCode = (event) => {

    dispatch(checkNumberWithCf(localState.cf))
      .then((data) => {
        {if (data.status == 'success' && data.total_found >=1) {
            dispatch(findNumerByFiscalCode(localState.cf))
              .then((data1) => {
                setNumberList(data1.data);
                console.log(typeof data1.data[0].Name)
                setFirst(data1.data[0])
              })
              .catch((error) => {
                alert(error.response.data.message);
              });

          }
          else {
            alert("Non vi sono informazioni relative al codice fiscale inserito!");
          }
        }
      })
      .catch((Error) => {
        console.log(Error)
      });
    event.preventDefault();
  }
  return (

    <div className="col-md-12">
      <div className="card card-container">
        <Form
          onSubmit={handleFiscalCode}
          ref={(c) => {
            form = c;
          }}
        >
          <div className="form-group">
            <label className="text-center" style={{ width: "100%" }} htmlFor="cf">Inserisci Codice Fiscale</label>
            <Input
              type="text"
              className="form-control"
              name="cf"
              value={localState.cf}
              onChange={onChangeCf}
            // validations={[required]}
            />
          </div>

          <div className="form-group text-center">
            <button
              className="btn btn-primary-ib btn-block"
            // disabled={localState.loading}
            >
              {/*        {localState.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
              <span>Cerca</span>
            </button>
          </div>
        </Form>
        <Label for="owners">
          <p >{(first.Name && typeof first.Name === 'string' ) ? first.Name  : ""} {(first.Surname && typeof first.Surname  === 'string') ?  first.Surname : "" }  {(first.DOB && typeof first.DOB  === 'string') ?  first.DOB : "" } </p>
          <p>{(first.Address && typeof first.Address  === 'string') ?  first.Address : "" }  {(first.City && typeof first.City  === 'string') ?  first.City : "" } </p>
        </Label>
        <Box id="owners">
          {
            
            numberList && numberList.map((phone, index) => phone.Mobile && <li key={index}><b> Recapito telefonico {index + 1} :</b> <ul>{phone.Mobile}</ul></li>)
          }
        </Box>
      </div>
    </div>

  );
}

export default FiscalCode;