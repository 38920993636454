import React from 'react'
import { requestsforproperty, updateRequest, addRequestForm } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import { MenuItem, TextField, Select, Checkbox, Stack, Chip } from '@mui/material';
import DatePicker from "react-datepicker";
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';

const EditingRequestModal = (props) => {
    const dispatch = useDispatch();
    const [apiData, setApiData] = useState({});

    useEffect(() => {
        dispatch(addRequestForm())
            .then((data) => {
                setApiData({
                    ...apiData,
                    clientList: data.clientList,
                    propertyList: data.propertyList,
                    countryList: data.countryList,
                    tagList: data.tagList,
                    agentList: data.userList,
                    regionList: data.regionList
                })
            })
            .catch((error) => {
                alert(error.response.data.message);
            });

    }, []);

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        props.setFormData({
            ...props.formData,
            [name]: value
        });
    };

    const handleChangePropertyProposeAutoComplete = (event, val) => {
        props.setFormData({
            ...props.formData,
            propertyIdProposeList: val.map(v => v.id)
        });
    };

    const handleSubmit = (event) => {
        if (props.formData.followupDate) {
            props.formData.followupDate = moment(props.formData.followupDate).format("YYYY-MM-DD");
        }
        if (props.formData.createDateAgent) {
            props.formData.createDateAgent = moment(props.formData.createDateAgent).format("YYYY-MM-DD");
        }
        console.log(props.formData)
        dispatch(updateRequest(props.formData))
            .then((data) => {
                props.toggleModalEditing();
                dispatch(requestsforproperty())
                    .then((data) => {
                        props.realoadList(data);
                        props.setFormData({
                            id: "",
                            maxMq: "",
                            minMq: "",
                            minPrice: "",
                            maxPrice: "",
                            maxRooms: "",
                            city: "",
                            region: "",
                            note: "",
                            radius: "",
                            topRequest:"",
                            agentId:"",
                            propertyIdProposeList:[],
                            createDateAgent: new Date(),
                            createDate: new Date(),
                            followupDate: new Date(),
                            tagSelected: []
                        });
                    })
                    .catch(() => {
                        console.log("Error");
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };

    
    const handleChangeCreateDateAgent = (event) => {

        props.setFormData({
            ...props.formData,
            createDateAgent: event
        });
    };
    const handleChangeRegion = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            regionList: typeof value === 'string' ? value.split(',') : value
        });
    };
    
    const handleChangeNewFollowUpDate = (event) => {

        props.setFormData({
            ...props.formData,
            followupDate: event
        });
    };
    const handleChangeTagsNew = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            tagIdList: typeof value === 'string' ? value.split(',') : value
        });
    };

    return (
        <div>
            <Modal className="modal-dialog-centered modal-xl" id="modalEditing" isOpen={props.showModalEditing} toggle={props.toggleModalEditing} >
                <ModalHeader>Modifica Richiesta</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="2" xl="2">
                            <Col>
                                        <FormGroup>
                                            <Label for="createDateAgent">
                                                Data
                                            </Label>
                                            <DatePicker
                                                selected={props.formData.createDateAgent && moment(props.formData.createDateAgent).toDate() }
                                                dateFormat="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleChangeCreateDateAgent(e);
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                    <FormGroup>
                                        <Label for="topRequest">
                                            Cliente Top
                                        </Label>
                                        <Checkbox
                                            name="topRequest"
                                            id="topRequest"
                                            checked={props.formData.topRequest|| ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="agentId">
                                            Agente
                                        </Label>
                                        <Select
                                            type="select"
                                            name="agentId"
                                            id="agentId"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.agentId || ""}
                                            onChange={(e) => {
                                                handleChange(e);

                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.agentList && props.apiData.agentList.map(t => (
                                                <MenuItem
                                                    key={t.username}
                                                    value={t.id}
                                                >
                                                    {t.username}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="propertyIdProposeList">
                                            Immobili proposti
                                        </Label>
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            <Autocomplete
                                                onChange={handleChangePropertyProposeAutoComplete}
                                                multiple
                                                sx={{ minWidth: 230 }}
                                                name="propertyIdProposeList"
                                                id="propertyIdProposeList"

                                                options={apiData.propertyList}
                                                getOptionLabel={(option) => {
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    return option.name;
                                                }}

                                                isOptionEqualToValue={(option, value) => option.name === value.name}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label=""
                                                        placeholder=""
                                                    />
                                                )}
                                                value={props.formData.propertyIdProposeList && apiData.propertyList && apiData.propertyList.filter(s => {return props.formData.propertyIdProposeList.indexOf(s.id) >= 0}) || []}
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="regionList">
                                            Regione
                                        </Label>
                                        <Select
                                            type="select"
                                            name="regionList"
                                            id="regionList"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.regionList || []}
                                            onChange={(e) => {
                                                handleChangeRegion(e);

                                            }}
                                            renderValue={(selected) => (
                                                <Stack gap={1} direction="row" flexWrap="wrap">
                                                  {selected.map((value) => (
                                                    <Chip key={value} label={props.apiData.regionList.filter(t=>t.id==value).map(t=> t.name)} />
                                                  ))}
                                                </Stack>
                                              )}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.regionList && props.apiData.regionList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="radius">
                                            Raggio Km
                                        </Label>
                                        <Input
                                            type="text"
                                            name="radius"
                                            id="radius"
                                            required
                                            value={props.formData.radius}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col >
                                    <FormGroup>
                                        <Label for="minPrice">
                                            Prezzo min
                                        </Label>
                                        <Input
                                            type="text"
                                            name="minPrice"
                                            id="minPrice"
                                            required
                                            value={props.formData.minPrice}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxPrice">
                                            Prezzo Max
                                        </Label>
                                        <Input
                                            type="text"
                                            name="maxPrice"
                                            id="maxPrice"
                                            required
                                            value={props.formData.maxPrice}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="minMq">
                                            Min MQ
                                        </Label>
                                        <Input
                                            type="text"
                                            name="minMq"
                                            id="minMq"
                                            value={props.formData.minMq || []}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxMq">
                                            Max MQ
                                        </Label>
                                        <Input
                                            type="text"
                                            name="maxMq"
                                            id="maxMq"
                                            value={props.formData.maxMq || []}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxRooms">
                                            Camere da letto
                                        </Label>
                                        <Input
                                            type="text"
                                            name="maxRooms"
                                            id="maxRooms"
                                            value={props.formData.maxRooms || []}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* <Col >
                                    <FormGroup>
                                        <Label for="mqLand">
                                            MQ Terreno
                                        </Label>
                                        <Input
                                            type="text"
                                            name="mqLand"
                                            id="mqLand"
                                            value={props.formData.mqLand || []}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col> */}
                                <Col >
                                    <FormGroup>
                                        <Label for="tags">
                                            Tags
                                        </Label>
                                        <Select
                                            type="select"
                                            name="tags"
                                            id="tags"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.tagIdList || []}
                                            onChange={handleChangeTagsNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.tagList && props.apiData.tagList.map(t => (
                                                <MenuItem
                                                    key={t.tag}
                                                    value={t.id}
                                                >
                                                    {t.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>

                                </Col>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="followupDate">
                                                Data Follow Up
                                            </Label>
                                            <DatePicker
                                                selected={props.formData.followupDate && moment(props.formData.followupDate).toDate() }
                                                dateFormat="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleChangeNewFollowUpDate(e);
                                                }}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="note">
                                                Note
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="note"
                                                id="note"
                                                value={props.formData.note}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={props.toggleModalEditing}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>

        </div>
    )
}

export default EditingRequestModal;