import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Router, Switch, Route, Link, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "leaflet/dist/leaflet.css";

import "bootstrap/dist/js/bootstrap.js";

import Login from "./components/login.component";
import AdminMenu from "./components/admin.menu.component";
import AgentMenu from "./components/agent.menu.component";

import Home from "./components/home.component";
import Profile from "./components/profile.component";
import Users from "./components/users.component";
import Tags from "./components/tags.component";
import Properties from "./components/properties.component";
import Clients from "./components/clients.component";
import Requests from "./components/requests.component";
import Owners from "./components/owners.component";
import FiscalCode from "./components/fiscalcode.component";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from './helpers/history';
import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";

const App = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  var currentUser = user ?? user;
  var showAdminBoard = user ? user.roles.includes("ROLE_ADMIN") : false;
  var showAgentBoard = user ? user.roles.includes("ROLE_AGENT") : false;

  
  
  history.listen((location) => {
    dispatch(clearMessage()); // clear message when changing location
  });

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
        EventBus.remove("logout"); 
    }
  }, []);
  

  function logOut() {
    dispatch(logout());
    showAdminBoard = false;
    showAgentBoard = false;
    currentUser = undefined;
  }

  return (
    <Router history={history}>
      <div>
      {
        window.location.pathname == '/findcellnumber' ? ("") : (
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
              <Link to={"/home"} className="navbar-nav">
                <img src="/LogoIB.png" alt="" width="40" height="40"></img>
              </Link>
              <div className="div">
            </div>
              <span className="logo-ib">
                <b>IB International Real Estate</b>
              </span>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {
                  showAdminBoard ? (<AdminMenu />) : (
                    showAgentBoard ? (<AgentMenu  />) : ("")
                  )
                }
                {
                  currentUser ? (
                    <li className="nav-item">
                    <a href="/login" className="nav-link" onClick={logOut}>
                      LogOut
                    </a>
                  </li>
                  ) : (
                    <li className="nav-item">
                      <Link to={"/login"} className="nav-link">
                        Login
                      </Link>
                    </li>
                  )
                }
              </ul>
            </div>
            </div>
          </nav>
        )}
        <div className="container-fluid mt-3">
          <Switch>
            <Route exact path={["/","/login"]} component={Login} />
            <Route path="/home">
              {currentUser ? <Home/> : <Redirect to="/login" />}
            </Route>
            <Route path="/profile">
              {currentUser ? <Profile/> : <Redirect to="/login" />}
            </Route>
            <Route path="/users">
              {currentUser ? <Users/> : <Redirect to="/login" />}
            </Route>
            <Route path="/tags">
              {currentUser ? <Tags/> : <Redirect to="/login" />}
            </Route>
            <Route path="/properties">
              {currentUser ? <Properties/> : <Redirect to="/login" />}
            </Route>
            <Route path="/clients">
              {currentUser ? <Clients/> : <Redirect to="/login" />}
            </Route>
            <Route path="/requests">
              {currentUser ? <Requests/> : <Redirect to="/login" />}
            </Route>
            <Route path="/owners">
              {currentUser ? <Owners/> : <Redirect to="/login" />}
            </Route>
            <Route path="/findcellnumber">
              {currentUser ? <FiscalCode/> : <Redirect to="/login" />}
            </Route> 
          </Switch>
        </div>
        {<AuthVerify logOut={logOut}/>}
      </div>
    </Router>
  );
}

export default App;