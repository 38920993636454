import React from 'react';
import { useState } from "react";
import { properties, regionListByCountryCode, cityListByRegionId, downloadBrochure, updateProperty, cityListByCountryCode, propertyById, ownerAddForm } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import { MenuItem, Box, Tooltip, TextField, Select, Link, Checkbox, IconButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Add from '@mui/icons-material/Add';
import EditingOwnerModal from "./editingownermodal.component";

const EditingPropertyModal = (props) => {
    const dispatch = useDispatch();
    const filter = createFilterOptions();
    const [showModalEditOwner, setShowModalEditOwner] = useState(false);
    const [showModalNewOwner, setShowModalNewOwner] = useState(false);
    const [ownerData, setOwnerData] = useState({
        ownerId: "",
        ownerFiscalCode: "",
        ownerEmail: "",
        ownerFirstname: "",
        ownerLastname: "",
        ownerCell: "",
        ownerAddress: "",
        formErrors: {}
    });
    const [currentOwner, setCurrentOwner] = useState({});
    const [ownerApiData, setOwnerApiData] = useState({
        countryList: []
    })
    const [ownerFormData, setOwnerFormData] = useState({
        id: "",
        fiscalCode:"",
        firstname: "",
        lastname: "",
        propertyNames:"",
        email: "",
        cell: "",
        address: "",
        city: "",
        formErrors: { email: '', cell: '' },
        fromComponent: "editingProperty",
        propertyId: props.id
    });
    const [isOwnerSubmitDisabled, setIsOwnerSubmitDisabled] = useState(false);
    const [isOwnerDisable, setIsOwnerDisable] = useState(true);
    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        props.setFormData({
            ...props.formData,
            [name]: value
        });
    };

    const handleChangeFile = (event) => {
        props.setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        dispatch(updateProperty(props.formData, props.file))
            .then((data) => {
                props.toggleModalEditing();
                if(props.caller === "propertyList") {
                    dispatch(properties())
                        .then((data) => {
                            props.reloadData(data);
                        })
                        .catch((error) => {
                            console.log("Error");
                            console.log(error);
                        });
                } else if(props.caller === "mapView") {
                    dispatch(propertyById(props.formData.id))
                        .then((data) => {
                            props.reloadData(data);
                        })
                        .catch((e) => {
                            console.log("Error");
                            console.log(e);
                        });
                }
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };
    const validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = ownerData.formErrors;

        if (emailRex.test(e.target.value)) {
            validate.ownerEmail = 'has-success';
        } else {
            validate.ownerEmail = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };
    
    const validatePrice = (e) => {
        const priceRex =/\d$/;
        let ret = true;
        let validate = props.formData.formErrors;
        if (priceRex.test(e.target.value)) {
            validate.price = 'has-success';
        } else {
            validate.price = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    const brochureDownload = (id) => {
        let filename;
        dispatch(downloadBrochure(id))
            .then(response => {
                filename = response.headers['x-suggested-filename'];
                return response;
            })
            .then(response => {
                var url = window.URL.createObjectURL(response.data);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch(error => {
                console.error(error);
            });
    };
    const handleChangeCountryNew = (event) => {
        const {
            target: { value },
        } = event;

        let countryObj = { code: value };

        props.setFormData({
            ...props.formData,
            country: value,
            countryObj: countryObj
        });

        dispatch(regionListByCountryCode(value))
            .then((data) => {
                dispatch(cityListByCountryCode(value))
                    .then((data1) => {
                        props.setApiData({
                            ...props.apiData,
                            regionList: data,
                            cityList: data1
                        })
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });


    };
    const handleChangeRegionNew = (event) => {
        const {
            target: { value },
        } = event;

        let regionObj = { id: value };

        props.setFormData({
            ...props.formData,
            region: value,
            regionObj: regionObj,
            city: null,
            cityObj: null
        });
        if (value != null) {
            dispatch(cityListByRegionId(value))
                .then((data) => {
                    props.setApiData({
                        ...props.apiData,
                        cityList: data
                    })
                })
                .catch((error) => {
                    console.log(error)
                    alert(error);
                });
        }
    };

    const handleChangeCityNew = (event) => {
        const {
            target: { value },
        } = event;

        let cityObj = { id: value };

        props.setFormData({
            ...props.formData,
            city: value,
            cityObj: cityObj
        });
    };


    const handleChangeTagsNew = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            tagIdList: typeof value === 'string' ? value.split(',') : value
        });
    };

    const validateEmailAfter = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = ownerData.formErrors;

        if (emailRex.test(e)) {
            validate.ownerEmail = 'has-success';
        } else {
            validate.ownerEmail = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    const validateCell = (e) => {
        var passwdRex = /^\d+$/;
        let ret = true;
        let validate = ownerData.formErrors;
        if (passwdRex.test(e.target.value)) {
            validate.ownerCell = 'has-success';
        } else {
            validate.ownerCell = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    const handleCreateNewOwner = () => {
        setShowModalNewOwner(true);
        
    };

    const handleChangeOwner = (event) => {
        const { target } = event;
        const value = target.value;
        const { name } = target;

        setOwnerData({
            ...ownerData,
            [name]: value
        });
    };

    const toggleModalNewOwner = () => {
        if (showModalNewOwner) {
            setOwnerData({
                ownerId: "",
                ownerFiscalCode:"",
                ownerEmail: "",
                ownerFirstname: "",
                ownerLastname: "",
                ownerCell: "",
                ownerAddress: "",
                formErrors: {}
            });
        }
        setShowModalNewOwner(!showModalNewOwner);
    };

    const toggleModalEditOwner = (ow) => {
        //console.log("-----popolo---");
        //console.log(ow);
        if(ow) {
            setOwnerFormData({
                id: ow.id,
                fiscalCode: ow.fiscalCode,
                firstname: ow.firstname,
                lastname: ow.lastname,
                email: ow.email,
                cell: ow.cell,
                address: ow.address,
                countryId: ow.countryId,
                country: ow.country,
                regionId: ow.regionId,
                region: ow.region,
                cityId: ow.cityId,
                city: ow.city,
                info: ow.info,
                formErrors: {},
                fromComponent: "editingProperty",
                propertyId: props.formData.id
            });

            dispatch(ownerAddForm())
            .then((data) => {
                setOwnerApiData(data);
            })
            setCurrentOwner(ow);
        }
        setShowModalEditOwner(!showModalEditOwner);
    };

    const realoadDummy = (data) => {
        props.setFormData({
            ...props.formData,
            owners: data.property.owners
        });
    };

    const handleNewOwnerSubmit = (event) => {
        let owns = props.formData.owners;
        if (!owns) {
            owns = [];
        }
        let localOwner = {
            id: ownerData.ownerId,
            fiscalCode: ownerData.ownerFiscalCode,
            email: ownerData.ownerEmail,
            firstname: ownerData.ownerFirstname,
            lastname: ownerData.ownerLastname,
            cell: ownerData.ownerCell,
            address: ownerData.ownerAddress
        };

        owns.push(localOwner);
        props.setFormData({
            ...props.formData,
            owners: owns
        });
        //console.log(props.formData)
        toggleModalNewOwner(false);
        event.preventDefault();
    };

    return (
        <div>
            <Modal className="modal-dialog-centered modal-xl" id="modalEditing" isOpen={props.showModalEditing} toggle={props.toggleModalEditing} >
                <ModalHeader >Modifica Informazioni Immobile</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Tooltip arrow title="Create New Owner">
                            <IconButton onClick={handleCreateNewOwner}>
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                <Col>
                                    <FormGroup>
                                        <Label for="classification">
                                            Disponibilità
                                        </Label>
                                        <Select
                                            type="select"
                                            name="classification"
                                            id="classification"
                                            required
                                            sx={{ minWidth: 230 }}
                                            value={(props.formData.classification) || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.classificationList && props.apiData.classificationList.map(t => (
                                                <MenuItem
                                                    key={t.description}
                                                    value={t.id}
                                                >
                                                    {t.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="name">
                                            Identificativo Immobile
                                        </Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            required
                                            value={props.formData.name}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="idAgent">
                                            Agente
                                        </Label>
                                        <Select
                                            type="select"
                                            name="idAgent"
                                            id="idAgent"
                                            autocomplete
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.idAgent || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.userList && props.apiData.userList.map(t => (
                                                <MenuItem
                                                    key={t.lastname + " " + t.firstname}
                                                    value={t.id}
                                                >
                                                    {t.lastname + " " + t.firstname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>


                                <Col >
                                    <FormGroup>
                                        <Label for="price">
                                            Prezzo
                                        </Label>
                                        <Input
                                            type="number"
                                            name="price"
                                            id="price"
                                            required
                                            value={props.formData.price}
                                            valid={(props.formData.formErrors && props.formData.formErrors.price === 'has-success') || ""}
                                            invalid={(props.formData.formErrors && props.formData.formErrors.price === 'has-danger') || ""}
                                            onChange={(e) => {
                                                validatePrice(e);
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="temporaryPrice">
                                            Prezzo Stimato
                                        </Label>
                                        <Checkbox
                                            name="temporaryPrice"
                                            id="temporaryPrice"
                                            checked={props.formData.temporaryPrice|| ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="mq">
                                            Metri Quadri
                                        </Label>
                                        <Input
                                            type="number"
                                            name="mq"
                                            id="mq"
                                            required
                                            value={props.formData.mq}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="rooms">
                                        Camere da letto
                                        </Label>
                                        <Input
                                            type="number"
                                            name="rooms"
                                            id="rooms"
                                            value={props.formData.rooms || []}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="mqLand">
                                            MQ Terreno
                                        </Label>
                                        <Input
                                          type="number"
                                            name="mqLand"
                                            id="mqLand"
                                            value={props.formData.mqLand || []}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="swimmingPool">
                                            Piscina
                                        </Label>
                                        <Checkbox
                                            name="swimmingPool"
                                            id="swimmingPool"
                                            checked={props.formData.swimmingPool|| ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="propertyType">
                                            Tipologia
                                        </Label>
                                        <Select
                                            type="select"
                                            name="propertyType"
                                            id="propertyType"
                                            required
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.propertyType || ''}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.propertyTypeList && props.apiData.propertyTypeList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="status">
                                            Stato
                                        </Label>
                                        <Select
                                            type="select"
                                            name="status"
                                            id="status"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.status || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.statusList && props.apiData.statusList.map(t => (
                                                <MenuItem
                                                    key={t.description}
                                                    value={t.id}
                                                >
                                                    {t.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="address">
                                            Indirizzo
                                        </Label>
                                        <Input
                                            type="text"
                                            name="address"
                                            id="address"
                                            value={props.formData.address}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                    
                                </Col>
                                <Col  >
                                    <FormGroup>
                                        <Label for="latitude">
                                            Latitudine
                                        </Label>
                                        <Input
                                          type="number"
                                          step={0.00000000000000001}
                                            name="latitude"
                                            id="latitude"
                                            required
                                            value={props.formData.latitude}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="longitude">
                                            Longitudine
                                        </Label>
                                        <Input
                                            type="number"
                                            step={0.00000000000000001}
                                            name="longitude"
                                            id="longitude"
                                            required
                                            value={props.formData.longitude}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="country">
                                            Paese
                                        </Label>
                                        <Select
                                            type="select"
                                            name="country"
                                            id="country"
                                            sx={{ minWidth: 230 }}
                                            required
                                            //value={props.formData.country || ""}
                                            value={(props.formData.countryObj && props.formData.countryObj.code) || ""}
                                            onChange={handleChangeCountryNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.countryList && props.apiData.countryList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.code}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="region">
                                            Regione
                                        </Label>
                                        <Select
                                            type="select"
                                            name="region"
                                            id="region"
                                            sx={{ minWidth: 230 }}
                                            //value={props.formData.region || ""}
                                            value={(props.formData.regionObj && props.formData.regionObj.id) || ""}
                                            onChange={handleChangeRegionNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.regionList && props.apiData.regionList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>


                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="city">
                                            Città
                                        </Label>
                                        <Select
                                            type="select"
                                            name="city"
                                            id="city"
                                            sx={{ minWidth: 230 }}
                                            //value={props.formData.city || ""}
                                            value={(props.formData.cityObj && props.formData.cityObj.id) || ""}
                                            onChange={handleChangeCityNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.cityList && props.apiData.cityList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="tags">
                                            Tags
                                        </Label>
                                        <Select
                                            type="select"
                                            name="tags"
                                            id="tags"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.tagIdList || []}
                                            onChange={handleChangeTagsNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.tagList && props.apiData.tagList.map(t => (
                                                <MenuItem
                                                    key={t.tag}
                                                    value={t.id}
                                                >
                                                    {t.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>

                                <Col >
                                    <FormGroup>
                                        <Label for="driveLink">
                                            Link Drive
                                        </Label>
                                        <Input
                                            type="text"
                                            name="driveLink"
                                            id="driveLink"
                                            value={props.formData.driveLink || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col >
                                    <FormGroup>
                                        <Label for="websiteLink">
                                            Website Link 
                                        </Label>
                                        <Input
                                            type="text"
                                            name="websiteLink"
                                            id="websiteLink"
                                            value={props.formData.websiteLink || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <Label for="owners">
                                        <p className="fw-bold">Proprietari</p>
                                    </Label>
                                    <Box id="owners">
                                        <ol>
                                            {
                                                props.formData.owners && props.formData.owners.map((ow, idx) => <div><li key={"own_" + idx}>{ow.firstname} {ow.lastname} <Link onClick={() => {
                                                    let owns = props.formData.owners;
                                                    owns.splice(idx, 1);
                                                    props.setFormData({
                                                        ...props.formData,
                                                        owners: owns
                                                    });
                                                }
                                                }>Elimina</Link> <Link onClick={() => {
                                                    let owns = props.formData.owners;
                                                    toggleModalEditOwner(owns[idx]);
                                                }}>Modifica</Link><ul><li>{ow.fiscalCode}</li>{ow.email && <li>{ow.email}</li>}{ow.cell && <li>{ow.cell}</li>}{ow.address && <li>{ow.address}</li>}</ul></li></div>)
                                            }
                                        </ol>
                                    </Box>
                                </Col>
                            </Row>
                            <FormGroup>
                                {props.formData.brochureFilename !== null ?
                                    <Link
                                        id="brochureDownload"
                                        //component="button"
                                        onClick={() => {
                                            brochureDownload(props.formData.id);
                                        }}
                                    >
                                        Scarica brochure
                                    </Link>
                                    : ""
                                }
                                <br/>
                                <Input
                                    type="file"
                                    name="brochure"
                                    id="brochure"
                                    onChange={(e) => {
                                        handleChangeFile(e);
                                    }}
                                />
                            </FormGroup>


                            <FormGroup>
                                <Label for="description">
                                    Descrizione
                                </Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    value={props.formData.description || []}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />

                            </FormGroup>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={props.toggleModalEditing}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>
            

            <div>

                <Modal id="modalOwner" className="modal-dialog-centered modal-xl" isOpen={showModalNewOwner} toggle={toggleModalNewOwner} >
                    <ModalHeader toggle={toggleModalNewOwner}>Nuovo proprietario</ModalHeader>
                    <Form onSubmit={handleNewOwnerSubmit}>
                    <ModalBody>
                            <Container>
                                <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                <Col>
                                        <Label>C.F. Proprietario</Label>
                                        <Autocomplete
                                            value={ownerData.ownerFiscalCode || ""}
                                            onChange={(e, newValue) => {
                                                if (typeof newValue === 'string') {
                                                    if (newValue.startsWith('Aggiungi ')) {
                                                        newValue = newValue.substring(9);
                                                    }
                                                    // if(!validateCF(newValue)) {
                                                    //     setIsOwnerSubmitDisabled(true);
                                                    // } else {
                                                    //     setIsOwnerSubmitDisabled(false);
                                                    // }
                                                    // validateCF(newValue);
                                                    setOwnerData({
                                                        ...ownerData,
                                                        ownerFiscalCode: newValue
                                                    });
                                                } else if (newValue && newValue.inputValue) {
                                                    setOwnerData({
                                                        ...ownerData,
                                                        ownerEmail: newValue.email,
                                                        ownerFirstname: newValue.firstname,
                                                        ownerLastname: newValue.lastname,
                                                        ownerFiscalCode: newValue.fiscalCode,
                                                        ownerCountry: newValue.countryId,
                                                        ownerRegion: newValue.regionId,
                                                        ownerCity: newValue.cityId,
                                                        ownerId: newValue.id,
                                                        ownerCell: newValue.cell
                                                    });
                                                } else {
                                                    if (!newValue) {
                                                        setIsOwnerSubmitDisabled(true);
                                                        return;
                                                    }
                                                    if (newValue.fiscalCode.startsWith('Aggiungi ')) {
                                                        setIsOwnerDisable(false);
                                                        newValue.fiscalCode = newValue.fiscalCode.substring(9);
                                                    }
                                                    setIsOwnerSubmitDisabled(false);
                                                    // if(!validateCF(newValue.fiscalCode)) {
                                                    //     setIsOwnerSubmitDisabled(true);
                                                    // } else {
                                                    //     setIsOwnerSubmitDisabled(false);
                                                    // }
                                                    setOwnerData({
                                                        ...ownerData,
                                                        ownerEmail: newValue.email,
                                                        ownerFirstname: newValue.firstname,
                                                        ownerLastname: newValue.lastname,
                                                        ownerFiscalCode: newValue.fiscalCode,
                                                        ownerAddress: newValue.address,
                                                        ownerCountry: newValue.countryId,
                                                        ownerRegion: newValue.regionId,
                                                        ownerCity: newValue.cityId,
                                                        ownerId: newValue.id,
                                                        ownerCell: newValue.cell
                                                    });
                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = props.apiData.ownerList.some((option) => inputValue === option.fiscalCode);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        fiscalCode: `Aggiungi ${inputValue}`
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="ownerFiscalCode"
                                            options={props.apiData.ownerList}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option.fiscalCode;
                                            }}
                                            renderOption={(props, option) => (
                                                <AutocompleteOption {...props}>
                                                    {option.fiscalCode?.startsWith('Aggiungi ') && (
                                                        <ListItemDecorator>
                                                            <Add />
                                                        </ListItemDecorator>
                                                    )}

                                                    {option.fiscalCode}
                                                </AutocompleteOption>
                                            )}
                                            sx={{ minWidth: 230 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=""
                                                //error={ownerData.formErrors.ownerFiscalCode === 'has-danger'}
                                                //helperText="Email errata"
                                                />
                                            )}

                                        />
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerFirstname">
                                                Nome Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerFirstname"
                                                id="ownerFirstname"
                                                required
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerFirstname}
                                                onChange={(e) => {
                                                    handleChangeOwner(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerLastname">
                                                Cognome Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerLastname"
                                                id="ownerLastname"
                                                required
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerLastname}
                                                onChange={(e) => {
                                                    handleChangeOwner(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerCell">
                                                Cellulare Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerCell"
                                                id="ownerCell"
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerCell}
                                                valid={ownerData.formErrors.ownerCell === 'has-success'}
                                                invalid={ownerData.formErrors.ownerCell === 'has-danger'}
                                                onChange={(e) => {
                                                    validateCell(e);
                                                    handleChangeOwner(e);

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerEmail">
                                                Email Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerEmail"
                                                id="ownerEmail"
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerEmail}
                                                valid={ownerData.formErrors.ownerEmail === 'has-success'}
                                                invalid={ownerData.formErrors.ownerEmail === 'has-danger'}
                                                onChange={(e) => {
                                                    validateEmail(e);
                                                    handleChangeOwner(e);

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerAddress">
                                                Indirizzo Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerAddress"
                                                id="ownerAddress"
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerAddress}
                                                onChange={(e) => {
                                                    handleChangeOwner(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn btn-primary custom-btn" disabled={isOwnerSubmitDisabled} type="submit">
                                Salva
                            </Button>{' '}
                            <Button className="btn btn-primary custom-btn" onClick={toggleModalNewOwner}>
                                Chiudi
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
            <EditingOwnerModal showModalEditing={showModalEditOwner} toggleModalEditing={toggleModalEditOwner} formData={ownerFormData} setFormData={setOwnerFormData} currentRow={currentOwner} apiData={ownerApiData} setApiData={setOwnerApiData} validateCell={validateCell} validateEmail={validateEmail}  realoadList={realoadDummy} />
        </div>
    )
}

export default EditingPropertyModal;