import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

const Profile = (props) => {
  const currentUser = useSelector(state => state.auth.user);

  if (!currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container-fluid">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.username}</strong> Profile
        </h3>
      </header>
      <p>
        <strong>Token:</strong> {currentUser.accessToken}
      </p>
      <p>
        <strong>Id:</strong> {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>
      <strong>Authorities:</strong>
      <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul>
    </div>
  );
}

export default Profile;