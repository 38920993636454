import React from 'react';
import { useDispatch } from 'react-redux';
import { uploadPropertyImages, deletePropertyImage } from "../actions/admin";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Grid from '@mui/material/Grid';
import Dropzone from 'react-dropzone-uploader';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ImageViewer from './imageviewer.component';

import 'react-dropzone-uploader/dist/styles.css';
import '../dropzone.popup.css'

const ImagesProperty = (props) => {
    const dispatch = useDispatch();

    const handleSubmit = (files, allFiles) => {
        dispatch(uploadPropertyImages(props.propertyId, files))
            .then((data) => {
                allFiles.forEach(
                    f => {
                        f.remove();
                    }
                )
                props.setUploadMessage(data.message);

                dispatch(props.getPropertyImages(props.propertyId))
                    .then((data) => {
                        props.setImageList(data);
                    })
                    .catch((error) => {
                        console.log(error);
                        props.setUploadMessage(error.response.data.message);
                    });

            })
            .catch((error) => {
                console.log(error);
                props.setUploadMessage(error.response.data.message);
            })

    }

    const deleteImage = (imageId) => {
        if(!window.confirm("Sei sicuro di voler eliminare l'immagine?")) {
            return;
        }
        dispatch(deletePropertyImage(imageId))
            .then((data) => {
                props.setUploadMessage(data.message);
                
                dispatch(props.getPropertyImages(props.propertyId))
                    .then((data) => {
                        props.setImageList(data);
                    })
                    .catch((error) => {
                        console.log("Error");
                        props.setUploadMessage(error.response.data.message);
                    });

            })
            .catch((error) => {
                console.log("Error");
                props.setUploadMessage(error.response.data.message);
            })
    }

    return (
        <Modal className="modal-dialog-centered modal-xl" id="modalImages" isOpen={props.showModalImages} toggle={props.toggleModalImages} >
            <ModalHeader>Immagini Immobile</ModalHeader>
            <ModalBody>
                <Grid container spacing={{ xs: 1, md: 4 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                {props.imageList.map((photo, index) => (
                    <Grid item={true} xs={1} sm={1} md={2} key={photo.id}>
                        <ImageViewer id={photo.id} />
                        <IconButton onClick={() => deleteImage(photo.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                ))}
                </Grid>

                <div className="alert alert-light text-center" role="alert">
                    {props.uploadMessage || ""}
                </div>

                <Dropzone
                    //onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    submitButtonContent="Carica"
                    inputWithFilesContent="Aggiungi immagine"
                    accept="image/*"
                    maxSizeBytes = "4096576"
                    maxFiles = "30"
                    inputContent="Trascina solo files di tipo immagine (jpg. gif, ...)"
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-primary custom-btn" onClick={props.toggleModalImages}>
                    Chiudi
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default ImagesProperty;