import { configureStore } from "@reduxjs/toolkit";
import message from "./reducers/message";
import auth from "./reducers/auth";

const store = configureStore({
    reducer: {
        auth: auth,
        message: message
    }
});

export default store;