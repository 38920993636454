import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Container, Row, Col, FormFeedback } from 'reactstrap';
import { MenuItem, TextField, Select, Stack, Chip, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState } from "react";
import { createRequest, requestsforpropertybyid } from "../actions/admin";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Add from '@mui/icons-material/Add';
import DatePicker from "react-datepicker";
import moment from "moment";
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';

const NewRequestPropertyViewModal = (props) => {

    const [isClientDisable, setIsClientDisable] = useState(true);
    const filter = createFilterOptions();
    const [isOwnerSubmitDisabled, setIsOwnerSubmitDisabled] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        props.setFormData({
            ...props.formData,
            [name]: value
        });
    };
    const handleChangeTagsNew = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            tagSelected: typeof value === 'string' ? value.split(',') : value
        });
    };
    const handleChangeRegion = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            regionList: typeof value === 'string' ? value.split(',') : value
        });
    };
    const handleChangeNewFollowUpDate = (event) => {
        props.setFormData({
            ...props.formData,
            followupDate: event
        });
    };

    const removeCell = (index) => {
        let cells = props.formData.clientCell;
        cells.splice(index, 1);
        props.formData({
            ...props.formData,
            clientCell: cells
        })
    };

    const addCell = () => {
        let cells = props.formData.clientCell;
        cells.push("");
        props.formData({
            ...props.formData,
            clientCell: cells
        })
    }
    const handleSubmit = (event) => {
        let clientObj = props.formData.clientObj;
        if (clientObj === undefined) {
            clientObj = { id: null };
        }
        if (!clientObj.id || clientObj.id === null) {
            clientObj.id = props.formData.clientId;
            clientObj.firstname = props.formData.clientFirstname;
            clientObj.lastname = props.formData.clientLastname;
            clientObj.cell = props.formData.clientCell;
            clientObj.email = props.formData.clientEmail;
            clientObj.countryId = props.formData.clientCountry;
            clientObj.info = props.formData.info;
            props.formData.clientObj = clientObj;
        }
        if (props.formData.followupDate) {
            props.formData.followupDate = moment(props.formData.followupDate).format("YYYY-MM-DD");
        }
        if (props.formData.createDateAgent) {
            props.formData.createDateAgent = moment(props.formData.createDateAgent).format("YYYY-MM-DD");
        }
        dispatch(createRequest(props.formData))
            .then((data) => {
                props.toggleModalNew();
                if (props.formData.propertyId != null && typeof (props.formData.propertyId) === 'string' && props.formData.propertyId.length > 0) {
                    dispatch(requestsforpropertybyid(props.formData.propertyId))
                        .then((data) => {
                            props.realoadList(data);
                        })
                        .catch(() => {
                            console.log("Error");
                        });
                }
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };

    const handleChangeCell = (index, event) => {
        let data = props.formData.clientCell;
        data[index] = event.target.value;
        props.setFormData({
            ...props.formData,
            clientCell: data
        })
    };



    const validateCell = (index, e) => {
        var passwdRex = /^\d+$/;
        let ret = true;
        let validate = props.formData.formErrors;
        if (passwdRex.test(e.target.value)) {
            validate.clientCell[index] = 'has-success';
        } else {
            validate.clientCell[index] = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };
    const handleChangeNewDateAgent = (event) => {
        props.setFormData({
            ...props.formData,
            createDateAgent: event
        });
    };
    const validateEmailAfter = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = props.formData.formErrors;

        if (emailRex.test(e)) {
            validate.clientEmail = 'has-success';
        } else {
            validate.clientEmail = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    return (

        <div>
            <Modal id="modalNew" className="modal-dialog-centered modal-xl" isOpen={props.showModalNew} toggle={props.toggleModalNew} >
                <ModalHeader>Nuova richiesta</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                <Col>
                                    <FormGroup>
                                        <Label for="createDateAgent">
                                            Data
                                        </Label>
                                        <DatePicker

                                            selected={props.formData.createDateAgent}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(e) => {
                                                handleChangeNewDateAgent(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="topRequest">
                                            Richiesta Top
                                        </Label>
                                        <Checkbox
                                            name="topRequest"
                                            id="topRequest"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Label>Email Cliente</Label>
                                    <Autocomplete
                                        value={props.formData.clientEmail || ""}
                                        onChange={(e, newValue) => {
                                            if (typeof newValue === 'string') {
                                                if (newValue.startsWith('Aggiungi ')) {
                                                    newValue = newValue.substring(9);
                                                }
                                                if (!validateEmailAfter(newValue)) {
                                                    setIsOwnerSubmitDisabled(true);
                                                } else {
                                                    setIsOwnerSubmitDisabled(false);
                                                }
                                                validateEmailAfter(newValue);
                                                props.setFormData({
                                                    ...props.formData,
                                                    clientEmail: newValue
                                                });
                                            } else if (newValue && newValue.inputValue) {
                                                props.setFormData({
                                                    ...props.formData,
                                                    clientId: newValue.id,
                                                    clientEmail: newValue.email,
                                                    clientFirstname: newValue.firstname,
                                                    clientLastname: newValue.lastname,
                                                    clientCountry: newValue.countryId,
                                                    clientCell: newValue.cell || [""],
                                                    clientInfo: newValue.info
                                                });

                                            } else {
                                                if (!newValue) {
                                                    setIsOwnerSubmitDisabled(true);
                                                    return;
                                                }
                                                if (newValue.email.startsWith('Aggiungi ')) {
                                                    setIsClientDisable(false);
                                                    newValue.email = newValue.email.substring(9);
                                                }
                                                if (!validateEmailAfter(newValue.email)) {
                                                    setIsOwnerSubmitDisabled(true);
                                                } else {
                                                    setIsOwnerSubmitDisabled(false);
                                                }
                                                props.setFormData({
                                                    ...props.formData,
                                                    clientId: newValue.id,
                                                    clientEmail: newValue.email,
                                                    clientFirstname: newValue.firstname,
                                                    clientLastname: newValue.lastname,
                                                    clientCountry: newValue.countryId,
                                                    clientCell: newValue.cell || [""],
                                                    clientInfo: newValue.info
                                                });

                                            }

                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = props.apiData.clientList.some((option) => inputValue === option.email);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    email: `Aggiungi ${inputValue}`
                                                });
                                            }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="clientEmail"
                                        options={props.apiData.clientList}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.email;
                                        }}
                                        renderOption={(props, option) => (
                                            <AutocompleteOption {...props}>
                                                {option.email?.startsWith('Aggiungi ') && (
                                                    <ListItemDecorator>
                                                        <Add />
                                                    </ListItemDecorator>
                                                )}

                                                {option.email}
                                            </AutocompleteOption>
                                        )}
                                        sx={{ minWidth: 230 }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                error={props.formData.formErrors.clientEmail === 'has-danger'}
                                            //helperText="Email errata"
                                            />
                                        )}

                                    />
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="clientFirstname">
                                            Nome Cliente
                                        </Label>
                                        <Input
                                            type="text"
                                            name="clientFirstname"
                                            id="clientFirstname"
                                            required
                                            disabled={isClientDisable}
                                            value={(props.formData.clientFirstname) || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="clientLastname">
                                            Cognome Cliente
                                        </Label>
                                        <Input
                                            type="text"
                                            name="clientLastname"
                                            id="clientLastname"
                                            required
                                            disabled={isClientDisable}
                                            value={props.formData.clientLastname || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>



                                <Col>
                                    <FormGroup>
                                        <Label for="clientCell">
                                            Cellulare Cliente
                                            <IconButton onClick={addCell}>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Label>
                                        {
                                            props.formData.clientCell && props.formData.clientCell.map((cellu, index) => (
                                                <>
                                                    <Row>
                                                        <Col xs="9">
                                                            <Input
                                                                type="text"
                                                                name="clientCell"
                                                                id="clientCell"
                                                                disabled={isClientDisable}
                                                                //valid={props.formData.clientCell[index] === 'has-success'}
                                                                //invalid={props.formData.clientCell[index] === 'has-danger'}
                                                                value={cellu ? cellu : ""}
                                                                onChange={(e) => {
                                                                    //validateCell(index, e);
                                                                    handleChangeCell(index, e);
                                                                }}
                                                            />
                                                            {/* <FormFeedback>
                                                        Numero di telefono non valido
                                                    </FormFeedback>
                                                    <FormFeedback valid>
                                                        Numero di telefono valido
                                                    </FormFeedback> */}
                                                        </Col>
                                                        <Col xs="3">
                                                            {index > 0 &&
                                                                <IconButton color="error" onClick={() => { removeCell(index) }}>
                                                                    <Delete />
                                                                </IconButton>
                                                            }
                                                        </Col>

                                                    </Row>
                                                </>
                                            ))
                                        }
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="clientCountry">
                                            Paese Cliente
                                        </Label>
                                        <Select
                                            type="select"
                                            name="clientCountry"
                                            id="clientCountry"
                                            disabled={isClientDisable}
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.clientCountry || ""}
                                            onChange={(e) => {
                                                handleChange(e);

                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.countryList && props.apiData.countryList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.code}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="regionList">
                                            Regione
                                        </Label>
                                        <Select
                                            type="select"
                                            name="regionList"
                                            id="regionList"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.regionList || []}
                                            onChange={(e) => {
                                                handleChangeRegion(e);

                                            }}
                                            renderValue={(selected) => (
                                                <Stack gap={1} direction="row" flexWrap="wrap">
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={props.apiData.regionList.filter(t => t.id == value).map(t => t.name)} />
                                                    ))}
                                                </Stack>
                                            )}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.regionList && props.apiData.regionList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="radius">
                                            Raggio Km
                                        </Label>
                                        <Input
                                            type="number"
                                            name="radius"
                                            id="radius"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="minMq">
                                            Min MQ
                                        </Label>
                                        <Input
                                            type="number"
                                            name="minMq"
                                            id="minMq"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxMq">
                                            Max MQ
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxMq"
                                            id="maxMq"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="minPrice">
                                            Prezzo Min
                                        </Label>
                                        <Input
                                            type="number"
                                            name="minPrice"
                                            id="minPrice"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="maxPrice">
                                            Prezzo Max
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxPrice"
                                            id="maxPrice"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="maxRooms">
                                            Camere da letto
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxRooms"
                                            id="maxRooms"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="tags">
                                            Tags
                                        </Label>
                                        <Select
                                            type="select"
                                            name="tags"
                                            id="tags"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.tagSelected || []}
                                            onChange={handleChangeTagsNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.tagList && props.apiData.tagList.map(t => (
                                                <MenuItem
                                                    key={t.tag}
                                                    value={t.id}
                                                >
                                                    {t.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormGroup>
                                </Col>
                                <Row >
                                    <FormGroup>
                                        <Label for="followupDate">
                                            Data Follow Up
                                        </Label>
                                        <DatePicker
                                            selected={props.formData.followupDate}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(e) => {
                                                handleChangeNewFollowUpDate(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Row >
                                <Row >
                                    <FormGroup>
                                        <Label for="description">
                                            Note
                                        </Label>
                                        <textarea className="form-control" id="description" name="description" rows="5"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        ></textarea>
                                    </FormGroup>
                                </Row>

                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={() => {
                            props.toggleModalNew();
                            setIsClientDisable(true);
                        }}>
                            Chiudi
                        </Button>

                    </ModalFooter>
                </Form>
            </Modal>
        </div >
    )
}

export default NewRequestPropertyViewModal;