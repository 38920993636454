import React from 'react';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { downloadImage } from "../actions/admin";

const ImageViewer = (props) => {
    const dispatch = useDispatch();
    const [imgSrc, setImgSrc] = useState('');

    useEffect(() => {
        dispatch(downloadImage(props.id))
            .then((data) => {
                const url = URL.createObjectURL(data);
                setImgSrc(url)
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }, []);

    return(
        <img    className="media-img card-img-top card-img-hero" src={imgSrc} alt="Alt text"/>
        //<img  src={imgSrc} className="img-fluid img-thumbnail"/>
      )

}

export default ImageViewer;