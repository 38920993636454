import React from 'react'
import { clients, updateClient } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import { MenuItem, Select } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';

const EditingClientModal = (props) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        props.setFormData({
            ...props.formData,
            [name]: value
        });
    };

    const removeCell = (index) => {
        let cells = props.formData.cell;
        cells.splice(index, 1);
        props.setFormData({
            ...props.formData,
            cell: cells
        })
    };

    const addCell = () => {
        let cells = props.formData.cell;
        cells.push("");
        props.setFormData({
            ...props.formData,
            cell: cells
        })
    }

    const handleChangeCell = (index, event) => {
        let data = props.formData.cell;
        data[index] = event.target.value;
        props.setFormData({
            ...props.formData,
            cell: data
        });
    };

    const handleSubmit = (event) => {
        if (props.formData.cell.length == 0) {
            props.setFormData({
                ...props.formData,
                cell: null
            });
        }
        dispatch(updateClient(props.formData))
            .then((data) => {
                props.toggleModalEditing();
                dispatch(clients())
                    .then((data) => {
                        props.realoadList(data)
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
            })
            .catch((error) => {
                if(error.response.data.message) {
                    alert(error.response.data.message);
                }
                if(error.response.data.errors) {
                    alert(error.response.data.errors);
                }
            });
        event.preventDefault();
    };


    return (
        <div>
            <Modal className="modal-dialog-centered modal-xl" id="modalEditing" isOpen={props.showModalEditing} toggle={props.toggleModalEditing} >
                <ModalHeader>Modifica Cliente</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="2" xl="2">
                                <Col >
                                    <FormGroup>
                                        <Label for="firstname">
                                            Nome
                                        </Label>
                                        <Input
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            required
                                            value={props.formData.firstname || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="lastname">
                                            Cognome
                                        </Label>
                                        <Input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            required
                                            value={props.formData.lastname || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="email">
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            required
                                            placeholder="example@example.com"
                                            valid={props.formData.formErrors.email === 'has-success'}
                                            invalid={props.formData.formErrors.email === 'has-danger'}
                                            value={props.formData.email || ""}
                                            onChange={(e) => {
                                                props.validateEmail(e);
                                                handleChange(e);
                                            }}
                                        />
                                        <FormFeedback>
                                            email non valida
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            email valida
                                        </FormFeedback>
                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="cell">
                                            Cellulare
                                            <IconButton onClick={addCell}>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Label>
                                        {
                                            props.formData.cell && props.formData.cell.map((cellu, index) => (
                                                <>
                                                    <Row>
                                                        <Col xs="9">
                                                            <Input
                                                                type="cell"
                                                                name="cell"
                                                                id="cell"
                                                                valid={props.formData.formErrors.cell[index] === 'has-success'}
                                                                invalid={props.formData.formErrors.cell[index] === 'has-danger'}
                                                                value={cellu ? cellu : ""}
                                                                onChange={(e) => {
                                                                    props.validateCell(index, e);
                                                                    handleChangeCell(index, e);
                                                                }}
                                                            />
                                                            <FormFeedback>
                                                                Numero di telefono non valido
                                                            </FormFeedback>
                                                            <FormFeedback valid>
                                                                Numero di telefono valido
                                                            </FormFeedback>
                                                        </Col>
                                                        <Col xs="3">
                                                            {index > 0 &&
                                                                <IconButton color="error" onClick={() => { removeCell(index) }}>
                                                                    <Delete />
                                                                </IconButton>
                                                            }
                                                        </Col>

                                                    </Row>
                                                </>
                                            ))
                                        }
                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="countryId">
                                            Paese
                                        </Label>
                                        <Select
                                            type="select"
                                            name="countryId"
                                            id="countryId"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.countryId || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.countryList && props.apiData.countryList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.code}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col >
                                <FormGroup>
                                    <Label for="info">
                                        Informazioni
                                    </Label>
                                    <textarea className="form-control" id="info" name="info" rows="5" value={props.formData.info || ""}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                    >
                                    </textarea>
                                </FormGroup>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={props.toggleModalEditing}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>

        </div>
    )
}

export default EditingClientModal;