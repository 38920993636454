import React from 'react';
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import { MenuItem, Tooltip, IconButton, Select, Box, Checkbox, TextField, Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { properties,searchRequestProperty, propertyById, propertyAddForm, createProperty, regionListByCountryCode, cityListByRegionId, cityListByCountryCode } from "../actions/admin";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DatePicker from "react-datepicker";
import moment from "moment";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Add from '@mui/icons-material/Add';
import FormLabel from '@mui/joy/FormLabel';

const NewPropertyModal = (props) => {
    const filter = createFilterOptions();
    const dispatch = useDispatch();
    const [showModalNewOwner, setShowModalNewOwner] = useState(false);
    const [isOwnerDisable, setIsOwnerDisable] = useState(true);
    const [isBrochure, setBrochure] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [ownerData, setOwnerData] = useState({
        ownerId: "",
        ownerEmail: "",
        ownerFirstname: "",
        ownerLastname: "",
        ownerCell: "",
        ownerFiscalCode: "",
        ownerAddress: "",
        ownerCountry: "",
        ownerRegion: "",
        ownerCity: "",
                formErrors: {}
    });
    const [isOwnerSubmitDisabled, setIsOwnerSubmitDisabled] = useState(false);

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        props.setFormData({
            ...props.formData,
            [name]: value
        });
        console.log(props.formData)
    };

    const handleNewSearchSubmit = (event) => {
        console.log(event)
        let newFormData = {};
        dispatch(searchRequestProperty(newFormData))
    
          .then((data) => {
            let clientmap = data.map(r => {return new Object({clientEmail : r.clientEmail, clientName: r.clientName, clientCell: r.clientCell.join(" - ")})});
            const unique = [];
            const flag = {};
            clientmap.forEach(element => {
                if(!flag[element.clientEmail]){
                    flag[element.clientEmail]=true;
                    unique.push(element);
                }
                
            });
            setClientList(unique);
          })
          .catch(() => {
            console.log("Error");
          });
    
      };


    const handleChangeNewFollowUpDate = (event) => {
        props.setFormData({
            ...props.formData,
            followupDate: event
        });
    };

    const handleChangeClassfication = (event) => {
        if( event.target.value==='4cedf08c-320d-11ee-be56-0242ac120002'){
            setBrochure(true);
        }else{
            setBrochure(false)
        }
        props.setFormData({
            ...props.formData,
            classification: event.target.value
        });
    };

    const handleChangeTagsNew = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            tagSelected: typeof value === 'string' ? value.split(',') : value
        });
    };


    const handleChangeCountryNew = (event) => {
        const {
            target: { value },
        } = event;

        props.setFormData({
            ...props.formData,
            country: value
        });

        dispatch(regionListByCountryCode(value))
            .then((data) => {
                dispatch(cityListByCountryCode(value))
                    .then((data1) => {
                        props.setApiData({
                            ...props.apiData,
                            regionList: data,
                            cityList: data1
                        })
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });


    };
    const handleChangeRegionNew = (event) => {
        const {
            target: { value },
        } = event;

        props.setFormData({
            ...props.formData,
            region: value
        });
        dispatch(cityListByRegionId(value))
            .then((data) => {
                props.setApiData({
                    ...props.apiData,
                    cityList: data
                })
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    };

    const handleChangeCityNew = (event) => {
        const {
            target: { value },
        } = event;

        props.setFormData({
            ...props.formData,
            city: value
        });
    };

    const handleChangeAgentId = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            idAgent: value
        })
    };

    const handleChangeFile = (event) => {
        props.setFile(event.target.files[0]);
    };



    const handleSubmit = (event) => {
        if (props.formData.followupDate) {
            props.formData.followupDate = moment(props.formData.followupDate).format("YYYY-MM-DD");
        }
        dispatch(createProperty(props.formData, props.file))
            .then((data) => {
                props.toggleModalNew();
                dispatch(properties())
                    .then((data) => {
                        props.reloadData(data);
                    })
                    .catch(() => {
                        console.log("Error");
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };
    const validatePrice = (e) => {
        const priceRex =/\d$/;
        let ret = true;
        let validate = props.formData.formErrors;

        if (priceRex.test(e.target.value)) {
            validate.price = 'has-success';
        } else {
            validate.price = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    const validateCell = (e) => {
        var cellRex = /^\d+$/;
        let ret = true;
        let validate = ownerData.formErrors;
        if (cellRex.test(e.target.value)) {
            validate.ownerCell = 'has-success';
        } else {
            validate.ownerCell = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    const validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = ownerData.formErrors;

        if (emailRex.test(e.target.value)) {
            validate.ownerEmail = 'has-success';
        } else {
            validate.ownerEmail = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    const validateEmailAfter = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = ownerData.formErrors;

        if (emailRex.test(e)) {
            validate.ownerEmail = 'has-success';
        } else {
            validate.ownerEmail = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };


    const toggleModalNewOwner = () => {
        if (showModalNewOwner) {
            setOwnerData({
                ownerId: "",
                ownerEmail: "",
                ownerFirstname: "",
                ownerLastname: "",
                ownerFiscalCode: "",
                ownerCountry: "",
                ownerRegion: "",
                ownerCity: "",
                ownerCell: "",
                formErrors: {}
            });
        }
        setShowModalNewOwner(!showModalNewOwner);
    };

    const handleCreateNewOwner = () => {
        setShowModalNewOwner(true);
    };

    const handleChangeCountryNewOwner = (event) => {
        const {
            target: { value },
        } = event;

        setOwnerData({
            ...ownerData,
            ownerCountry: value
        });

        dispatch(regionListByCountryCode(value))
            .then((data) => {
                dispatch(cityListByCountryCode(value))
                    .then((data1) => {
                        props.setApiData({
                            ...props.apiData,
                            regionList: data,
                            cityList: data1
                        })
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });


    };
    const handleChangeRegionNewOwner = (event) => {
        const {
            target: { value },
        } = event;

        setOwnerData({
            ...ownerData,
            ownerRegion: value
        });
        dispatch(cityListByRegionId(value))
            .then((data) => {
                props.setApiData({
                    ...props.apiData,
                    cityList: data
                })
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    };

    const handleChangeCityNewOwner = (event) => {
        const {
            target: { value },
        } = event;

        setOwnerData({
            ...ownerData,
            ownerCity: value
        });
    };


    const handleChangeOwner = (event) => {
        const { target } = event;
        const value = target.value;
        const { name } = target;

        setOwnerData({
            ...ownerData,
            [name]: value
        });
    };

    const handleNewOwnerSubmit = (event) => {
        let owns = props.formData.owners;
        if (!owns) {
            owns = [];
        }

        let localOwner = {
            id: ownerData.ownerId,
            email: ownerData.ownerEmail,
            firstname: ownerData.ownerFirstname,
            lastname: ownerData.ownerLastname,
            city: ownerData.ownerCity,
            address: ownerData.ownerAddress,
            fiscalCode:ownerData.ownerFiscalCode,
            cell: ownerData.ownerCell
        };

        owns.push(localOwner);
        props.setFormData({
            ...props.formData,
            owners: owns
        });

        toggleModalNewOwner(false);
        event.preventDefault();
    };

    const cityMapper = (cityId) => {
        if(cityId!=null){
            let cityObj1 = props.apiData.cityList.find(item => item.id === cityId);
            return cityObj1.name;
        }
        return;
        
    };
    const requiredField = () => {console.log(props.formData); if(isBrochure) return true; return false}; 

    return (
        <div>
            <Modal id="modalNew" className="modal-dialog-centered modal-xl" isOpen={props.showModalNew} toggle={props.toggleModalNew} >
                <ModalHeader >Nuovo Immobile</ModalHeader>

                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Tooltip arrow title="Create New Owner">
                            <IconButton onClick={handleCreateNewOwner}>
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                <Col>
                                    <FormGroup>
                                        <Label for="classification">
                                            Disponibilità
                                        </Label>
                                        <Select
                                            type="select"
                                            name="classification"
                                            id="classification"
                                            required
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.classification || ""}
                                            onChange={(e) => {
                                                handleChangeClassfication(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.classificationList && props.apiData.classificationList.map(t => (
                                                <MenuItem
                                                    key={t.description}
                                                    value={t.id}
                                                >
                                                    {t.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="idAgent">
                                            Email Agente
                                        </Label>
                                        <Select
                                            type="select"
                                            name="idAgent"
                                            id="idAgent"
                                            autocomplete
                                            required
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.idAgent || ""}
                                            onChange={handleChangeAgentId}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.userList && props.apiData.userList.map(t => (
                                                <MenuItem
                                                    key={t.email}
                                                    value={t.id}
                                                >
                                                    {t.email}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>

                                        <Label for="name">
                                            Nome Immobile
                                        </Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            required
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col >
                                    <FormGroup>
                                        <Label for="price">
                                            Prezzo
                                        </Label>
                                        <Input
                                            type="number"
                                            name="price"
                                            id="price"
                                            required
                                            valid={(props.formData.formErrors && props.formData.formErrors.price === 'has-success') || ""}
                                            invalid={(props.formData.formErrors && props.formData.formErrors.price === 'has-danger') || ""}
                                            onChange={(e) => {
                                                validatePrice(e);
                                                handleChange(e);
                                            }}
                
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="temporaryPrice">
                                            Prezzo Stimato
                                        </Label>
                                        <Checkbox
                                            name="temporaryPrice"
                                            id="temporaryPrice"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="mq">
                                            MQ
                                        </Label>
                                        <Input
                                             type="number"
                                            name="mq"
                                            id="mq"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="mqLand">
                                            MQ Terreno
                                        </Label>
                                        <Input
                                            
                                            name="mqLand"
                                            id="mqLand"
                                            type="number"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="rooms">
                                        Camere da letto
                                        </Label>
                                        <Input
                                             type="number"
                                            name="rooms"
                                            id="rooms"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="swimmingPool">
                                            Piscina
                                        </Label>
                                        <Checkbox
                                            name="swimmingPool"
                                            id="swimmingPool"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="address">
                                            Indirizzo
                                        </Label>
                                        <Input
                                            type="text"
                                            name="address"
                                            id="address"
    
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="latitude">
                                            Latitudine
                                        </Label>
                                        <Input
                                             type="number"
                                             step={0.00000000000000001}
                                            name="latitude"
                                            id="latitude"
                                            required
                                            value={props.formData.latitude || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="longitude">
                                            Longitudine
                                        </Label>
                                        <Input
                                             type="number"
                                             step={0.00000000000000001}
                                            name="longitude"
                                            id="longitude"
                                            value={props.formData.longitude || ""}
                                            required
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="country">
                                            Paese
                                        </Label>
                                        <Select
                                            type="select"
                                            name="country"
                                            id="country"
                                            required
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.country || ""}
                                            onChange={handleChangeCountryNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.countryList && props.apiData.countryList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.code}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="region">
                                            Regione
                                        </Label>
                                        <Select
                                            type="select"
                                            name="region"
                                            id="region"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.region || ""}
                                            onChange={handleChangeRegionNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.regionList && props.apiData.regionList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="city">
                                            Città
                                        </Label>
                                        <Select
                                            type="select"
                                            name="city"
                                            id="city"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.city || ""}
                                            onChange={handleChangeCityNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.cityList && props.apiData.cityList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="tags">
                                            Tags
                                        </Label>
                                        <Select
                                            type="select"
                                            name="tags"
                                            id="tags"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.tagSelected || []}
                                            onChange={handleChangeTagsNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.tagList && props.apiData.tagList.map(t => (
                                                <MenuItem
                                                    key={t.tag}
                                                    value={t.id}
                                                >
                                                    {t.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="status">
                                            Stato
                                        </Label>
                                        <Select
                                            type="select"
                                            name="status"
                                            id="status"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.status || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.statusList && props.apiData.statusList.map(t => (
                                                <MenuItem
                                                    key={t.description}
                                                    value={t.id}
                                                >
                                                    {t.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="propertyType">
                                            Tipologia
                                        </Label>
                                        <Select
                                            type="select"
                                            name="propertyType"
                                            id="propertyType"
                                            required
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.propertyType || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.propertyTypeList && props.apiData.propertyTypeList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="driveLink">
                                            Link Drive
                                        </Label>
                                        <Input
                                            type="text"
                                            name="driveLink"
                                            id="driveLink"
                                            //value={props.formData.driveLink}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="websiteLink">
                                            Website Link
                                        </Label>
                                        <Input
                                            type="text"
                                            name="websiteLink"
                                            id="websiteLink"
                                            //value={props.formData.driveLink}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                            <Col>
                                <FormGroup>
                                    <Label for="followupDate">
                                        Data Follow Up
                                    </Label>
                                    <DatePicker

                                        selected={props.formData.followupDate}
                                        dateFormat="yyyy-MM-dd"
                                        onChange={(e) => {
                                            handleChangeNewFollowUpDate(e);
                                        }}

                                    />
                                </FormGroup>
                                </Col>
                                <Col>
                                <FormGroup>
                                    <Label for="note">
                                        Note
                                    </Label>
                                    <textarea className="form-control" id="note" name="note" rows="5"
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                    ></textarea>
                                </FormGroup>

                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Label for="owners">
                                        <p className="fw-bold">Proprietari</p>
                                    </Label>
                                    <Box id="owners">
                                        <ol>
                                            {
                                                props.formData.owners && props.formData.owners.map((ow, idx) => <div><li key={"own_" + idx}>{ow.firstname} {ow.lastname} <Link onClick={() => {
                                                    let owns = props.formData.owners;
                                                    owns.splice(idx, 1);
                                                    props.setFormData({
                                                        ...props.formData,
                                                        owners: owns
                                                    });
                                                }
                                                }>Elimina</Link><ul><li>{ow.fiscalCode}</li>{ow.email && <li>{ow.email}</li>}{ow.cell && <li>{ow.cell}</li>}{ow.address && <li>{ow.address}</li>}{ow.city && <li>cityMapper(ow.city)</li>}</ul></li></div>)
                                            }
                                        </ol>
                                    </Box>
                                </Col>
                            </Row>
                            <FormGroup >
                                <Label for="brochure">
                                    Brochure
                                </Label>
                                <Input
                                    type="file"
                                    name="brochure"
                                    id="brochure"
                                    required={isBrochure}
                                    
                                    onChange={(e) => {
                                        handleChangeFile(e);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">
                                    Descrizione
                                </Label>
                                <textarea className="form-control" id="description" name="description" rows="5"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                ></textarea>
                            </FormGroup>
                            
                            <Row>
                                <Col>
                                    <Label for="clients">
                                        <p className="fw-bold">Clienti possibili</p>
                                    </Label>
                                    <Box id="clients">
                                        <ol>
                                            {
                                                clientList && clientList.map((ow, idx) => <div><li key={"own_" + idx}>{ow.clientName}<ul>{ow.clientEmail && <li>{ow.clientEmail}</li>}{ow.clientCell && <li>{ow.clientCell}</li>}</ul></li></div>)
                                            }
                                        </ol>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={() => {
                            setClientList([]); 
                            props.toggleModalNew();
                        }}>
                            Chiudi
                        </Button>
                        <Button className="btn btn-primary custom-btn" onClick={() => {
                            handleNewSearchSubmit(props.formData); 
                        }}>
                            Cerca Clienti
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <div>
                <Modal id="modalOwner" className="modal-dialog-centered modal-xl" isOpen={showModalNewOwner} toggle={toggleModalNewOwner} >
                    <ModalHeader>Nuovo proprietario</ModalHeader>
                    <Form onSubmit={handleNewOwnerSubmit}>
                        <ModalBody>
                            <Container>
                                <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                    <Col>
                                        <Label>C.F. Proprietario</Label>
                                        <Autocomplete
                                            value={ownerData.ownerFiscalCode || ""}
                                            onChange={(e, newValue) => {
                                                if (typeof newValue === 'string') {
                                                    if (newValue.startsWith('Aggiungi ')) {
                                                        newValue = newValue.substring(9);
                                                    }
                                                    // if(!validateCF(newValue)) {
                                                    //     setIsOwnerSubmitDisabled(true);
                                                    // } else {
                                                    //     setIsOwnerSubmitDisabled(false);
                                                    // }
                                                    // validateCF(newValue);
                                                    setOwnerData({
                                                        ...ownerData,
                                                        ownerFiscalCode: newValue
                                                    });
                                                } else if (newValue && newValue.inputValue) {
                                                    setOwnerData({
                                                        ...ownerData,
                                                        ownerEmail: newValue.email,
                                                        ownerFirstname: newValue.firstname,
                                                        ownerLastname: newValue.lastname,
                                                        ownerFiscalCode: newValue.fiscalCode,
                                                        ownerCountry: newValue.countryId,
                                                        ownerRegion: newValue.regionId,
                                                        ownerCity: newValue.cityId,
                                                        ownerId: newValue.id,
                                                        ownerCell: newValue.cell
                                                    });
                                                } else {
                                                    if (!newValue) {
                                                        setIsOwnerSubmitDisabled(true);
                                                        return;
                                                    }
                                                    if (newValue.fiscalCode.startsWith('Aggiungi ')) {
                                                        setIsOwnerDisable(false);
                                                        newValue.fiscalCode = newValue.fiscalCode.substring(9);
                                                    }
                                                    setIsOwnerSubmitDisabled(false);
                                                    // if(!validateCF(newValue.fiscalCode)) {
                                                    //     setIsOwnerSubmitDisabled(true);
                                                    // } else {
                                                    //     setIsOwnerSubmitDisabled(false);
                                                    // }
                                                    setOwnerData({
                                                        ...ownerData,
                                                        ownerEmail: newValue.email,
                                                        ownerFirstname: newValue.firstname,
                                                        ownerLastname: newValue.lastname,
                                                        ownerFiscalCode: newValue.fiscalCode,
                                                        ownerAddress: newValue.address,
                                                        ownerCountry: newValue.countryId,
                                                        ownerRegion: newValue.regionId,
                                                        ownerCity: newValue.cityId,
                                                        ownerId: newValue.id,
                                                        ownerCell: newValue.cell
                                                    });
                                                }

                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = props.apiData.ownerList.some((option) => inputValue === option.fiscalCode);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        fiscalCode: `Aggiungi ${inputValue}`
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            id="ownerFiscalCode"
                                            options={props.apiData.ownerList}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Regular option
                                                return option.fiscalCode;
                                            }}
                                            renderOption={(props, option) => (
                                                <AutocompleteOption {...props}>
                                                    {option.fiscalCode?.startsWith('Aggiungi ') && (
                                                        <ListItemDecorator>
                                                            <Add />
                                                        </ListItemDecorator>
                                                    )}

                                                    {option.fiscalCode}
                                                </AutocompleteOption>
                                            )}
                                            sx={{ minWidth: 230 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=""
                                                //error={ownerData.formErrors.ownerFiscalCode === 'has-danger'}
                                                //helperText="Email errata"
                                                />
                                            )}

                                        />
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerFirstname">
                                                Nome Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerFirstname"
                                                id="ownerFirstname"
                                                required
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerFirstname}
                                                onChange={(e) => {
                                                    handleChangeOwner(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerLastname">
                                                Cognome Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerLastname"
                                                id="ownerLastname"
                                                required
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerLastname}
                                                onChange={(e) => {
                                                    handleChangeOwner(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerCell">
                                                Cellulare Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerCell"
                                                id="ownerCell"
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerCell}
                                                valid={ownerData.formErrors.ownerCell === 'has-success'}
                                                invalid={ownerData.formErrors.ownerCell === 'has-danger'}
                                                onChange={(e) => {
                                                    validateCell(e);
                                                    handleChangeOwner(e);

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerEmail">
                                                Email Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerEmail"
                                                id="ownerEmail"
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerEmail}
                                                valid={ownerData.formErrors.ownerEmail === 'has-success'}
                                                invalid={ownerData.formErrors.ownerEmail === 'has-danger'}
                                                onChange={(e) => {
                                                    validateEmail(e);
                                                    handleChangeOwner(e);

                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="ownerAddress">
                                                Indirizzo Proprietario
                                            </Label>
                                            <Input
                                                type="text"
                                                name="ownerAddress"
                                                id="ownerAddress"
                                                disabled={isOwnerDisable}
                                                value={ownerData.ownerAddress}
                                                onChange={(e) => {
                                                    handleChangeOwner(e);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn btn-primary custom-btn" disabled={isOwnerSubmitDisabled} type="submit">
                                Salva
                            </Button>{' '}

                            <Button className="btn btn-primary custom-btn" onClick={() => {
                            toggleModalNewOwner(); 
                            setIsOwnerDisable(true);
                        }}>
                            Chiudi
                        </Button>
                        
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        </div >
    )
}

export default NewPropertyModal;