import { useDispatch } from 'react-redux';
import { useState, useCallback, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Box } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import VisitOutcomesPropertyViewModal from "./visitoutcomes.propertyview.modal.component";

const ClientsPropertyViewModal = (props) => {
    const dispatch = useDispatch();
    const [showModalVisit, setShowModalVisit] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [visitList, setVisitList] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [currentRow, setCurrentRow] = useState({});
    const [countryList, setCountryList] = useState({});

    const [apiData, setApiData] = useState({
        countryList: []
    })
    const [formData, setFormData] = useState({
        id: "",
        firstname: "",
        lastname: "",
        email: "",
        cell: "",
        info: "",
        countryname: "",
        countryId: "",
        formErrors: { email: '', cell: '' }
    });


    const toggleModalVisit = (row) => {
        if (row != null && row.original) {
            setFormData({
                ...formData,
                id: row.original.id
            });
        }
        setShowModalVisit(!showModalVisit)
        if (showModalVisit) { // is closing
            setVisitList([]);
        }

    };

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event)
                            : cell.column.id === 'cell' && cell.value !== ""
                                ? validateCell(event)
                                : false
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `Il campo ${cell.column.columnDef.header} non è valido`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [{
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false
        },
        {
            accessorKey: 'firstname',
            header: 'Nome'
        },
        {
            accessorKey: 'lastname',
            header: 'Cognome'
        },
        {
            accessorKey: 'email',
            header: 'Email',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'email',
            }),
        },
        {
            accessorKey: 'cellString',
            header: 'Cellulare',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell)
            })
        },
        {
            accessorKey: 'info',
            header: 'Info'
        }
            ,
        {
            accessorKey: 'countryname',
            header: 'Paese'
        }

        ],
        [getCommonEditTextFieldProps],
    );

    const validateCell = (e) => {
        var passwdRex = /^\d+$/;
        let ret = true;
        let validate = formData.formErrors;
        if (passwdRex.test(e.target.value)) {
            validate.cell = 'has-success';
        } else {
            validate.cell = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };


    const validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = formData.formErrors;

        if (emailRex.test(e.target.value)) {
            validate.email = 'has-success';
        } else {
            validate.email = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };

    return (
        <div>
            <Modal id="modalClients" className="modal-dialog-centered modal-xl" isOpen={props.showModalClients} toggle={props.toggleModalClients} >
                <ModalHeader >Lista Clienti</ModalHeader>
                <ModalBody>
                    <MaterialReactTable
                        columns={columns}
                        data={props.clientList}
                        initialState={{ columnVisibility: { id: false } }}
                        enableEditing
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '', //change header text
                                size: 5 //make actions column wider
                            },
                        }}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="right" title="Note">
                                    <IconButton onClick={() => toggleModalVisit(row)}>
                                        <EventNoteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        renderTopToolbarCustomActions={(table) => {
                            return (
                                <div>
                                    <VisitOutcomesPropertyViewModal showModalVisit={showModalVisit} toggleModalVisit={toggleModalVisit} formData={formData} propertyId={props.propertyId} />
                                </div>
                            );
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={props.toggleModalClients}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>
        </div>

    );
}

export default ClientsPropertyViewModal;