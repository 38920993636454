import React from 'react'
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import { Box, Tooltip, IconButton, MenuItem, Select } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { MaterialReactTable } from 'material-react-table';
import { useState, useEffect, useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { acquisitionListProperty, updateOutcomeClient, deleteAcquisitionProperty, properties, createDescriptionProperty, updateAcquisitionById } from "../actions/admin";
import "react-datepicker/dist/react-datepicker.css";

const AcquisitionNoteModal = (props) => {
    const [tableData, setTableData] = useState([]);
    const [showModalNewAcquisition, setShowModalNewAcquisition] = useState(false);

    const [formDataNew, setFormDataNew] = useState({
        id: "",
        propertyId: "",
        visitDate: new Date(),
        description: "",
        formErrors: {}
    });
    const [apiData, setApiData] = useState({
        propertyList: []
    });

    const dispatch = useDispatch();

    const toggleModalNewAcquisition = () => {
        setShowModalNewAcquisition(!showModalNewAcquisition)
    };

    const handleChangeNewAcquisition = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        setFormDataNew({
            ...formDataNew,
            [name]: value
        });
    };

    const handleChangeNewVisitDate = (event) => {
        setFormDataNew({
            ...formDataNew,
            visitDate: event
        });
    };

    const handleChangeNewFollowUpDate = (event) => {
        setFormDataNew({
            ...formDataNew,
            followupDate: event
        });
    };

    const handleNewAcquisitionSubmit = (event) => {
        setFormDataNew({
            ...formDataNew,
            propertyId: props.formData.id
        });
        formDataNew.visitDate = moment(formDataNew.visitDate).format("YYYY-MM-DD HH:mm:ss");
        if (formDataNew.followupDate) {
            formDataNew.followupDate = moment(formDataNew.followupDate).format("YYYY-MM-DD");
        }

        
        dispatch(createDescriptionProperty(formDataNew))
            .then((data) => {
                dispatch(acquisitionListProperty(props.formData.id))
                    .then((data) => {
                        setTableData(data);
                        setShowModalNewAcquisition(false);
                        setFormDataNew({
                            id: "",
                            propertyId: "",
                            visitDate: new Date(),
                            followupDate: new Date(),
                            description: "",
                            formErrors: {}
                        });
                    })
                    .catch(() => {
                        console.log("Error");
                    });
            })
            .catch(() => {
                console.log("Error");
            });

      event.preventDefault();
     };

    useEffect(() => {
        if (props.showModalAcquisition) {

            dispatch(acquisitionListProperty(props.formData.id))
                .then((data) => {
                    setTableData(data);
                })
                .catch(() => {
                    console.log("Error");
                });
        }
    }, [props.showModalAcquisition, props.formData.id, dispatch]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false

            },
            {
                accessorKey: 'visitDate',
                header: 'Data Visita'

            },
            {
                accessorKey: 'description',
                header: 'Descrizione'
            },
            {
                accessorKey: 'followupDate',
                header: 'Data Follow Up'

            }
        ],
        [],
    );

    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        tableData[row.index] = values;
        tableData[row.index].id = row.original.id;
        setTableData(
            [...tableData]
        )
        dispatch(updateAcquisitionById(tableData[row.index]))
            .then((data) => {
                //console.log(data);
            })
            .catch(() => {
                console.log("Error");
            });
        exitEditingMode();
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(`Sei sicuro di voler cancellare la nota di acquisizione inserita in data ${row.getValue('visitDate')}`)
            ) {
                return;
            }

            dispatch(deleteAcquisitionProperty(row.getValue('id')))
                .then((data) => {
                    //console.log(data);
                })
                .catch(() => {
                    console.log("Error");
                });
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData],
    );

    return (
        <div>
            <Modal id="modalAcquisition" className="modal-dialog-centered modal-xl" isOpen={props.showModalAcquisition} toggle={props.toggleModalAcquisition} >
                <ModalHeader toggle={props.toggleModalAcquisition}>Esiti acquisizioni</ModalHeader>
                <ModalBody>
                    <MaterialReactTable
                        columns={columns}
                        initialState={{ columnVisibility: { id: false } }}
                        data={tableData}
                        editingMode="modal"
                        enableEditing
                        onEditingRowSave={handleSaveRow}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 5
                            },
                        }}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip arrow placement="left" title="Modifica">
                                <IconButton onClick={() => { if(row.original.editable) {table.setEditingRow(row)}}}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip arrow placement="right" title="Cancella">
                                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip> */}
                            </Box>
                        )}
                        renderTopToolbarCustomActions={() => {
                            const handleCreateNewAcquisition = () => {
                                dispatch(properties())
                                    .then((data) => {
                                        setApiData({
                                            ...apiData,
                                            propertyList: data,
                                        });
                                        setFormDataNew({
                                            ...formDataNew, 
                                            propertyId: props.formData.id
                                        })
                                    })
                                    .catch((error) => {
                                        alert(error.response.data.message);
                                    });
                                setShowModalNewAcquisition(true);
                            };

                            return (
                                <div>
                                    <Tooltip arrow title="Create New Acquisition">
                                        <IconButton onClick={handleCreateNewAcquisition}>
                                            <AddBoxIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Modal id="modalAcquisition" className="modal-dialog-centered modal-xl" isOpen={showModalNewAcquisition} toggle={toggleModalNewAcquisition} >
                                        <ModalHeader >Nuova info</ModalHeader>
                                        <Form onSubmit={handleNewAcquisitionSubmit}>
                                            <ModalBody>
                                                <Container>
                                                    <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                                    
                                                      
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="visitDate">
                                                                    Data
                                                                </Label>
                                                                <DatePicker

                                                                    selected={formDataNew.visitDate}
                                                                    dateFormat="yyyy-MM-dd H:mm"
                                                                    onChange={(e) => {
                                                                        handleChangeNewVisitDate(e);
                                                                    }}
                                                                    showTimeSelect

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="followupDate">
                                                                    Data Follow Up
                                                                </Label>
                                                                <DatePicker

                                                                    selected={formDataNew.followupDate}
                                                                    required
                                                                    dateFormat="yyyy-MM-dd"
                                                                    onChange={(e) => {
                                                                        handleChangeNewFollowUpDate(e);
                                                                    }}

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="description">
                                                                    Descrizione
                                                                </Label>
                                                                <textarea className="form-control" id="description" name="description" rows="5"
                                                                    onChange={(e) => {
                                                                        handleChangeNewAcquisition(e);
                                                                    }}
                                                                ></textarea>

                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button className="btn btn-primary custom-btn" type="submit">
                                                    Salva
                                                </Button>{' '}
                                                <Button className="btn btn-primary custom-btn" onClick={toggleModalNewAcquisition}>
                                                    Chiudi
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                </div>
                            );
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={props.toggleModalAcquisition}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default AcquisitionNoteModal;