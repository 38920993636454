import { propertyRequestForm, addRequestForm, regionListByCountryCode, cityListByRegionId, cityListByCountryCode } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';

import Edit from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NewRequestPropertyViewModal from "./newrequest.propertyview.modal.component";
import EditingRequestPropertyViewModal from "./editingrequest.propertyview.modal.component";

const RequestsPropertyModal = (props) => {
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [showModalNew, setShowModalNew] = useState(false);
    const [showModalEditing, setShowModalEditing] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [showModalSearch, setShowModalSearch] = useState(false);
    const [apiData, setApiData] = useState({});
    const [formData, setFormData] = useState({
        id: "",
        agentName: "",
        propertyId: props.propertyId,
        clientName: "",
        clientEmail: "",
        clientCell: [],
        clientCountry: "",
        clientFirstname: "",
        clientLastname: "",
        clientId: "",
        maxMq: "",
        minMq: "",
        minPrice: "",
        maxPrice: "",
        maxPrice: "",
        longitude:"",
        latitude:"",
        price:"",
        mq:"",
        rooms:"",
        maxRooms: "",
        country:"",
        city: "",
        region: "",
        note: "",
        radius: "",
        createDateAgent:"",
        regionName:"",
        regionList:[],
        topRequest:false,
        createDate: new Date(),
        clientObj: {},
        propertyIdProposeList:[],
        property: { id: props.propertyId, name: '', address: '' },
        tagSelected: [],
        formErrors: { clientEmail: '' ,  clientCell: [""] }
    });
   

  const resetFormData = () => {
    /* setFormData({
      tagSelected: [],
      longitude:"",
        latitude:"",
        price:"",
        mq:"",
        rooms:"",
        radius:"",
      country:"",
      city: "",
      region: "",
    }); */
    setFormData({
        id: "",
        agentName: "",
        propertyId: props.propertyId,
        clientName: "",
        clientEmail: "",
        clientCell: [],
        clientCountry: "",
        clientFirstname: "",
        clientLastname: "",
        clientId: "",
        maxMq: "",
        minMq: "",
        minPrice: "",
        maxPrice: "",
        maxPrice: "",
        longitude:"",
        latitude:"",
        price:"",
        mq:"",
        rooms:"",
        maxRooms: "",
        country:"",
        city: "",
        region: "",
        note: "",
        radius: "",
        createDateAgent:"",
        regionName:"",
        regionList:[],
        topRequest:false,
        createDate: new Date(),
        clientObj: {},
        propertyIdProposeList:[],
        property: { id: props.propertyId, name: '', address: '' },
        tagSelected: [],
        formErrors: { clientEmail: '' ,  clientCell: [""] }
    });
  }


  const renderTopClient = (rowData) => {
    return rowData.topRequest ? "SI" : "NO"
}
    const toggleModalNew = () => {

        setFormData({
            formErrors: {}
        })
        setShowModalNew(!showModalNew)
    };

    const realoadList = (data) => {
        props.setRequestList(data);
    };

    const columns = useMemo(
        () => [{
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false
        },

        
    {
        accessorKey: 'createDateAgent',
        header: 'Data'
  
      },
      
  
      {
        accessorKey: 'topRequest',
        header: 'Richiesta Top',
        Cell: rowData => renderTopClient(rowData.row.original)
  
  
      },
        {
            accessorKey: 'clientName',
            header: 'Nome Cliente',
            enableEditing: false

        },
        {
            accessorKey: 'clientEmail',
            header: 'Email cliente',
            enableEditing: false
        },
        {
            accessorKey: 'clientCell',
            header: 'Cellulare Cliente',
            enableEditing: false

        },
        {
            accessorKey: 'propertyName',
            header: 'Identificativo immobile',
            enableEditing: false
        }
        ,
    {
      accessorKey: 'agentName',
      header: 'Agente'
        },
    {
      accessorKey: 'propertyProposeName',
      header: 'Immobili Proposti'
    },
        {
            accessorKey: 'note',
            header: 'Note'
        },
        {
            accessorKey: 'followupDate',
            header: 'Follow up',

        },
        {
            accessorKey: 'city',
            header: 'Città Immobile',
            enableEditing: false
        },
        {
            accessorKey: 'property.address',
            header: 'Indirizzo Immobile',
            enableEditing: false
        },
        {
            accessorKey: 'radius',
            header: 'Raggio Km',
            width: "10%"
        },
        {
            accessorKey: 'minPrice',
            header: 'Prezzo min',

        },
        {
            accessorKey: 'maxPrice',
            header: 'Prezzo max',

        },
        {
            accessorKey: 'minMq',
            header: 'Min MQ',

        },
        {
            accessorKey: 'maxMq',
            header: 'Max MQ',

        },
        {
            accessorKey: 'maxRooms',
            header: 'Camere da letto',

        },
        {
            accessorKey: 'regionName',
            header: 'Regione',

        },
        {
            accessorKey: 'tagName',
            header: 'Tag',

        }
        ],
    );


    const handleChangeCountryNew = (event) => {
        const {
            target: { value },
        } = event;

        setFormData({
            ...formData,
            country: value
        });

        dispatch(regionListByCountryCode(value))
            .then((data) => {
                dispatch(cityListByCountryCode(value))
                    .then((data1) => {
                        setApiData({
                            ...apiData,
                            regionList: data,
                            cityList: data1
                        })
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });


    };
    const handleChangeRegionNew = (event) => {
        const {
            target: { value },
        } = event;

        setFormData({
            ...formData,
            region: value
        });
        dispatch(cityListByRegionId(value))
            .then((data) => {
                setApiData({
                    ...apiData,
                    cityList: data
                })
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    };

    const handleChangeCityNew = (event) => {
        const {
            target: { value },
        } = event;

        setFormData({
            ...formData,
            city: value
        });
    };

    const toggleModalEditing = (row) => {
        if (row != null && row.original) {
            dispatch(propertyRequestForm(row.original.id))
                .then((data) => {
                    setFormData(data.request);

                    setApiData({
                        ...apiData,
                        tagList: data.tagList, 
                        agentList: data.userList,
            regionList: data.regionList,
            propertyList: data.propertyList
                    });
                })
                .catch(() => {
                    console.log("Error");
                });
        } else {
            resetFormData();
        }
        setShowModalEditing(!showModalEditing)
    };

    // if (isLoading) {
    //     return <div className="Requests">Loading...</div>;
    // }

    return (
        <>
        <div>
        <Modal id="modalRequests" className="modal-dialog-centered modal-xl" isOpen={props.showModalRequests} toggle={props.toggleModalRequests} >
                <ModalHeader>Lista Richieste</ModalHeader>
                <ModalBody>
                  <MaterialReactTable
                      columns={columns}
                      data={props.requestList}
                      initialState={{ columnVisibility: { id: false } }}
                      enableRowSelection
                      editingMode="modal" //default
                      enableEditing
                
                            //onEditingRowSave={handleSaveRowEdits}
                      displayColumnDefOptions={{
                          'mrt-row-actions': {
                              header: '', //change header text
                              size: 5, //set custom width
                              muiTableHeadCellProps: {
                                align: 'center', //change head cell props
                              },
                          },
                      }}
                      renderRowActions={({ row, table }) => (
                          <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                  <IconButton onClick={() => { if(row.original.editable) {toggleModalEditing(row)}}}>
                                      <Edit />
                                  </IconButton>
                              </Tooltip>
                          </Box>
                      )}
                      renderTopToolbarCustomActions={(table) => {
                          const handleCreateNewRequest = () => {
                              dispatch(addRequestForm())
                              
                                  .then((data) => {
                                              setApiData({
                                                  clientList: data.clientList,
                                                  propertyList: data.propertyList,
                                                  countryList: data.countryList,
                                                  tagList:data.tagList, 
                                                  userList:data.userList, 
                                                  regionList:data.regionList
                                              })
                                              
                                          })
                                  .catch((error) => {
                                      alert(error.response.data.message);
                                  });

                              setShowModalNew(true);
                          };

                          return (
                              <div>
                                  <Tooltip arrow title="Nuova Richiesta">
                                      <IconButton onClick={() =>{if(props.propertyDetails.editable) {handleCreateNewRequest()}}}>
                                          <AddBoxIcon />
                                      </IconButton>
                                  </Tooltip>
                              </div>
                          );
                      }}
                  />
          
                  <div>
                      <NewRequestPropertyViewModal showModalNew={showModalNew} toggleModalNew={toggleModalNew} setFormData={setFormData} formData={formData} apiData={apiData} realoadList={realoadList} />
                      <EditingRequestPropertyViewModal showModalEditing={showModalEditing} toggleModalEditing={toggleModalEditing} formData={formData} setFormData={setFormData} apiData={apiData} setApiData={setApiData} realoadList={realoadList} />

                  </div>
                  </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={props.toggleModalRequests}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
        </>


    );

}

export default RequestsPropertyModal;