import { properties, propertyById, deleteProperty, propertyAddForm, propertyEditingForm, getPropertyImages, isCurrentUserAdmin } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Image from "@mui/icons-material/Image";
import Download from "@mui/icons-material/Download";
import EventNoteIcon from '@mui/icons-material/EventNote'
import Delete  from '@mui/icons-material/Delete';
import { MenuItem, Box, Typography, TextField, Select } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import DetailPropertyModal from "./detailproperty.modal.component";
import NewPropertyModal from "./newproperty.modal.component";
import ImagesProperty from "./imagesproperty.modal.component";
import EditingPropertyModal from "./editingproperty.modal.component";
import AcqusitionNoteModal from "./propertyacquisitionnote.modal.component"
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

const Properties = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [propertyList, setPropertyList] = useState();
    const dispatch = useDispatch();
    const [propertyDetails, setPropertyDetails] = useState({});
    const [tableData, setTableData] = useState([]);
    const [showModalEditing, setShowModalEditing] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [showModalImages, setShowModalImages] = useState(false);
    const [showModalNew, setShowModalNew] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [uploadMessage, setUploadMessage] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const [imageList, setImageList] = useState([]);
    const [file, setFile] = useState();
    const [rowSelection, setRowSelection] = useState({});
    const [newdata, setNewdata] = useState([]);
    const [showModalAcquisition, setShowModalAcquisition] = useState(false);
    const [acquisitionList, setAcquisitionList] = useState([]);


    const [apiData, setApiData] = useState({
        classificationList: [],
        tagList: [],
        countryList: [],
        regionList: [],
        cityList: [],
        statusList: [],
        propertyTypeList: [],
        agentList: [],
        ownerList: [], 
        formErrors: {price:""}
    });
    const [formData, setFormData] = useState({
        id: "",
        idAgent: "",
        agenteUsername: "",
        name: "",
        price: "",
        mq: "",
        address: "",
        city: "",
        status: "",
        classification: "",
        propertyType: "",
        propertyTypeName:"",
        description: "",
        rooms: "",
        mqLand: "",
        swimmingPool: false,
        driveLink: "",
        websiteLink:"",
        latitude: "",
        longitude: "",
        country: "",
        region: "",
        tagSelected: [],
        ownerObj: { firstname: "", lastname: "", email: "", cell: "",fiscalCode:"" },
        owners: [],
        formErrors: {price:"", latitude:""}
    });

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    const toggleModalNew = () => {
        clearCacheData()
        
        setFormData({
            formErrors:{price:""}
        });
        setFile();
        setShowModalNew(!showModalNew)
    };

    const reloadData = (data) => {
        setPropertyList(data);
    };

    const toggleModalDetail = (row) => {
        if (row != null && row.original) {

            dispatch(propertyById(row.original.id))
            
                .then((data) => {

                    setPropertyDetails(data);
                })
                .catch(() => {
                    console.log("Error");
                });

            setFormData({
                ...formData,
                id: row.original.id
            });
        } else {
            setPropertyDetails({});
        }
        setShowModalDetail(!showModalDetail)
    };
    const toggleModalAcquisition = (row) => {
        if (row != null && row.original) {
            setFormData({
                ...formData,
                id: row.original.id
            });
        }
        setShowModalAcquisition(!showModalAcquisition)
        if (showModalAcquisition) { // is closing
            setAcquisitionList([]);
        }

    };
    const toggleModalEditing = (row) => {
        if (row != null && row.original) {

            dispatch(propertyEditingForm(row.original.id))
                .then((data) => {
                    data.property.formErrors = {price:""};
                    setFormData(data.property);
                    setApiData(data);
                })
                .catch(() => {
                    console.log("Error");
                });
        } else {
            setFormData({});
            setFile();
        }
        setShowModalEditing(!showModalEditing)
    };
    const toggleModalImages = (row) => {
        if (row != null && row.original) {
            setPropertyId(row.original.id);
            dispatch(getPropertyImages(row.original.id))
                .then((data) => {
                    setImageList(data);
                })
                .catch((error) => {
                    console.log("Error");
                    props.setUploadMessage(error.response.data.message);
                });
        }
        setShowModalImages(!showModalImages);
        setUploadMessage("");
        setImageList([]);
    };


    useEffect(() => {
        dispatch(properties())
            .then((data) => {
                setPropertyList(data);
                setLoading(false);
            })
            .catch(() => {
                console.log("Error");
            });
    }, []);

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                // error: !!validationErrors[cell.id],
                // helperText: validationErrors[cell.id],
                // onBlur: (event) => {
                //     const isValid =
                //         cell.column.id === 'email'
                //             ? validateEmail(event)
                //             : cell.column.id === 'username'
                //                 ? validateUsername(event)
                //                 : false
                //     if (!isValid) {
                //         setValidationErrors({
                //             ...validationErrors,
                //             [cell.id]: `Il campo ${cell.column.columnDef.header} non è valido`,
                //         });
                //     } else {
                //         delete validationErrors[cell.id];
                //         setValidationErrors({
                //             ...validationErrors,
                //         });
                //     }
                // },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [{
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false
        },
        {
            accessorKey: 'name',
            header: 'Nome'
        },
        {
            accessorKey: 'agentFullName',
            header: 'Agente',
        },
        {
            accessorKey: 'price',
            header: 'Prezzo'
        },
        {
            accessorKey: 'mq',
            header: 'MQ',
        },
        {
            accessorKey: 'rooms',
            header: 'Camere da letto',
        },
        {
            accessorKey: 'mqLand',
            header: 'MQ Terrerno',
        },
        {
            accessorKey: 'address',
            header: 'Indirizzo',
        },
        {
            accessorKey: 'city',
            header: 'Città',
        },
        {
            accessorKey: 'region',
            header: 'Regione',
        },
         {
            accessorKey: 'country',
            header: 'country'
        },
        {
            accessorKey: 'latitude',
            header: 'Latitudine',
        },
        {
            accessorKey: 'longitude',
            header: 'Longitudine',
        },
        
        {
            accessorKey: 'propertyTypeName',
            header: 'Tipologia'
        },    
        {
            accessorKey: 'classification',
            header: 'Disponibilità'
        },
        
        {
            accessorKey: 'status',
            header: 'Stato'
        }
        ,
        {
            accessorKey: 'swimmingPool',
            header: 'swimmingPool'
        }, 
        // {
        //     accessorKey: 'driveLink',
        //     header: 'Link Drive'
        // }, 
        {
            accessorKey: 'ownerFullName',
            header: 'Proprietario'
        }, 
        {
            accessorKey: 'ownerEmail',
            header: 'Email Proprietario'
        }, 
        {
            accessorKey: 'ownerCell',
            header: 'Cellulare Proprietario'
        }, 
        
        ],
        [getCommonEditTextFieldProps],
    );
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    // const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    // userList[row.index] = values;
    // values.enabled = values.enabled === 'Attivo' ? true : false;
    // values.type = values.role;
    // dispatch(updateUser(values))
    //     .then((data) => {
    //         console.log(data);
    //         window.location.reload();
    //     })
    //     .catch((error) => {
    //         alert(error.response.data.message);
    //     });
    // setUserList([...userList]);
    //  exitEditingMode();
    //};
    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(`Sei sicuro di voler cancellare l'immobile ${row.getValue('name')}?`)
            ) {
                return;
            }
            dispatch(deleteProperty(row.getValue('id')))
                .then((data) => {
                    dispatch(properties())
                    .then((data) => {
                        reloadData(data);
                    })
                    .catch(() => {
                        console.log("Error");
                    });
                })
                .catch(() => {
                    console.log("Error");
                });
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData],
    );

    const handleDownloadProperty = (rows) => {
        if(!isCurrentUserAdmin()) {
            alert("Operazione non consentita");
            return;
        }
        if(rows.length == 0){
            alert("Non è stato selezionato alcun record!");
            return;
        }
        let selRows = [];
        let keys = Object.keys(rowSelection);
        keys.forEach(key => {
            if(rowSelection[key]){
                selRows.push(rows[key])
            }
        });
        if(selRows.length == 0){
            csvExporter.generateCsv(rows.map((row) => {
                let newrow = { 
                    id : row.id,
                    name: row.original.name, 
                    agentFullName:  row.original.agentFullName, 
                    price: row.original.price, 
                    mq: row.original.mq,
                    rooms: row.original.rooms,
                    mqLand: row.original.mqLand,
                    address: row.original.address, 
                    city : row.original.city,
                    region : row.original.region,
                    country : row.original.country,
                    latitude : row.original.latitude, 
                    longitude:  row.original.longitude, 
                    propertyTypeName:  row.original.propertyTypeName, 
                    classification:  row.original.classification, 
                    status:  row.original.status, 
                    swimmingPool:  row.original.swimmingPool,
                    ownerFullName: row.original.agentFullName,
                    ownerEmail:row.original.ownerEmail,
                    ownerCell:row.original.ownerEmail,
                }
                
                return newrow;
            }));
        } else {
            csvExporter.generateCsv(selRows.map((row) => 
            {
                let newrow = { 
                    id : row.id,
                    name: row.original.name, 
                    agentFullName:  row.original.agentFullName, 
                    price: row.original.price, 
                    mq: row.original.mq,
                    rooms: row.original.rooms,
                    mqLand: row.original.mqLand,
                    address: row.original.address, 
                    city : row.original.city,
                    region : row.original.region,
                    country : row.original.country,
                    latitude : row.original.latitude, 
                    longitude:  row.original.longitude, 
                    propertyTypeName:  row.original.propertyTypeName, 
                    classification:  row.original.classification, 
                    status:  row.original.status, 
                    swimmingPool:  row.original.swimmingPool,
                    ownerFullName: row.original.agentFullName,
                    ownerEmail:row.original.ownerEmail,
                    ownerCell:row.original.ownerEmail,
                }
                
                return newrow;
            }));
        }
    }

    if (isLoading) {
        return <div className="Properties">Loading...</div>;
    }

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={propertyList}
                initialState={{ columnVisibility: { id: false, mqLand:false,swimmingPool:false, region:false, country:false, ownerFullName:false, ownerEmail:false, ownerCell:false, propertyTypeName:false, driveLink:false,  status:false} }}
                enableRowSelection
                editingMode="modal"
                enableEditing
                enableGrouping
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '', //change header text
                        size: 280,
                    },
                }}
                defaultColumn= {{
                    maxSize: 400,
                    minSize: 10,
                    size: 180, //default size is usually 180
                  }}
                  enableColumnResizing
                  columnResizeMode='onChange'
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => { if(row.original.editable) {toggleModalEditing(row)}} }>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Details">
                            <IconButton onClick={() => toggleModalDetail(row)}>
                                <Info />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Images">
                            <IconButton onClick={() => toggleModalImages(row)}>
                                <Image />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Note">
                            <IconButton onClick={() => toggleModalAcquisition(row)}>
                                <EventNoteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Cancella">
                            <IconButton color="error" onClick={() =>{ console.log(row.original); if(row.original.deletable) {handleDeleteRow(row)}}}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={({ table }) => {
                    const handleCreateNewProperty = () => {
                        dispatch(propertyAddForm())
                            .then((data) => {
                                setApiData(data);
                            })
                            .catch(() => {
                                console.log("Error");
                            });
                        setShowModalNew(true);
                    };

                    return (
                        <div>
                            <Tooltip arrow title="Create New Property">
                                <IconButton onClick={handleCreateNewProperty}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Download">

                                <IconButton onClick={() => handleDownloadProperty(table.getSelectedRowModel().rows)}>
                                    <Download />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
            />
            <NewPropertyModal showModalNew={showModalNew} toggleModalNew={toggleModalNew} setFile={setFile} file={file} setApiData={setApiData} apiData={apiData} setFormData={setFormData} formData={formData} reloadData={reloadData} />
            <DetailPropertyModal showModalDetail={showModalDetail} toggleModalDetail={toggleModalDetail} propertyDetails={propertyDetails} />
            <EditingPropertyModal showModalEditing={showModalEditing} toggleModalEditing={toggleModalEditing} setFile={setFile} file={file} formData={formData} setFormData={setFormData} apiData={apiData} setApiData={setApiData} reloadData={reloadData} caller="propertyList" />
            <ImagesProperty showModalImages={showModalImages} toggleModalImages={toggleModalImages} uploadMessage={uploadMessage} setUploadMessage={setUploadMessage} propertyId={propertyId} setImageList={setImageList} imageList={imageList} getPropertyImages={getPropertyImages} />
            <AcqusitionNoteModal showModalAcquisition={showModalAcquisition} toggleModalAcquisition={toggleModalAcquisition} formData={formData} />

        </div>
    );
}

export default Properties;