import {
  adminService_users, 
  adminService_create_user, 
  adminService_update_user, 
  adminService_update_password, 
  adminService_tags, 
  adminService_create_tag, 
  adminService_update_tag,
  adminService_properties,
  adminService_acquisitionlist_property,
  adminService_delete_acquisition_property,
  adminService_create_acquisition_property,
  adminService_clients,
  adminService_clients_by_propertyid,
  adminService_create_client, 
  adminService_addClientForm,
  adminService_editingClientForm,
  adminService_update_client, 
  adminService_delete_client,
  adminService_propertyById, 
  adminService_agentByPropertyId,
  adminService_editingPropertyForm, 
  adminService_addPropertyForm, 
  adminService_requestsforproprierty,
  adminService_requestsforpropriertybyid,
  adminService_regionListByCountryCode, 
  adminService_cityListByRegionId, 
  adminService_cityListByCountryCode,
  adminService_uploadPropertyImages, 
  adminService_create_property, 
  adminService_delete_property,
  adminService_downloadBrochure, 
  adminService_update_property, 
  adminService_getPropertyImages,
  adminService_deletePropertyImage, 
  adminService_create_request, 
  adminService_editingRequestForm, 
  adminService_update_request,
  adminService_addRequestForm,
  adminService_update_acquisition_by_id,
  adminService_outcomelist_client,
  adminService_update_outcome_client,
  adminService_delete_outcome_client, 
  adminService_create_outcome_client,
  adminService_owners, 
  adminService_delete_owner,
  adminService_delete_request,
  adminService_update_owner,
  adminService_addOwnerForm,
  adminService_propertiesForMap,
  adminService_requestforpropertieshomepage,
  adminService_propertiesSiblingsByRequest,
  adminService_searchParams,
  adminService_search, 
  adminService_searchRequest,
  adminService_downloadImage,
  adminService_mapProvider,
  adminService_urlCatasto, 
  findnumber_by_fiscal_code, 
  checkNumber_by_fiscal_code, 
  adminService_calendarEventsByMonth, 
  adminService_calendar_by_agents
  
} from "../services/admin.service";

export const currentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    return user;
  }
  return null;
};

export const isCurrentUserAdmin = () => {
  const user = currentUser();
  if(user && user.roles && user.roles.includes("ROLE_ADMIN")) {
    return true;
  }
  return false;
};

export const users = () => (dispatch) => {
    return adminService_users().then(
      (response) => {
        // dispatch({
        //   type: REGISTER_SUCCESS,
        // });
  
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: response.data.message,
        // });
        return response;
      },
      (error) => {
/*         const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString(); */
  
        // dispatch({
        //   type: REGISTER_FAIL,
        // });
  
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: message,
        // });
  
        return Promise.reject();
      }
    );
  };
  

  export const createProperty = (propertyData, file) => () => {
    return adminService_create_property(propertyData, file).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const updateProperty = (propertyData, file) => () => {
    return adminService_update_property(propertyData, file).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  
  export const updateRequest = (propertyData) => () => {
    return adminService_update_request(propertyData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  
  

  export const createUser = (userData) => () => {
    return adminService_create_user(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const createRequest = (userData) => () => {
    return adminService_create_request(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const updateUser = (userData) => () => {
    return adminService_update_user(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const updatePassword = (userData) => () => {
    return adminService_update_password(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    
  };

  export const tags = () => (dispatch) => {
    return adminService_tags().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  
  export const createTag = (userData) => () => {
    return adminService_create_tag(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const updateTag = (userData) => () => {
    return adminService_update_tag(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const properties = () => (dispatch) => {
    return adminService_properties().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const owners = () => (dispatch) => {
    return adminService_owners().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
 
  export const clients = () => (dispatch) => {
    return adminService_clients().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const clientsByPropertyId = (id) => (dispatch) => {
    return adminService_clients_by_propertyid(id).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const createClient = (userData) => (dispatch) => {
    return adminService_create_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const updateOwner = (userData) => () => {
    return adminService_update_owner(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const updateClient = (userData) => () => {
    return adminService_update_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const propertyClientForm = (id) => () => {
    return adminService_editingClientForm(id).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const outcomeListClient = (userData) => () => {
    return adminService_outcomelist_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  
  export const acquisitionListProperty = (userData) => () => {
    return adminService_acquisitionlist_property(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const createDescriptionProperty = (userData) => () => {
    return adminService_create_acquisition_property(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };


  export const createOutcomeClient = (userData) => () => {
    return adminService_create_outcome_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };


  export const updateAcquisitionById = (userData) => () => {
    return adminService_update_acquisition_by_id(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };  

  export const updateOutcomeClient = (userData) => () => {
    return adminService_update_outcome_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const deleteOutcomeClient = (userData) => () => {
    return adminService_delete_outcome_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const deleteProperty = (userData) => () => {
    return adminService_delete_property(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const deleteRequest = (userData) => () => {
    return adminService_delete_request(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const deleteOwner = (userData) => () => {
    return adminService_delete_owner(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const deleteClient= (userData) => () => {
    return adminService_delete_client(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const deleteAcquisitionProperty = (userData) => () => {
    return adminService_delete_acquisition_property(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const propertyById = (userData) => () => {
    return adminService_propertyById(userData).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const propertyEditingForm = (propertyId) => () => {
    return adminService_editingPropertyForm(propertyId).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  

  export const addRequestForm = () => () => {
    return adminService_addRequestForm().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const propertyRequestForm = (id) => () => {
    return adminService_editingRequestForm(id).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const propertyAddForm = () => () => {
    return adminService_addPropertyForm().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const clientAddForm = () => () => {
    return adminService_addClientForm().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const ownerAddForm = () => () => {
    return adminService_addOwnerForm().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const downloadBrochure = (id) => () => {
    return adminService_downloadBrochure(id).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };


  export const uploadPropertyImages = (propertyId, files) => () => {
    return adminService_uploadPropertyImages(propertyId, files).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const deletePropertyImage = (imageId) => () => {
    return adminService_deletePropertyImage(imageId).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const regionListByCountryCode = (countryCode) => () => {
    return adminService_regionListByCountryCode(countryCode).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };


  
  export const getPropertyImages = (propertyId) => () => {
    return adminService_getPropertyImages(propertyId).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  
  export const agentByPropertyId = (propertyId) => () => {
    console.log(propertyId)
    return adminService_agentByPropertyId(propertyId).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const cityListByRegionId = (regionId) => () => {
    return adminService_cityListByRegionId(regionId).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const cityListByCountryCode = (countryCode) => () => {
    return adminService_cityListByCountryCode(countryCode).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const requestsforproperty = () => (dispatch) => {
    return adminService_requestsforproprierty().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const requestsforpropertybyid = (id) => (dispatch) => {
    return adminService_requestsforpropriertybyid(id).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const requestsforpropertyhomepage = () => (dispatch) => {
    return adminService_requestforpropertieshomepage().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const propertiesformap = () => (dispatch) => {
    return adminService_propertiesForMap().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  
  export const propertiesSiblingsByRequest = (requestId) => (dispatch) => {
    return adminService_propertiesSiblingsByRequest(requestId).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const searchParams = () => (dispatch) => {
    return adminService_searchParams().then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const search = (searchRequest) => (dispatch) => {
    return adminService_search(searchRequest).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  export const searchRequestProperty = (searchRequest) => (dispatch) => {
    return adminService_searchRequest(searchRequest).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  
  export const downloadImage = (id) => (dispatch) => {
    return adminService_downloadImage(id).then(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Erroe:")
        console.log(error)
        return Promise.reject(error);
      }
    );
  };
  export const mapProvider = () => (dispatch) => {
    return adminService_mapProvider().then(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Errore:")
        console.log(error)
        return Promise.reject(error);
      }
    );
  };
  export const urlCatasto = () => (dispatch) => {
    return adminService_urlCatasto().then(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Errore:")
        console.log(error)
        return Promise.reject(error);
      }
    );
  };

  export const findNumerByFiscalCode = (cf) => (dispatch) => {
    return findnumber_by_fiscal_code(cf).then(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Erroe:")
        console.log(error)
        return Promise.reject(error);
      }
    );
  };
  
  
  export const checkNumberWithCf = (cf) => (dispatch) => {
    return checkNumber_by_fiscal_code(cf).then(
      (response) => {
        return response;
      },
      (error) => {
        console.log("Erroe:")
        console.log(error)
        return Promise.reject(error);
      }
    );
  };

  export const calendarEventsByMonth = (month) => () => {
    return adminService_calendarEventsByMonth(month).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  export const calendarEventsByAgents = (agents) => () => {
    return adminService_calendar_by_agents(agents).then(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  