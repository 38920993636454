import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarModal from './calendar.component';

const AgentMenu = (props) => {
    const user = useSelector(state => state.auth.user);
    const location = useLocation();
    const [url, setUrl] = useState(null);
    const [showModalCalendar, setShowModalCalendar] = useState(false);
    
    useEffect(() => {
      setUrl(location.pathname);
    }, [location]);

    const toggleModalCalendar = () => {
        setShowModalCalendar(!showModalCalendar);
      };

    return (
        <>
            <li className="nav-item">
                <IconButton className="nav-link" title="Calendario" onClick={toggleModalCalendar}>
                    <CalendarMonthIcon />
                </IconButton>
            </li>
            <li className="nav-item">
                <a className="nav-link" href={sessionStorage.getItem("urlCatasto")} target="_blank">
                    Catasto
                </a>
            </li>
            <li className="nav-item">
                <NavLink to="/properties" className={"underline" + (url === "/properties" ?" nav-link active" : " nav-link")}>
                    Immobili
                </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/clients" className={"underline" + (url === "/clients" ?" nav-link active" : " nav-link")}>
                    Clienti
                </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/requests" className={"underline" + (url === "/requests" ?" nav-link active" : " nav-link")}>
                    Richieste
                </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/owners" className={"underline" + (url === "/owners" ?" nav-link active" : " nav-link")}>
                    Proprietari
                </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/tags" className={"underline" + (url === "/tags" ?" nav-link active" : " nav-link")}>
                    Tags
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/findcellnumber" className={"underline" + (url === "/findcellnumber" ?" nav-link active" : " nav-link")} target="_blank">
                    Cerca Telefono
                </NavLink>
            </li>

            <CalendarModal showModalCalendar={showModalCalendar} toggleModalCalendar={toggleModalCalendar} />
        </>
    );
}

export default AgentMenu;