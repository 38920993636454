import { tags, createTag, updateTag } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Edit from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { sizing } from '@mui/system';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

const Tags = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [tagList, setTagList] = useState();
    const dispatch = useDispatch();
    const [showModalNew, setShowModalNew] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [formData, setFormData] = useState({
        id: "",
        tag: "",
        formErrors: { tag: '' }

    });

    const toggleModalNew = () => {
        setShowModalNew(!showModalNew)
    };


    useEffect(() => {
        dispatch(tags())
            .then((data) => {
                setTagList(data);
                setLoading(false);
            })
            .catch(() => {
                console.log("Error");
            });
    }, []);

    // const getCommonEditTextFieldProps = useCallback(
    //     (cell) => {
    //         return {
    //             error: !!validationErrors[cell.id],
    //             helperText: validationErrors[cell.id],
    //             onBlur: (event) => {
    //                 const isValid =
    //                     cell.column.id === 'tag'
    //                         ? validateTag(event)
    //                         : false
    //                 if (!isValid) {
    //                     setValidationErrors({
    //                         ...validationErrors,
    //                         [cell.id]: `Il campo ${cell.column.columnDef.header} non è valido`,
    //                     });
    //                 } else {
    //                     delete validationErrors[cell.id];
    //                     setValidationErrors({
    //                         ...validationErrors,
    //                     });
    //                 }
    //             },
    //         };
    //     },
    //     [validationErrors],
    // );

    const columns = useMemo(
        () => [{
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false
        },

        {
            accessorKey: 'tag',
            header: 'Tag',
            // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            //     ...getCommonEditTextFieldProps(cell),
            //     type: 'tag',
            // }),
        }
        ]
        //[getCommonEditTextFieldProps],
    );

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        tagList[row.index] = values;
        dispatch(updateTag(values))
            .then((data) => {
                window.location.reload();
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        setTagList([...tagList]);
        exitEditingMode();
    };

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        setFormData({
            ...formData,
            [name]: value
        });
    };


    // const validateTag = (e) => {
    //     var passwdRex = /^(?!.*\W)(?!.*\s).{3,20}$/;
    //     let ret = true;
    //     let validate = formData.formErrors;

    //     if (passwdRex.test(e.target.value)) {
    //         validate.tag = 'has-success';
    //     } else {
    //         validate.tag = 'has-danger';
    //         ret = false;
    //     }

    //     setFormData({
    //         ...formData,
    //         formErrors: validate
    //     });
    //     return ret;
    // };

    const handleSubmit = (event) => {
        dispatch(createTag(formData))
            .then((data) => {
                toggleModalNew();
                window.location.reload();
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };

    if (isLoading) {
        return <div className="Tags">Loading...</div>;
    }

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={tagList}
                initialState={{ columnVisibility: { id: false } }}
                //enableRowSelection
                editingMode="modal" //default
                enableEditing
                onEditingRowSave={handleSaveRowEdits}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '', //change header text
                        size: 5 //make actions column wider
                    },
                }}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => {
                    const handleCreateNewUser = () => {
                        setShowModalNew(true);
                    };

                    return (
                        <div>
                            <Tooltip arrow title="Create New Tag">
                                <IconButton onClick={handleCreateNewUser}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
            />
            <div>
                <Modal id="modalNew" isOpen={showModalNew} toggle={toggleModalNew} >
                    <ModalHeader >Nuovo Tag</ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="tag">
                                    Tag
                                </Label>
                                <Input
                                    type="text"
                                    name="tag"
                                    id="tag"
                                    required
                                    value={formData.tag}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn btn-primary custom-btn" type="submit">
                                Salva
                            </Button>{' '}
                            <Button className="btn btn-primary custom-btn" onClick={toggleModalNew}>
                                Chiudi
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        </div>

    );
}

export default Tags;