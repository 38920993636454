import { useState, useEffect } from "react";
import { Container } from 'reactstrap';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Image from "@mui/icons-material/Image";
import Group from "@mui/icons-material/Group";
import { Grid } from "@mui/material";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EventNoteIcon from '@mui/icons-material/EventNote'
import { useDispatch } from 'react-redux';
import { propertyById, propertyEditingForm, getPropertyImages, clientsByPropertyId, requestsforpropertybyid, acquisitionListProperty , propertiesformap} from "../actions/admin";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import DetailPropertyModal from "./detailproperty.modal.component";
import EditingPropertyModal from "./editingproperty.modal.component";
import ClientsPropertyViewModal from "./clientspropertyview.modal.component";
import RequestsPropertyModal from "./requestsproperty.modal.component";
import AcqusitionNotePropertyViewModal from "./acquisitionnotepropertyview.modal.component"
import ImagesProperty from "./imagesproperty.modal.component";
import ImageViewer from "./imageviewer.component";


const HomePropertyView = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [propertyDetails, setPropertyDetails] = useState({});
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [showModalEditing, setShowModalEditing] = useState(false);
    const [showModalImages, setShowModalImages] = useState(false);
    const [showModalClients, setShowModalClients] = useState(false);
    const [showModalRequests, setShowModalRequests] = useState(false);
    const [showModalAcquisition, setShowModalAcquisition] = useState(false);
    const [acquisitionList, setAcquisitionList] = useState([]);

    const [file, setFile] = useState();
    const [uploadMessage, setUploadMessage] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const [imageList, setImageList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [requestList, setRequestList] = useState([]);

    const [apiData, setApiData] = useState({
        classificationList: [],
        tagList: [],
        countryList: [],
        regionList: [],
        cityList: [],
        statusList: [],
        propertyTypeList: [],
        agentList: [],
        ownerList: [], 
        formErrors: {price:""}
    });
    const [formData, setFormData] = useState({
        id: "",
        idAgent: "",
        agenteUsername: "",
        name: "",
        price: "",
        mq: "",
        address: "",
        city: "",
        region:"",
        status: "",
        classification: "",
        propertyType: "",
        propertyTypeName: "",
        description: "",
        rooms: "",
        mqLand: "",
        swimmingPool: false,
        driveLink: "",
        latitude: "",
        longitude: "",
        country: "",
        region: "",
        clientId: "",
        clientEmail: "",
        clientFirstname: "",
        clientLastname: "",
        clientCountry: "",
        clientCell: [""],
        clientInfo:"",
        tagSelected: [],
        ownerObj: { firstname: "", lastname: "", email: "", cell: "" },
        owners: [],
        formErrors: {price:"", clientCell: [""]}
    });

    const reloadData = (data) => {
        setPropertyDetails(data);
        dispatch(propertiesformap())
      .then((data) => {
        props.setProperties(data);
      })
        
    };

    useEffect(() => {
        if (props.showPropertyDetailPopup) {
            dispatch(propertyById(props.propertyId))
                .then((data) => {
                    setPropertyDetails(data);
                    setLoading(false);
                })
                .catch(() => {
                    console.log("Error");
                });
        }
    }, []);

    const toggleModalAcquisition = (id) => {
        if (id != null && typeof (id) === 'string' && id.length > 0) {
            dispatch(acquisitionListProperty(id))
                .then((data) => {
                    setAcquisitionList(data);
                })
                .catch(() => {
                    console.log("Error");
                });
        } else {
            setAcquisitionList([]);
        }
        setShowModalAcquisition(!showModalAcquisition)

    };

    const toggleModalDetail = () => {
        setShowModalDetail(!showModalDetail)
    };

    const toggleModalClients = (id) => {
        if (id != null && typeof (id) === 'string' && id.length > 0) {
            dispatch(clientsByPropertyId(id))
                .then((data) => {
                    setClientList(data);
                    //setApiData(data);
                })
                .catch(() => {
                    console.log("Error");
                });
        } else {
            setClientList([]);
        }
        setShowModalClients(!showModalClients)
    };

    const toggleModalEditing = (id) => {
        if (id != null && typeof (id) === 'string' && id.length > 0) {
            dispatch(propertyEditingForm(id))
                .then((data) => {
                    data.property.formErrors = {price:""};
                    setFormData(data.property);
                    setApiData(data);
               
                })
                .catch(() => {
                    console.log("Error");
                });
        } else {
            setFormData({});
            setFile();
        }
        setShowModalEditing(!showModalEditing)
    };

    const toggleModalRequests = (id) => {
        if (id != null && typeof (id) === 'string' && id.length > 0) {
            dispatch(requestsforpropertybyid(id))
                .then((data) => {
                    setRequestList(data);
                })
                .catch(() => {
                    console.log("Error");
                });
        } else {
            setRequestList([]);
        }
        setShowModalRequests(!showModalRequests)
    };
    const responsive = {
        
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            paritialVisibilityGutter: 40},
        tablet: {
            breakpoint: { max: 500, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const toggleModalImages = (id) => {
        if (id != null && typeof (id) === 'string' && id.length > 0) {
            setPropertyId(id);
            dispatch(getPropertyImages(id))
                .then((data) => {
                    setImageList(data);
                })
                .catch((error) => {
                    console.log("Error");
                    props.setUploadMessage(error.response.data.message);
                });
        }
        if (showModalImages) {
            dispatch(propertyById(props.propertyId))
                .then((data) => {
                    setPropertyDetails(data);
                })
                .catch(() => {
                    console.log("Error");
                });
        }
        setShowModalImages(!showModalImages);
        setUploadMessage("");
        setImageList([]);
    };


    if (isLoading) {
        return <div className="Tags">Loading...</div>;
    }

    return (
        <div className="slider-container">
            <Container fluid >

            <Card>
      
            {propertyDetails.imagesList &&
                               
                    
                        <Carousel
                            additionalTransfrom={0}
                            showDots={true}
                            arrows={true}
                            autoPlaySpeed={3000}
                            autoPlay={false}
                            centerMode={false}
                            className="carousel-hero"
                            containerClass="container-with-dots carousel-container"
                            dotListClass="dots"
                            focusOnSelect={false}
                            itemClass="carousel-top"
                            keyBoardControl
                            minimumTouchDrag={50}
                            renderButtonGroupOutside={true}
                            responsive={responsive}
                          >
                            { propertyDetails.imagesList.map((photo, index) => (
                                    <div className="mt-5" key={index}>
                                      <ImageViewer id={photo.id}  key={index} /> 
                                    </div>
                                 ))}
                          
                        </Carousel>
                     
                                
                          }

                    <CardContent>

                        <Typography gutterBottom variant="h5" component="div">
                            <b>Rif. </b>  {propertyDetails.name}
                        </Typography>

                        <Typography gutterBottom variant="h7" component="div">
                            <b>Agente. </b>  {propertyDetails.agentObj && propertyDetails.agentObj.fullname}
                        </Typography>
                        <Typography gutterBottom variant="h7" component="div">
                            <b>Location. </b> {propertyDetails.regionObj && propertyDetails.regionObj.name}  -  {propertyDetails.cityObj && propertyDetails.cityObj.name}
                        </Typography>
                        <Typography variant="h7" component="div">
                            <b>Prezzo. </b> {propertyDetails.price} €
                        </Typography>
                        <Typography variant="h7" component="div">
                            <b>Prezzo Stimato. </b> {propertyDetails.temporaryPrice ? "Si" : "No"}
                        </Typography>
                        <Typography variant="h7" component="div">
                            <b>Camere da letto. </b> {propertyDetails.rooms}
                        </Typography>
                        <Typography variant="h7" component="div">
                            <b>Interni. </b> {propertyDetails.mq} mq
                        </Typography>
                        <Typography variant="h7" component="div">
                            <b>Esterni. </b>    {propertyDetails.mqLand} mq
                        </Typography>
                        <Typography variant="h7" component="div">
                            <b>Coordinate. </b>  ({propertyDetails.latitude} , {propertyDetails.longitude})
                        </Typography>
                        <Typography paragraph variant="h7">
                            {propertyDetails.descriptionFirstCharacters}
                        </Typography>
                    </CardContent>
                    <CardActions >
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                            <IconButton size="small" title="Dettagli" onClick={() => toggleModalDetail()}>
                                <Info />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton size="small" title="Modifica" onClick={() =>{if(propertyDetails.editable) {toggleModalEditing(propertyDetails.id)}}}>
                            <Edit />
                        </IconButton>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton size="small" title="Modifica Immagini" onClick={() =>{if(propertyDetails.editable) {toggleModalImages(propertyDetails.id)}}}>
                            <Image />
                        </IconButton>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton size="small" title="Lista Clienti" onClick={() => toggleModalClients(propertyDetails.id)}>
                            <Group />
                        </IconButton>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton size="small" title="Lista Richieste" onClick={() => toggleModalRequests(propertyDetails.id)}>
                            <ContactMailIcon />
                        </IconButton>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton size="small" title="Lista Note Agente" onClick={() => toggleModalAcquisition(propertyDetails.id)}>
                            <EventNoteIcon />
                        </IconButton>
                        </Grid>
</Grid>
                    </CardActions>
                
                </Card>
                
            </Container>
            <DetailPropertyModal showModalDetail={showModalDetail} toggleModalDetail={toggleModalDetail} propertyDetails={propertyDetails} />
            <EditingPropertyModal showModalEditing={showModalEditing} toggleModalEditing={toggleModalEditing} setFile={setFile} file={file} formData={formData} setFormData={setFormData} apiData={apiData} setApiData={setApiData} reloadData={reloadData} caller="mapView" />
            <ImagesProperty showModalImages={showModalImages} toggleModalImages={toggleModalImages} uploadMessage={uploadMessage} setUploadMessage={setUploadMessage} propertyId={propertyId} setImageList={setImageList} imageList={imageList} getPropertyImages={getPropertyImages} />
            <ClientsPropertyViewModal showModalClients={showModalClients} toggleModalClients={toggleModalClients} propertyId={props.propertyId} clientList={clientList} />
            <RequestsPropertyModal showModalRequests={showModalRequests} toggleModalRequests={toggleModalRequests} propertyId={props.propertyId} propertyDetails={propertyDetails} setRequestList={setRequestList} requestList={requestList} />
            <AcqusitionNotePropertyViewModal showModalAcquisition={showModalAcquisition} toggleModalAcquisition={toggleModalAcquisition} propertyDetails={propertyDetails} setAcquisitionList={setAcquisitionList} acquisitionList={acquisitionList} propertyId={props.propertyId} />
        </div>
    );
}

export default HomePropertyView;