import React from 'react'
import { downloadBrochure} from "../actions/admin";
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Container, Row, Col } from 'reactstrap';
import { Box, Link } from '@mui/material';

import ImageViewer from "./imageviewer.component";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DetailPropertyModal = (props) => {
    const dispatch = useDispatch();
    const brochureDownload = (id) => {
        let filename;
        dispatch(downloadBrochure(id))
            .then(response => {
                filename = response.headers['x-suggested-filename'];
                return response;
            })
            .then(response => {
                var url = window.URL.createObjectURL(response.data);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch(error => {
                console.error(error);
            });
    };
    const responsive = {
        
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            paritialVisibilityGutter: 40},
        tablet: {
            breakpoint: { max: 500, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <div>
            <Modal id="modalDetail" className="modal-dialog-centered modal-xl" isOpen={props.showModalDetail} toggle={props.toggleModalDetail} >
                <ModalHeader>Dettaglio Immobile</ModalHeader>
                <ModalBody>
                    <Container fluid>
                    <Row xs="1" sm="1" md="1" lg="1" xl="1">
                    {props.propertyDetails.imagesList &&
                    <Col >
                    <div >
                    <Carousel
                            additionalTransfrom={0}
                            showDots={true}
                            arrows={true}
                            autoPlaySpeed={3000}
                            autoPlay={false}
                            centerMode={false}
                            className="carousel-hero"
                            containerClass="container-with-dots"
                            dotListClass="dots"
                            focusOnSelect={false}
                            itemClass="carousel-top"
                            keyBoardControl
                            minimumTouchDrag={50}
                            renderButtonGroupOutside={true}
                            responsive={responsive}
                          >
                            { props.propertyDetails.imagesList.map((photo, index) => (
                                    <div className="mt-5" key={index}>
                                      <ImageViewer id={photo.id}  key={index} /> 
                                    </div>
                                 ))}
                          
                        </Carousel>
                    </div>
                    </Col>
}
                    </Row>
                    </Container>
                    <Container>
                        <Row xs="1" sm="1" md="3" lg="3" xl="3">
                            <Col >
                                <Label for="name">
                                    <p className="fw-bold"> Identificativo Immobile </p>
                                </Label>
                                <Box id="name">{props.propertyDetails.name}</Box>
                            </Col>
                            <Col >
                                <Label for="agent">
                                    <p className="fw-bold">Agente</p>
                                </Label>
                                <Box id="agent">{props.propertyDetails.agentObj != null ? props.propertyDetails.agentObj.name + " " + props.propertyDetails.agentObj.lastname  : ""} </Box>
                            </Col>
                            <Col >
                                <Label for="price">
                                    <p className="fw-bold">  Prezzo </p>
                                </Label>
                                <Box id="price">{props.propertyDetails.price}</Box>
                            </Col>
                            <Col >
                                <Label for="temporaryPrice">
                                    <p className="fw-bold">Prezzo Stimato</p>
                                </Label>
                                <Box id="temporaryPrice">{props.propertyDetails.temporaryPrice ? "Si" : "No"}</Box>
                            </Col>
                            <Col >
                                <Label for="MQ">
                                    <p className="fw-bold">MQ</p>
                                </Label>
                                <Box id="MQ">{props.propertyDetails.mq}</Box>
                            </Col>
                            <Col >
                                <Label for="rooms">
                                    <p className="fw-bold">Camere da letto</p>
                                </Label>
                                <Box id="rooms">{props.propertyDetails.rooms}</Box>
                            </Col>
                            <Col >
                                <Label for="mqLand">
                                    <p className="fw-bold">MQ Terreno</p>
                                </Label>
                                <Box id="mqLand">{props.propertyDetails.mqLand}</Box>
                            </Col>
                            <Col >
                                <Label for="swimmingPool">
                                    <p className="fw-bold">Piscina</p>
                                </Label>
                                <Box id="swimmingPool">{props.propertyDetails.swimmingPool ? "Si" : "No"}</Box>
                            </Col>
                            <Col >
                                <Label for="propertyType">
                                    <p className="fw-bold">Tipologia</p>
                                </Label>
                                <Box id="propertyType">{props.propertyDetails.propertyTypeObj != null ? props.propertyDetails.propertyTypeObj.name : ""}</Box>
                            </Col>
                            <Col >
                                <Label for="classification">
                                    <p className="fw-bold">Disponibilità</p>
                                </Label>
                                <Box id="classification">{props.propertyDetails.classificationObj != null ? props.propertyDetails.classificationObj.description : ""}</Box>
                            </Col>
                            <Col >
                                <Label for="status">
                                    <p className="fw-bold">Stato</p>
                                </Label>
                                <Box id="status">{props.propertyDetails.statusObj != null ? props.propertyDetails.statusObj.description : ""}</Box>
                            </Col>
                            <Col >
                                <Label for="address">
                                    <p className="fw-bold"> Indirizzo</p>
                                </Label>
                                <Box id="address">{props.propertyDetails.address}</Box>
                            </Col>
                            <Col  >
                                <Label for="latitude">
                                    <p className="fw-bold">Latitudine</p>
                                </Label>
                                <Box id="latitude">{props.propertyDetails.latitude}</Box>
                            </Col>
                            <Col >
                                <Label for="longitude">
                                    <p className="fw-bold">Longitudine</p>
                                </Label>
                                <Box id="longitude">{props.propertyDetails.longitude}</Box>
                            </Col>
                            <Col >
                                <Label for="city">
                                    <p className="fw-bold">Città</p>
                                </Label>
                                <Box id="city">{props.propertyDetails.cityObj != null ? props.propertyDetails.cityObj.name : ""}</Box>
                            </Col>
                            <Col >
                                <Label for="regionObj">
                                    <p className="fw-bold">Regione</p>
                                </Label>
                                <Box id="regionObj">{props.propertyDetails.regionObj != null ? props.propertyDetails.regionObj.name : ""}</Box>
                            </Col>
                            <Col >
                                <Label for="countryObj">
                                    <p className="fw-bold">Paese</p>
                                </Label>
                                <Box id="countryObj">{props.propertyDetails.countryObj != null ? props.propertyDetails.countryObj.name : ""}</Box>
                            </Col>
                            <Col >
                                <Label for="tags">
                                    <p className="fw-bold">Tags</p>
                                </Label>
                                <Box id="tags">
                                    {
                                        props.propertyDetails.tagList && props.propertyDetails.tagList.map(tg => <li key={tg.id}>{tg.tag}</li>)
                                    }
                                </Box>
                            </Col>
                        
                            <Col >
                                <Label for="owners">
                                    <p className="fw-bold">Proprietari</p>
                                </Label>
                                <Box id="owners">
                                    <ol>
                                    {
                                        props.propertyDetails.owners && props.propertyDetails.owners.map(ow => <li key={ow.id}>{ow.firstname} {ow.lastname} <ul><li>{ow.fiscalCode}</li>{ow.email && <li>{ow.email}</li>}{ow.cell && <li>{ow.cell}</li>}{ow.address && <li>{ow.address}</li>}</ul></li>)
                                    }
                                    </ol>
                                </Box>
                            </Col>
                            <Col>
                            <Label for="acquisitionNote">
                                    <p className="fw-bold">Note</p>
                                </Label>
                                <Box id="acquisitionNote">
                                    <ol>
                                    {
                                        props.propertyDetails.acquisitionNote && props.propertyDetails.acquisitionNote.map(ow =>  <li key={ow.id}><ul>{ow.visitDate && <li><b>Data Visita: </b> {ow.visitDate}</li>}{ow.followupDate && <li><b>Data followUp:</b> {ow.followupDate}</li>}{ow.description && <li><b>Descrizione:</b> {ow.description}</li>}</ul></li>)
                                    }
                                    </ol>
                                </Box></Col>
                            </Row>
                            <Label for="websiteLink">
                                    <p className="fw-bold">Website Link</p>
                                </Label>
                                
                                <Box id="websiteLink" >{props.propertyDetails.websiteLink}</Box>
                            <Label for="driveLink">
                                    <p className="fw-bold">Drive Link</p>
                                </Label>
                                
                                <Box id="driveLink" >{props.propertyDetails.driveLink}</Box>
                                <Label for="description">
                                    <p className="fw-bold">Descrizione</p>
                                </Label>
                                <Box id="description">{props.propertyDetails.description}</Box>
                            
                            <Row xs="1" sm="1" md="3" lg="3" xl="3">
                         
                            { props.propertyDetails.brochureFilename!== null ?
                                <Col >
                                    <Link 
                                        id= "brochure"
                                        component="button"
                                        onClick={() => {
                                            brochureDownload(props.propertyDetails.id);
                                        }}
                                    >
                                        Brochure
                                    </Link>
                                </Col>
                                : ""
                            }


                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={props.toggleModalDetail}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default DetailPropertyModal;