import { propertyRequestForm, deleteRequest, searchRequestProperty, requestsforproperty, addRequestForm, regionListByCountryCode, cityListByRegionId, cityListByCountryCode, updateRequest, isCurrentUserAdmin } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Download from "@mui/icons-material/Download";
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';

import { MenuItem, Box, Select, Stack, Chip } from '@mui/material';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import NewRequestModal from "./newrequest.modal.component";
import EditingRequestModal from "./editingrequest.modal.component";
import DetailRequestModal from "./detailrequest.modal.component"
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice her

const Requests = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [requestList, setRequestList] = useState();
  const [requestDetails, setRequestDetails] = useState({});

  const dispatch = useDispatch();
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEditing, setShowModalEditing] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showModalSearch, setShowModalSearch] = useState(false);
  const [apiData, setApiData] = useState({});
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [formData, setFormData] = useState({
    clientObj: {},
    id: "",
    agentName: "",
    agentId: "",
    propertyId: "",
    clientName: "",
    clientEmail: "",
    clientCell: [],
    clientCountry: "",
    clientFirstname: "",
    clientLastname: "",
    clientId: "",
    maxMq: "",
    minMq: "",
    minPrice: "",
    maxPrice: "",
    maxPrice: "",
    longitude: "",
    latitude: "",
    price: "",
    mq: "",
    rooms: "",
    maxRooms: "",
    country: "",
    city: "",
    region: "",
    note: "",
    radius: "",
    createDateAgent: "",
    topRequest: false,
    createDate: new Date(),
    property: { id: '', name: '', address: '' },
    tagSelected: [],
    propertyIdProposeList: [],
    regionList: [],
    formErrors: { clientEmail: '', clientCell: [""] }
  });

  const resetRequestFormData = () => {
    setFormData(
      {
        clientObj: {},
        id: "",
        agentName: "",
        agentId: "",
        propertyId: "",
        clientName: "",
        clientEmail: "",
        clientCell: [],
        clientCountry: "",
        clientFirstname: "",
        clientLastname: "",
        clientId: "",
        maxMq: "",
        minMq: "",
        minPrice: "",
        maxPrice: "",
        maxPrice: "",
        longitude: "",
        latitude: "",
        price: "",
        mq: "",
        rooms: "",
        maxRooms: "",
        country: "",
        city: "",
        region: "",
        note: "",
        radius: "",
        createDateAgent: "",
        topRequest: false,
        createDate: new Date(),
        property: { id: '', name: '', address: '' },
        tagSelected: [],
        propertyIdProposeList: [],
        regionList: [],
        formErrors: { clientEmail: '', clientCell: [""] }
      }
    );
  };

  const resetFormData = () => {
    setFormData({
      tagSelected: [],
      longitude: "",
      latitude: "",
      price: "",
      mq: "",
      rooms: "",
      radius: "",
      country: "",
      city: "",
      region: "",
    });
  };

  const toggleModalDetail = (row) => {
    if (row != null && row.original) {

      dispatch(propertyRequestForm(row.original.id))

        .then((data) => {
          console.log(data)
          setRequestDetails(data.request);
        })
        .catch(() => {
          console.log("Error");
        });

      setFormData({
        ...formData,
        id: row.original.id
      });
    } else {
      setRequestDetails({});
    }
    setShowModalDetail(!showModalDetail)
  };

  const toggleModalNew = () => {

    setFormData({
      formErrors: {}
    })
    setShowModalNew(!showModalNew)
  };

  const realoadList = (data) => {
    setRequestList(data);
  };

  const renderTopClient = (rowData) => {
    return rowData.topRequest ? "SI" : "NO"
  }

  useEffect(() => {
    dispatch(requestsforproperty())
      .then((data) => {
        setRequestList(data);
        setLoading(false);
      })
      .catch(() => {
        console.log("Error");
      });
  }, []);

  const columns = useMemo(
    () => [{
      accessorKey: 'id',
      header: 'Id',
      enableEditing: false
    },

    {
      accessorKey: 'createDateAgent',
      header: 'Data'

    },


    {
      accessorKey: 'topRequest',
      header: 'Richiesta Top',
      Cell: rowData => renderTopClient(rowData.row.original)


    },
    {
      accessorKey: 'clientName',
      header: 'Nome Cliente',
      enableEditing: false

    },
    {
      accessorKey: 'clientEmail',
      header: 'Email cliente',
      enableEditing: false
    },
    {
      accessorKey: 'clientCell',
      header: 'Cellulare Cliente',
      enableEditing: false

    },
    {
      accessorKey: 'propertyName',
      header: 'Identificativo immobile',
      enableEditing: false
    },
    {
      accessorKey: 'agentName',
      header: 'Agente'
    },
    {
      accessorKey: 'propertyProposeName',
      header: 'Immobili Proposti'
    },

    {
      accessorKey: 'note',
      header: 'Note'
    },
    {
      accessorKey: 'followupDate',
      header: 'Follow up',

    },
    {
      accessorKey: 'city',
      header: 'Città Immobile',
      enableEditing: false
    },
    {
      accessorKey: 'property.address',
      header: 'Indirizzo Immobile',
      enableEditing: false
    },
    {
      accessorKey: 'radius',
      header: 'Raggio Km',
      width: "10%"
    },
    {
      accessorKey: 'minPrice',
      header: 'Prezzo min',

    },
    {
      accessorKey: 'maxPrice',
      header: 'Prezzo max',

    },
    {
      accessorKey: 'minMq',
      header: 'Min MQ',

    },
    {
      accessorKey: 'maxMq',
      header: 'Max MQ',

    },
    {
      accessorKey: 'maxRooms',
      header: 'Max Stanze',

    },
    {
      accessorKey: 'regionName',
      header: 'Regione',

    },
    {
      accessorKey: 'tagName',
      header: 'Tag',

    }
    ],
  );
  const handleChangeRegion = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      regionList: typeof value === 'string' ? value.split(',') : value
    });
  };

  const handleChangePropertyPorpose = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      propertyIdProposeList: typeof value === 'string' ? value.split(',') : value
    });
  };
  const handleChangeCountryNew = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({
      ...formData,
      country: value
    });

    dispatch(regionListByCountryCode(value))
      .then((data) => {
        dispatch(cityListByCountryCode(value))
          .then((data1) => {
            setApiData({
              ...apiData,
              regionList: data,
              cityList: data1
            })
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      })
      .catch((error) => {
        alert(error.response.data.message);
      });


  };
  const handleChangeRegionNew = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({
      ...formData,
      region: value
    });
    dispatch(cityListByRegionId(value))
      .then((data) => {
        setApiData({
          ...apiData,
          cityList: data
        })
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const handleChangeCityNew = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({
      ...formData,
      city: value
    });
  };


  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    requestList[row.index] = values;;
    if (values['cell'] === "") {
      values['cell'] = null;
    }
    dispatch(updateRequest(values))
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
    setRequestList([...requestList]);
    exitEditingMode();
  };

  const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleChangeSearch = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDownloadProperty = (rows) => {
    if(!isCurrentUserAdmin()) {
      alert("Operazione non consentita");
      return;
    }
    if (rows.length == 0) {
      alert("Non è stato selezionato alcun record!");
      return;
    }
    let selRows = [];
    let keys = Object.keys(rowSelection);
    keys.forEach(key => {
      if (rowSelection[key]) {
        selRows.push(rows[key])
      }
    });
    if (selRows.length == 0) {
      csvExporter.generateCsv(rows.map((row) => {
        let newrow = {
          id: row.id,
          createDate: row.original.createDate,
          clientName: row.original.clientName,
          clientEmail: row.original.clientEmail,
          clientCell: row.original.clientCell,
          propertyName: row.original.propertyName,
          followupDate: row.original.followupDate,
          note: row.original.note,
          city: row.original.city,
          address: row.original.property.address,
          radius: row.original.radius,
          minPrice: row.original.minPrice,
          maxPrice: row.original.maxPrice,
          minMq: row.original.minMq,
          maxMq: row.original.maxMq,
          maxRooms: row.original.maxRooms,
          tag: row.original.tagName,
        }

        return newrow;
      }));
    } else {
      csvExporter.generateCsv(selRows.map((row) => {
        let newrow = {
          createDate: row.original.createDate,
          clientName: row.original.clientName,
          clientEmail: row.original.clientEmail,
          clientCell: row.original.clientCell,
          propertyName: row.original.propertyName,
          followupDate: row.original.followupDate,
          note: row.original.note,
          city: row.original.city,
          address: row.original.property.address,
          radius: row.original.radius,
          minPrice: row.original.minPrice,
          maxPrice: row.original.maxPrice,
          minMq: row.original.minMq,
          maxMq: row.original.maxMq,
          tag: row.original.tagName,
        }

        return newrow;
      }));
    }
  }
  const handleChangeSearchTags = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      tagSelected: typeof value === 'string' ? value.split(',') : value
    });
  };



  const toggleModalReset = () => {
    resetFormData();
    dispatch(requestsforproperty())
      .then((data) => {
        realoadList(data);
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const toggleModalSearch = () => {
    dispatch(addRequestForm())

      .then((data) => {
        setApiData({
          clientList: data.clientList,
          propertyList: data.propertyList,
          countryList: data.countryList,
          regionList: data.regionList,
          agentList: data.userList,
          tagList: data.tagList
        })
      })
      .catch((error) => {
        alert(error.response.data.message);
      });

    setShowModalSearch(true);
    setShowModalSearch(!showModalSearch);
  };
  const handleDeleteRow = useCallback(
    (row) => {
      if (
        !window.confirm(`Sei sicuro di voler cancellare la richiesta della proprietà ${row.getValue('propertyName')}`)
      ) {
        return;
      }
      dispatch(deleteRequest(row.getValue('id')))
        .then((data) => {
          dispatch(requestsforproperty())
            .then((data) => {
              realoadList(data);
            })
            .catch(() => {
              console.log("Error");
            });
        })
        .catch(() => {
          console.log("Error");
        });
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  const handleNewSearchSubmit = (event) => {
    let newFormData = {};
    let buttonClicked = event.nativeEvent.submitter.name;

    if (buttonClicked === "reset") {
      resetFormData();
    } else {
      newFormData = formData;
    }
    dispatch(searchRequestProperty(newFormData))

      .then((data) => {
        setRequestList(data);
      })
      .catch(() => {
        console.log("Error");
      });

    setShowModalSearch(false);
    event.preventDefault();
  };
  const toggleModalEditing = (row) => {
    if (row != null && row.original) {
      dispatch(propertyRequestForm(row.original.id))
        .then((data) => {
          setFormData(data.request);

          setApiData({
            ...apiData,
            tagList: data.tagList,
            agentList: data.userList,
            regionList: data.regionList,
            propertyList: data.propertyList

          });
        })
        .catch(() => {
          console.log("Error");
        });
    } else {
      resetRequestFormData();
    }
    setShowModalEditing(!showModalEditing)
  };

  if (isLoading) {
    return <div className="Requests">Loading...</div>;
  }

  return (
    <>
      <div>
        <div><Button className="btn btn-primary custom-btn" size="small" onClick={toggleModalSearch}>Cerca</Button>{' '}
          <Button className="btn btn-primary custom-btn" name="reset" onClick={toggleModalReset}>
            Reset
          </Button></div>
        <Modal id="modalSearch" className="modal-dialog-centered modal-xl" isOpen={showModalSearch} toggle={toggleModalSearch} >
          <ModalHeader >Cerca</ModalHeader>
          <Form onSubmit={handleNewSearchSubmit}>
            <ModalBody>
              <Container>
                <Row xs="1" sm="1" md="2" lg="4" xl="4">
                  <Col >
                    <FormGroup>
                      <Label for="agentId">
                        Agente
                      </Label>
                      <Select
                        type="select"
                        name="agentId"
                        id="agentId"

                        sx={{ minWidth: 230 }}
                        value={formData.agentId || ""}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      >
                        <MenuItem
                          key=""
                          value=""
                        ></MenuItem>
                        {apiData.agentList && apiData.agentList.map(t => (
                          <MenuItem
                            key={t.username}
                            value={t.id}
                          >
                            {t.username}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="propertyIdProposeList">
                        Immobili proposti
                      </Label>
                      <Select
                        type="select"
                        name="propertyIdProposeList"
                        id="propertyIdProposeList"
                        autocomplete
                        multiple
                        sx={{ minWidth: 230 }}
                        value={formData.propertyIdProposeList || []}
                        onChange={handleChangePropertyPorpose}
                        renderValue={(selected) => (
                          <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((value) => (
                              <Chip key={value} label={apiData.propertyList.filter(t => t.id == value).map(t => t.name)} />
                            ))}
                          </Stack>
                        )}
                      >
                        <MenuItem
                          key=""
                          value=""
                        ></MenuItem>
                        {apiData.propertyList && apiData.propertyList.map(t => (
                          <MenuItem
                            key={t.name}
                            value={t.id}
                          >
                            {t.name}
                          </MenuItem>
                        ))}

                      </Select>
                    </FormGroup>
                  </Col>
                  <Col >
                    <FormGroup>
                      <Label for="tags">
                        Tags
                      </Label>
                      <Select
                        type="select"
                        name="tags"
                        id="tags"
                        multiple
                        sx={{ minWidth: 230 }}
                        value={formData.tagSelected || []}
                        onChange={handleChangeSearchTags}
                      >
                        <MenuItem
                          key=""
                          value=""
                        ></MenuItem>
                        {apiData.tagList && apiData.tagList.map(t => (
                          <MenuItem
                            key={t.tag}
                            value={t.id}
                          >
                            {t.tag}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="mq">
                        MQ
                      </Label>
                      <Input
                        type="number"
                        name="mq"
                        id="mq"
                        value={formData.mq}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="price">
                        Prezzo
                      </Label>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        value={formData.price}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="rooms">
                        Camere
                      </Label>
                      <Input
                        type="number"
                        name="rooms"
                        id="rooms"
                        value={formData.rooms}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="latitude">
                        Latitudine
                      </Label>
                      <Input
                        type="number"
                        step={0.00000000000000001}

                        name="latitude"
                        id="latitude"
                        value={formData.latitude}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="longitude">
                        Longitudine
                      </Label>
                      <Input
                        type="number"
                        step={0.00000000000000001}

                        name="longitude"
                        id="longitude"
                        value={formData.longitude}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="radius">
                        Raggio Km
                      </Label>
                      <Input
                        type="number"
                        name="radius"
                        id="radius"
                        value={formData.radius}
                        onChange={(e) => {
                          handleChangeSearch(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="regionList">
                        Regione
                      </Label>
                      <Select
                        type="select"
                        name="regionList"
                        id="regionList"
                        multiple
                        sx={{ minWidth: 230 }}
                        value={formData.regionList || []}
                        onChange={(e) => {
                          handleChangeRegion(e);

                        }}
                        renderValue={(selected) => (
                          <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((value) => (
                              <Chip key={value} label={apiData.regionList.filter(t => t.id == value).map(t => t.name)} />
                            ))}
                          </Stack>
                        )}
                      >
                        <MenuItem
                          key=""
                          value=""
                        ></MenuItem>
                        {apiData.regionList && apiData.regionList.map(t => (
                          <MenuItem
                            key={t.name}
                            value={t.id}
                          >
                            {t.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  {/* 
                       <Col>
                    <FormGroup>
                      <Label for="country">
                        Paese
                      </Label>
                      <Select
                        type="select"
                        name="country"
                        id="country"
                        sx={{ minWidth: 230 }}
                        value={formData.country || ""}
                        onChange={handleChangeCountryNew}
                      >
                        <MenuItem
                          key=""
                          value=""
                        ></MenuItem>
                        {apiData.countryList && apiData.countryList.map(t => (
                          <MenuItem
                            key={t.name}
                            value={t.code}
                          >
                            {t.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  
                                <Col>
                    <FormGroup>
                      <Label for="city">
                        Città
                      </Label>
                      <Select
                        type="select"
                        name="city"
                        id="city"
                        sx={{ minWidth: 230 }}
                        value={formData.city || ""}
                        onChange={handleChangeCityNew}
                      >
                        <MenuItem
                          key=""
                          value=""
                        ></MenuItem>
                        {apiData.cityList && apiData.cityList.map(t => (
                          <MenuItem
                            key={t.name}
                            value={t.id}
                          >
                            {t.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup>
                    <Label for="roomsTo">
                      Camere - massimo
                    </Label>
                    <Input
                      type="number"
                      name="roomsTo"
                      id="roomsTo"
                      value={formData.roomsTo}
                      onChange={(e) => {
                        handleChangeSearch(e);
                      }}
                    />
                  </FormGroup>
                </Col> */}
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button className="btn btn-primary custom-btn" name="search" type="submit">
                Cerca
              </Button>{' '}
              <Button className="btn btn-primary custom-btn" name="reset" type="submit">
                Reset
              </Button>{' '}
              <Button className="btn btn-primary custom-btn" onClick={toggleModalSearch}>
                Chiudi
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>

      <div>
        <MaterialReactTable
          columns={columns}
          data={requestList}
          initialState={{ columnVisibility: { id: false } }}
          enableRowSelection
          editingMode="modal" //default
          enableEditing
          defaultColumn={{
            maxSize: 400,
            minSize: 120,
            size: 180, //default size is usually 180
          }}
          enableColumnResizing
          columnResizeMode='onChange'
          onEditingRowSave={handleSaveRowEdits}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: '', //change header text
              size: 60, //set custom width
              muiTableHeadCellProps: {
                align: 'center', //change head cell props
              },
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => { if (row.original.editable) { toggleModalEditing(row) } }}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Details">
                <IconButton onClick={() => toggleModalDetail(row)}>
                  <Info />
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow placement="right" title="Cancella">
              <IconButton color="error" onClick={() => { if (row.original.deletable) { handleDeleteRow(row) } }}>
                  <Delete />
                </IconButton>
               </Tooltip> */}
            </Box>
          )}
          renderTopToolbarCustomActions={(table) => {
            const handleCreateNewRequest = () => {
              // dispatch(addRequestForm())

              //   .then((data) => {
              //     setApiData({
              //       ...apiData,
              //       clientList: data.clientList,
              //       propertyList: data.propertyList,
              //       countryList: data.countryList,
              //       tagList: data.tagList,
              //       agentList: data.userList,
              //       regionList: data.regionList
              //     })
              //   })
              //   .catch((error) => {
              //     alert(error.response.data.message);
              //   });

              setShowModalNew(true);
            };

            return (
              <div>
                <Tooltip arrow title="Nuova Richiesta">
                  <IconButton onClick={handleCreateNewRequest}>
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Download">

                  <IconButton onClick={() => handleDownloadProperty(table.table.getSelectedRowModel().rows)}>
                    <Download />
                  </IconButton>
                </Tooltip>


              </div>
            );
          }}
        />

        <div>
          <NewRequestModal showModalNew={showModalNew} toggleModalNew={toggleModalNew} setFormData={setFormData} formData={formData} apiData={apiData} realoadList={realoadList} />
          <EditingRequestModal showModalEditing={showModalEditing} toggleModalEditing={toggleModalEditing} formData={formData} setFormData={setFormData} apiData={apiData} setApiData={setApiData} realoadList={realoadList} />
          <DetailRequestModal showModalDetail={showModalDetail} toggleModalDetail={toggleModalDetail} requestDetails={requestDetails} />

        </div>
      </div>
    </>


  );

}

export default Requests;