import { users, createUser, updateUser, updatePassword } from "../actions/admin";
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Edit from '@mui/icons-material/Edit';
import Password from '@mui/icons-material/Password';
import { MenuItem, Box } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

const Users = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [userList, setUserList] = useState();
    const dispatch = useDispatch();
    const [showModalNew, setShowModalNew] = useState(false);
    const [showModalPassword, setShowModalPassword] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [formData, setFormData] = useState({
        id: "",
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        type: "ADMIN",
        enabled: false,
        colorCode: "",
        standByCount:"",
        formErrors: { email: '', password: '', username: '', repassword: "" }
    });

    const toggleModalNew = () => {
        setShowModalNew(!showModalNew)
    };

    const toggleModalPassword = (row) => {
        if (row != null && row.original) {
            setFormData({
                ...formData,
                id: row.original.id
            });
        } 
        setShowModalPassword(!showModalPassword)
    };

    useEffect(() => {
        dispatch(users())
            .then((data) => {
                setUserList(data);
                setLoading(false);
            })
            .catch(() => {
                console.log("Error");
            });
    }, []);

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event)
                            : cell.column.id === 'username'
                                ? validateUsername(event)
                                : false
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `Il campo ${cell.column.columnDef.header} non è valido`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [{
            accessorKey: 'id',
            header: 'Id',
            enableEditing: false
        },

        {
            accessorKey: 'firstname',
            header: 'Nome'
        },
        {
            accessorKey: 'lastname',
            header: 'Cognome'
        },
        {
            accessorKey: 'email',
            header: 'Email',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'email',
            }),
        },
        {
            accessorKey: 'username',
            header: 'Username',
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'username',
            }),
        },
        {
            accessorKey: 'role',
            header: 'Tipo',
            muiTableBodyCellEditTextFieldProps: {
                select: true,
                children: ['ADMIN', 'AGENT'].map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                )),
            },
        },
        {
            accessorKey: 'enabled',
            header: 'Attivo',
            muiTableBodyCellEditTextFieldProps: {
                select: true,
                children: ['Attivo', 'Inattivo'].map((enabled) => (
                    <MenuItem key={enabled} value={enabled}>
                        {enabled}
                    </MenuItem>
                )),
            },
            
        }, 
        {
            accessorKey: 'colorCode',
            header: 'Codice Colore',
            Cell: (cell, column, rdval, row, table) => {
                return (
                    <div style={{ background: cell.renderedCellValue}}>{cell.renderedCellValue}</div>
                )
            }
        },
        {
            accessorKey: 'standByCount',
            header: 'N° StandBy', 
            enableEditing: false
        },
        
        ],
        [getCommonEditTextFieldProps],
    );

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        userList[row.index] = values;
        values.enabled = values.enabled === 'Attivo' ? true : false;
        values.type = values.role;
        dispatch(updateUser(values))
            .then((data) => {
                window.location.reload();
            })
            .catch((error) => {
                if(error.response.data.message) {
                    alert(error.response.data.message);
                }
                if(error.response.data.errors) {
                    alert(error.response.data.errors);
                }
            });
        setUserList([...userList]);
        exitEditingMode();
    };

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateEmail = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = formData.formErrors;

        if (emailRex.test(e.target.value)) {
            validate.email = 'has-success';
        } else {
            validate.email = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };

    const validatePassword = (e) => {
        var passwdRex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        let ret = true;
        let validate = formData.formErrors;

        if (passwdRex.test(e.target.value)) {
            validate.password = 'has-success';
        } else {
            validate.password = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };

    const confrontPassword = (e) => {
        var passwd = formData.password;
        let ret = true;
        let validate = formData.formErrors;

        if (e.target.value === passwd) {
            validate.repassword = 'has-success';
        } else {
            validate.repassword = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };

    const validateUsername = (e) => {
        var passwdRex = /^(?!.*\W)(?!.*\s).{5,15}$/;
        let ret = true;
        let validate = formData.formErrors;

        if (passwdRex.test(e.target.value)) {
            validate.username = 'has-success';
        } else {
            validate.username = 'has-danger';
            ret = false;
        }

        setFormData({
            ...formData,
            formErrors: validate
        });
        return ret;
    };

    const handleSubmit = (event) => {
        dispatch(createUser(formData))
            .then((data) => {
                toggleModalNew();
                window.location.reload();
            })
            .catch((error) => {
                if(error.response.data.message) {
                    alert(error.response.data.message);
                }
                if(error.response.data.errors) {
                    alert(error.response.data.errors);
                }
            });
        event.preventDefault();
    };

    const handleSubmitChangePassword = (event) => {
        dispatch(updatePassword(formData))
            .then((data) => {
                alert(data.message);
                toggleModalPassword(null);
                
            })
            .catch((error) => {
                if(error.response.data.message) {
                    alert(error.response.data.message);
                }
                if(error.response.data.errors) {
                    alert(error.response.data.errors);
                }
            });
    
        event.preventDefault();
    };

    if (isLoading) {
        return <div className="Users">Loading...</div>;
    }

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={userList}
                initialState={{ columnVisibility: { id: false } }}
                //enableRowSelection
                editingMode="modal" //default
                enableEditing
                defaultColumn= {{
                    maxSize: 400,
                    minSize: 10,
                    size: 120, //default size is usually 180
                  }}
                  enableColumnResizing
                  columnResizeMode='onChange'
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: '' //change header text
                    },
                  }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Change Password">
                            <IconButton onClick={() => toggleModalPassword(row)}>
                                <Password />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => {
                    const handleCreateNewUser = () => {
                        setShowModalNew(true);
                    };

                    return (
                        <div>
                            <Tooltip arrow title="Create New User">
                                <IconButton onClick={handleCreateNewUser}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    );
                }}
            />
            <div>
                <Modal id="modalNew" isOpen={showModalNew} toggle={toggleModalNew} >
                    <ModalHeader >Nuovo Utente</ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>

                            <FormGroup>
                                <Label for="firstname">
                                    Nome
                                </Label>
                                <Input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    required
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastname">
                                    Cognome
                                </Label>
                                <Input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    required
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="username">
                                    Username
                                </Label>
                                <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    required
                                    valid={formData.formErrors.username === 'has-success'}
                                    invalid={formData.formErrors.username === 'has-danger'}
                                    value={formData.username}
                                    onChange={(e) => {
                                        validateUsername(e);
                                        handleChange(e);
                                    }}
                                />
                                <FormFeedback>
                                    username non valido
                                </FormFeedback>
                                <FormFeedback valid>
                                    username valido
                                </FormFeedback>
                                <FormText>Lo username deve essere lungo almeno 8 caratteri, non contenere spazi e nemmeno caratteri speciali</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">
                                    Email
                                </Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    required
                                    placeholder="example@example.com"
                                    valid={formData.formErrors.email === 'has-success'}
                                    invalid={formData.formErrors.email === 'has-danger'}
                                    value={formData.email}
                                    onChange={(e) => {
                                        validateEmail(e);
                                        handleChange(e);
                                    }}
                                />
                                <FormFeedback>
                                    email non valida
                                </FormFeedback>
                                <FormFeedback valid>
                                    email valida
                                </FormFeedback>
                                <FormText>La password deve essere lunga da 8 a 15 caratteri e deve contenere almeno un numero, una lettera maiuscola, una lettera minuscola e un carattere speciale</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">
                                    Password
                                </Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    required
                                    placeholder="********"
                                    valid={formData.formErrors.password === 'has-success'}
                                    invalid={formData.formErrors.password === 'has-danger'}
                                    value={formData.password}
                                    onChange={(e) => {
                                        validatePassword(e);
                                        handleChange(e);
                                    }}
                                />
                                <FormFeedback>
                                    password non valida
                                </FormFeedback>
                                <FormFeedback valid>
                                    password valida
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="repassword">
                                    Conferma Password
                                </Label>
                                <Input
                                    type="password"
                                    name="repassword"
                                    id="repassword"
                                    required
                                    placeholder="********"
                                    valid={formData.formErrors.repassword === 'has-success'}
                                    invalid={formData.formErrors.repassword === 'has-danger'}
                                    onChange={(e) => {
                                        confrontPassword(e);
                                    }}
                                />
                                <FormFeedback>
                                    le password non coincidono
                                </FormFeedback>
                                <FormFeedback valid>
                                    le password coincidono
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="type">Tipologia</Label>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                >
                                    <option value="ADMIN">AMMINISTRATORE</option>
                                    <option value="AGENT">AGENTE</option>
                                </Input>
                            </FormGroup>
                            <FormGroup check >
                                <Input
                                    name="enabled"
                                    id="enabled"
                                    type="checkbox"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                <Label check>
                                    Attivo
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="colorCode">
                                    Codice colore
                                </Label>
                                <Input
                                    type="text"
                                    name="colorCode"
                                    id="colorCode"
                                    required
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                                 <FormText>Il codice colore deve essere scritto nella forma: #FFFFFF e deve essere diverso per ogni agente. </FormText>

                                </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn btn-primary custom-btn" type="submit">
                                Salva
                            </Button>{' '}
                            <Button className="btn btn-primary custom-btn" onClick={toggleModalNew}>
                                Chiudi
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>

            <div>
                <Modal id="modalPassword" isOpen={showModalPassword} toggle={toggleModalPassword} >
                    <ModalHeader >Cambio password</ModalHeader>
                    <Form onSubmit={handleSubmitChangePassword}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="password">
                                    Password
                                </Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    required
                                    placeholder="********"
                                    valid={formData.formErrors.password === 'has-success'}
                                    invalid={formData.formErrors.password === 'has-danger'}
                                    value={formData.password}
                                    onChange={(e) => {
                                        validatePassword(e);
                                        handleChange(e);
                                    }}
                                />
                                <FormFeedback>
                                    password non valida
                                </FormFeedback>
                                <FormFeedback valid>
                                    password valida
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="repassword">
                                    Conferma Password
                                </Label>
                                <Input
                                    type="password"
                                    name="repassword"
                                    id="repassword"
                                    required
                                    placeholder="********"
                                    valid={formData.formErrors.repassword === 'has-success'}
                                    invalid={formData.formErrors.repassword === 'has-danger'}
                                    onChange={(e) => {
                                        confrontPassword(e);
                                    }}
                                />
                                <FormFeedback>
                                    le password non coincidono
                                </FormFeedback>
                                <FormFeedback valid>
                                    le password coincidono
                                </FormFeedback>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn btn-primary custom-btn" type="submit">
                                Salva
                            </Button>{' '}
                            <Button className="btn btn-primary custom-btn" onClick={toggleModalPassword}>
                                Chiudi
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        </div>

    );
}

export default Users;