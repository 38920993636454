import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { login } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const localState = {
  username: "",
  password: "",
  loading: false,
};

const Login  = (props) => {
  const dispatch = useDispatch()

  var form = {};
  var checkBtn = {};

  function onChangeUsername(e) {
    localState.username = e.target.value;
  }

  function onChangePassword(e) {
    localState.password = e.target.value;
  }

  function  handleLogin(e) {
    e.preventDefault();
    localState.loading = true;

    form.validateAll();

    const { history } = props;

    if (checkBtn.context._errors.length === 0) {
      dispatch(login(localState.username, localState.password))
        .then(() => {
          history.push("/home");
          window.location.reload();
        })
        .catch(() => {
          localState.loading = false;
        });
    } else {
      localState.loading = false;
    }
  }

  const isLoggedIn = useSelector(state => (state.auth != null && state.auth.user != null) ? state.auth.user.isLoggedIn : false);
  const message = useSelector(state => state.message != null ? state.message.message : null);

  localState.loading = false;

  if (isLoggedIn) {
    return <Redirect to="/home" />;
  }

  return (
      <>
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={handleLogin}
            ref={(c) => {
              form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={localState.username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={localState.password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary-ib btn-block"
                disabled={localState.loading}
              >
                {localState.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
      </>
    );
}

export default Login;