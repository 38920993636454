import React from 'react'
import { Fragment, useState, useEffect, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import { MenuItem, Tooltip, IconButton, Select, Stack , Chip} from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useDispatch } from 'react-redux';
import { calendarEventsByMonth, users, calendarEventsByAgents } from '../actions/admin';
import { useMemo } from 'react';
import moment from 'moment';
import {
    Calendar,
    Views,
    DateLocalizer,
    momentLocalizer,
} from 'react-big-calendar'

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const messages = {
    previous: "←",
    next: "→",
    today: "Oggi",
    month: "Mese",
    week: "Settimana",
    day: "Giorno",
    date: "Data",
    time: "Tempo",
}
const CalendarModal = (props) => {
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [showAgentModal, setShowAgentModal] = useState(false);
    const [showMoreModal, setShowMoreModal] = useState(false);
    const [showMoreEvents, setShowMoreEvents] = useState([]);
    const [userList, setUserList] = useState();
    const [formData, setFormData] = useState({ idAgent: [] });

    const toggleAgentModal = () => {

        setShowAgentModal(!showAgentModal);
    };

    const displayMoreModal = (events) => {
        setShowMoreModal(!showMoreModal);
        setShowMoreEvents(events);
    }

    const toggleShowMoreModal = () => {
        if (showMoreModal) {
            setShowMoreEvents([]);
        }
        setShowMoreModal(!showMoreModal);
    }

    const handleChangeEventAgent = (event) => {
        const {
            target: { value },
        } = event;
        console.log(value)
        setFormData({
            ...formData,
            idAgent: typeof value === 'string' ? value.split(',') : value
        })
        console.log(formData)
    };


    const eventPropGetter = useCallback(
        (event, start, end, isSelected) => {
            var backgroundColor = event.agentColor;
            return {
                ...(backgroundColor && { style: { backgroundColor } })
            };
        }, []
    )

    function Event({ event }) {
        return (
            <span>
                <strong>{event.title}</strong>
                {event.desc && ':  ' + event.desc}
            </span>
        )
    }


    const showEventDetails = (e) => {
        setShowMoreEvents([e]);
        toggleShowMoreModal();
    }
    const handleSearchAgent = () => {
        setShowAgentModal(true);
    };
    const resetFormData = () => {
        setFormData({
            idAgent: []
        });
    }

    const handleNewSearchSubmit = (event) => {
        let newFormData = {};
        let buttonClicked = event.nativeEvent.submitter.name;

        if (buttonClicked === "reset") {
            resetFormData();
            const now = new Date();
            let month = now.getMonth() + 1;

            dispatch(calendarEventsByMonth(month))
            .then((data) => {
                
                let evts = [];
                let counter = 0;
                data.forEach(element => {
                    let e = {
                        id: counter,
                        title: element.title,
                        start: new Date(element.followUpDate[0], element.followUpDate[1] - 1, element.followUpDate[2], 0, 0, 0),
                        end: new Date(element.followUpDate[0], element.followUpDate[1] - 1, element.followUpDate[2], 12, 0, 0),
                        desc: element.description,
                        agentColor: element.agentColor,
                        event: Event,
                    };
                    evts.push(e);
                    counter++;
                });
                setEvents(evts);
            })
            .catch(() => {
                console.log("Error");
            }
            );
        } else {
            newFormData = formData;
            dispatch(calendarEventsByAgents(newFormData))
                .then((data) => {
                    let evts = [];
                    let counter = 0;
                    data.forEach(element => {
                        let e = {
                            id: counter,
                            title: element.title,
                            start: new Date(element.followUpDate[0], element.followUpDate[1] - 1, element.followUpDate[2], 0, 0, 0),
                            end: new Date(element.followUpDate[0], element.followUpDate[1] - 1, element.followUpDate[2], 12, 0, 0),
                            desc: element.description,
                            agentColor: element.agentColor,
                            event: Event,
                        };
                        evts.push(e);
                        counter++;
                    });
                    setEvents(evts);
                })
                .catch(() => {
                    console.log("Error");
                }
                )
        };
        setShowAgentModal(false);
        event.preventDefault();
    };


    useEffect(() => {

        if (props.showModalCalendar) {
            dispatch(users())
                .then((data) => {
                    setUserList(data);
                })
                .catch(() => {
                    console.log("Error");
                });
            const now = new Date();
            let month = now.getMonth() + 1;

            dispatch(calendarEventsByMonth(month))
                .then((data) => {
                    let evts = [];
                    let counter = 0;
                    data.forEach(element => {
                        let e = {
                            id: counter,
                            title: element.title,
                            start: new Date(element.followUpDate[0], element.followUpDate[1] - 1, element.followUpDate[2], 0, 0, 0),
                            end: new Date(element.followUpDate[0], element.followUpDate[1] - 1, element.followUpDate[2], 12, 0, 0),
                            desc: element.description,
                            agentColor: element.agentColor,
                            event: Event,
                        };
                        evts.push(e);
                        counter++;
                    });
                    setEvents(evts);
                })
                .catch(() => {
                    console.log("Error");
                }
                );
        }
    }, [props.showModalCalendar]);


    const { components, defaultDate } = useMemo(
        () => ({
            components: {
                event: Event,
            },
            defaultDate: new Date(),
        }),
        []
    );
    return (
        <div>
            <Modal className="modal-dialog-centered modal-xl" id="modalCalendar" isOpen={props.showModalCalendar} toggle={props.toggleModalCalendar} >
                <Tooltip arrow title="Search">
                    <IconButton onClick={handleSearchAgent}>
                        <PersonSearchIcon />
                    </IconButton>
                </Tooltip>
                <ModalBody>
                    <Fragment>
                        <Calendar
                            localizer={localizer}
                            events={events}
                            components={components}
                            defaultDate={defaultDate}
                            startAccessor="start"
                            endAccessor="end"
                            // onShowMore={(event) => {
                            //     displayMoreModal(event);
                            //   }}

                            views={{ month: true, day: true }}
                            style={{ height: 500 }}
                            eventPropGetter={eventPropGetter}
                            onSelectEvent={showEventDetails}
                            messages={messages}
                        />
                    </Fragment>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={props.toggleModalCalendar}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal className="modal-dialog-centered modal-l" id="modalMoreEvents" isOpen={showMoreModal} toggle={toggleShowMoreModal}>
                <ModalBody>
                    {
                        showMoreEvents && showMoreEvents.map(function (ev, index) {
                            var evStyle = {
                                backgroundColor: ev.agentColor,
                                borderRadius: '6px',
                                opacity: 0.8,
                                color: 'white',
                                border: '2px',
                                display: 'block',
                                margin: "8px",
                                padding: "10px"
                            };
                            var descrStyle = {
                                fontSize: 12
                            }
                            return (<div key={"moreEvents" + index} style={evStyle}><b>{ev.title}</b><div style={descrStyle}>{ev.desc}</div></div>)
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={toggleShowMoreModal}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal className="modal-dialog-centered modal-l" id="modalAgent" isOpen={showAgentModal} toggle={toggleAgentModal}>
                <ModalHeader >Cerca</ModalHeader>
                <Form onSubmit={handleNewSearchSubmit}>
                    <ModalBody>
                        <Col>
                            <FormGroup>
                                <Label for="idAgent">
                                    Email Agente
                                </Label>
                                <Select
                                    type="select"
                                    name="idAgent"
                                    id="idAgent"
                                    autocomplete
                                    multiple
                                    sx={{ minWidth: 230 }}
                                    value={formData.idAgent || []}
                                    onChange={handleChangeEventAgent}
                                    renderValue={(selected) => (
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                          {selected.map((value) => (
                                            <Chip key={value} label={userList.filter(t=>t.id==value).map(t=> t.email)} />
                                          ))}
                                        </Stack>
                                      )}
                                >
                                    
                                    <MenuItem
                                        key=""
                                        value=""
                                    ></MenuItem>
                                    {userList && userList.map(t => (
                                        <MenuItem
                                            key={t.email}
                                            value={t.id}
                                        >
                                            {t.email}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormGroup>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Cerca
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" name="reset" type="submit">
                            Reset
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={() => {
                            toggleAgentModal();
                        }}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>

    )
}

export default CalendarModal;