import React from 'react'
import { owners, updateOwner , regionListByCountryCode, cityListByRegionId, cityListByCountryCode, propertyEditingForm} from "../actions/admin";
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import { MenuItem, Select} from '@mui/material';

const EditingOwnerModal = (props) => {
    const dispatch = useDispatch();

    //console.log("editingOwnerModal - props.formData")
    //console.log(props.formData)

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        props.setFormData({
            ...props.formData,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        //console.log("editingOwnerModal - handleSubmit - props.formData")
        //console.log(props.formData)
        dispatch(updateOwner(props.formData))
            .then((data) => {
                props.toggleModalEditing();
                if(props.formData.fromComponent === "ownersList") {
                    dispatch(owners())
                    .then((data) => {
                        props.realoadList(data);
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
                } else if(props.formData.fromComponent === "editingProperty") {
                    dispatch(propertyEditingForm(props.formData.propertyId))
                    .then((data) => {
                        data.propertyId = props.formData.propertyId;
                        props.realoadList(data);
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
                }
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };
    
    const handleChangeCountryNew = (event) => {
        const {
            target: { value },
        } = event;

        props.setFormData({
            ...props.formData,
            countryId: value
        });

        dispatch(regionListByCountryCode(value))
            .then((data) => {
                dispatch(cityListByCountryCode(value))
                    .then((data1) => {
                        props.setApiData({
                            ...props.apiData,
                            regionList: data,
                            cityList: data1
                        })
                    })
                    .catch((error) => {
                        alert(error.response.data.message);
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });


    };
    const handleChangeRegionNew = (event) => {
        const {
            target: { value },
        } = event;

        props.setFormData({
            ...props.formData,
            regionId: value,
            cityId: null
                });
        if (value != null) {
            dispatch(cityListByRegionId(value))
                .then((data) => {
                    props.setApiData({
                        ...props.apiData,
                        cityList: data
                    })
                })
                .catch((error) => {
                    console.log(error)
                    alert(error);
                });
        }
    };

    const handleChangeCityNew = (event) => {
        const {
            target: { value },
        } = event;

        props.setFormData({
            ...props.formData,
            cityId: value
        });
    };


    return (
        <div>
            <Modal className="modal-dialog-centered modal-xl" id="modalEditing" isOpen={props.showModalEditing} toggle={props.toggleModalEditing} >
                <ModalHeader >Modifica Proprietario</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="2" xl="2">
                            <Col >
                                    <FormGroup>
                                        <Label for="fiscalCode">
                                            C.F.
                                        </Label>
                                        <Input
                                            type="text"
                                            name="fiscalCode"
                                            id="fiscalCode"
                                            required
                                            value={props.formData.fiscalCode || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="firstname">
                                            Nome
                                        </Label>
                                        <Input
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            required
                                            value={props.formData.firstname || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="lastname">
                                            Cognome
                                        </Label>
                                        <Input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            required
                                            value={props.formData.lastname || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="email">
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="example@example.com"
                                            valid={props.formData.formErrors.email === 'has-success'}
                                            invalid={props.formData.formErrors.email === 'has-danger'}
                                            value={props.formData.email || ""}
                                            onChange={(e) => {
                                                props.validateEmail(e);
                                                handleChange(e);
                                            }}
                                        />
                                        <FormFeedback>
                                            email non valida
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            email valida
                                        </FormFeedback>
                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="cell">
                                            Cellulare
                                        </Label>
                                        <Input
                                            type="cell"
                                            name="cell"
                                            id="cell"
                                            valid={props.formData.formErrors.cell === 'has-success'}
                                            invalid={props.formData.formErrors.cell === 'has-danger'}
                                            value={props.formData.cell ? props.formData.cell : ""}
                                            onChange={(e) => {
                                                props.validateCell(e);
                                                handleChange(e);
                                            }}
                                        />
                                        <FormFeedback>
                                            Numero di telefono non valido
                                        </FormFeedback>
                                        <FormFeedback valid>
                                            Numero di telefono valido
                                        </FormFeedback>
                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="address">
                                            Indirizzo
                                        </Label>
                                        <Input
                                            type="text"
                                            name="address"
                                            id="address"
                                            value={props.formData.address || ""}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />

                                    </FormGroup>
                                </Col >

                                <Col >
                                    <FormGroup>
                                        <Label for="countryId">
                                            Paese
                                        </Label>
                                        <Select
                                            type="select"
                                            name="countryId"
                                            id="countryId"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.countryId || ""}
                                            onChange={(e) => {
                                                handleChangeCountryNew(e);
                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.countryList && props.apiData.countryList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.code}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col >
                                <Col >
                                    <FormGroup>
                                        <Label for="regionId">
                                            Regione
                                        </Label>
                                        <Select
                                            type="select"
                                            name="regionId"
                                            id="regionId"
                                            sx={{ minWidth: 230 }}
                                            //value={props.formData.region || ""}
                                            value={props.formData.regionId || ""}
                                            onChange={handleChangeRegionNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.regionList && props.apiData.regionList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>


                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="cityId">
                                            Città
                                        </Label>
                                        <Select
                                            type="select"
                                            name="cityId"
                                            id="cityId"
                                            sx={{ minWidth: 230 }}
                                            //value={props.formData.city || ""}
                                            value={props.formData.cityId || ""}
                                            onChange={handleChangeCityNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {props.apiData.cityList && props.apiData.cityList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={props.toggleModalEditing}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>

        </div>
    )
}

export default EditingOwnerModal;