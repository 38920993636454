import React from 'react'
import { downloadBrochure} from "../actions/admin";
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Container, Row, Col } from 'reactstrap';
import { Box, Link } from '@mui/material';

import ImageViewer from "./imageviewer.component";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DetailRequestModal = (props) => {
    const dispatch = useDispatch();

    const responsive = {
        
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            paritialVisibilityGutter: 40},
        tablet: {
            breakpoint: { max: 500, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <div>
            <Modal id="modalDetailRequest" className="modal-dialog-centered modal-xl" isOpen={props.showModalDetail} toggle={props.toggleModalDetail} >
                <ModalHeader>Dettaglio Richiesta</ModalHeader>
                <ModalBody>
                  
                    <Container>
                        <Row xs="1" sm="1" md="3" lg="3" xl="3">
                            <Col >
                                <Label for="createDateAgent">
                                    <p className="fw-bold"> Data </p>
                                </Label>
                                <Box id="createDateAgent">{props.requestDetails.createDateAgent}</Box>
                            </Col>
                            <Col >
                                <Label for="topRequest">
                                    <p className="fw-bold">Richiesta Top </p>
                                </Label>
                                <Box id="topRequest">{props.requestDetails.topRequest  ? "Si" : "No"}</Box>
                            </Col>
                            <Col >
                                <Label for="clientName">
                                    <p className="fw-bold">Nome Cliente</p>
                                </Label>
                                <Box id="clientName">{props.requestDetails.clientName} </Box>
                            </Col>
                            <Col >
                                <Label for="clientEmail">
                                    <p className="fw-bold">Email Cliente</p>
                                </Label>
                                <Box id="clientEmail">{props.requestDetails.clientEmail} </Box>
                            </Col>
                           
                            <Col >
                                <Label for="propertyName">
                                    <p className="fw-bold">Identificativo Immobile</p>
                                </Label>
                                <Box id="propertyName">{props.requestDetails.propertyName}</Box>
                            </Col>
                            <Col >
                                <Label for="agentName">
                                    <p className="fw-bold">Agente</p>
                                </Label>
                                <Box id="agentName">{props.requestDetails.agentName}</Box>
                            </Col>
                            <Col >
                                <Label for="propertyPropose">
                                    <p className="fw-bold">Immobili Proposti</p>
                                </Label>
                                <Box id="propertyPropose">
                                   
                                    {
                                        props.requestDetails.propertyList && props.requestDetails.propertyList.map(r =><li key={r.id}>{r.name}</li>)
                                    }
                                    
                                </Box>
                                </Col>
                            <Col >
                                <Label for="note">
                                    <p className="fw-bold">Note</p>
                                </Label>
                                <Box id="note">{props.requestDetails.note}</Box>
                            </Col>
                            <Col >
                                <Label for="followupDate">
                                    <p className="fw-bold">Follow up </p>
                                </Label>
                                <Box id="followupDate">{props.requestDetails.followupDate}</Box>
                            </Col>
                            <Col >
                                <Label for="propertyCity">
                                    <p className="fw-bold">Città Immobile </p>
                                </Label>
                                <Box id="propertyCity">{props.requestDetails.property && props.requestDetails.property.city && props.requestDetails.property.city.name}</Box>
                            </Col>
                            <Col >
                                <Label for="propertyAddress">
                                    <p className="fw-bold">Indirizzo Immobile </p>
                                </Label>
                                <Box id="propertyAddress">{props.requestDetails.property && props.requestDetails.property.address}</Box>
                            </Col>
                            <Col >
                                <Label for="radius">
                                    <p className="fw-bold">Raggio</p>
                                </Label>
                                <Box id="radius">{props.requestDetails.radius}</Box>
                            </Col>
                            <Col >
                                <Label for="minMq">
                                    <p className="fw-bold">Min MQ</p>
                                </Label>
                                <Box id="minMq">{props.requestDetails.minMq}</Box>
                            </Col>
                            <Col >
                                <Label for="maxMq">
                                    <p className="fw-bold">Max MQ</p>
                                </Label>
                                <Box id="minMq">{props.requestDetails.maxMq}</Box>
                            </Col>
                            <Col >
                                <Label for="maxRooms">
                                    <p className="fw-bold">Max Stanze</p>
                                </Label>
                                <Box id="maxRooms">{props.requestDetails.maxRooms}</Box>
                            </Col>
                            <Col >
                                <Label for="tags">
                                    <p className="fw-bold">Tags</p>
                                </Label>
                                <Box id="tags">
                                    {
                                        props.requestDetails.tagList && props.requestDetails.tagList.map(tg => <li key={tg.id}>{tg.tag}</li>)
                                    }
                                </Box>
                            </Col>
                         
                            <Col >
                                <Label for="regions">
                                    <p className="fw-bold">Regione</p>
                                </Label>
                                <Box id="regions">
                                   
                                    {
                                        props.requestDetails.regionList && props.requestDetails.regionOBjList.map(r =><li key={r.id}>{r.name}</li>)
                                    }
                                    
                                </Box>
                                </Col>
                        
                            </Row>
                            
                            
                            
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary custom-btn" onClick={props.toggleModalDetail}>
                        Chiudi
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default DetailRequestModal;