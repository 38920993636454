import axios from "axios";
import authHeader from "./auth-header";
import qs from 'qs';
//const API_URL = "http://localhost:8886/api/ib/";
const API_URL = "https://ib.deepleey.com/api/ib/";


export const adminService_users = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.get(API_URL + "users", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_create_request = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    console.log("createrequest")
    console.log(userData)
    const response = await axios.post(API_URL + "createrequest", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_create_user = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.post(API_URL + "createuser", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_update_user = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updateuser", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_update_password = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "changepassword", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_tags = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    //console.log(config);
    const response = await axios.get(API_URL + "tags", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_create_tag = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.post(API_URL + "createtag", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_update_tag = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updatetag", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_properties = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.get(API_URL + "properties", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_owners = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.get(API_URL + "owners", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_clients = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.get(API_URL + "clients", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_clients_by_propertyid = async (id) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: id }
    }

    const response = await axios.get(API_URL + "clientsbypropertyid", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_create_client = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    console.log("createClient")
    console.log(userData)
    const response = await axios.post(API_URL + "createClient", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_update_owner = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updateowner", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_update_client = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updateclient", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_acquisitionlist_property = async (propertyId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { propertyId: propertyId }
    }

    const response = await axios.get(API_URL + "propertyacquisiotionbyproperty", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_outcomelist_client = async (clientId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { clientId: clientId }
    }

    const response = await axios.get(API_URL + "outcomeListByClient", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_update_outcome_client = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updateOutcomeById", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_update_acquisition_by_id = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updateacquisitionbyid", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_create_outcome_client = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "createOutcome", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_delete_property = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deletepropertyById", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_delete_client = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deleteclientById", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_delete_request = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deleterequestById", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_delete_owner = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deleteownerById", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_delete_outcome_client = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deleteOutcomeById", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_create_acquisition_property = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "createacquisitionnote", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_delete_acquisition_property = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deleteacquisitionbyid", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_create_property = async (propertyData, file) => {
  try {
    var headers = authHeader();
    headers = {
      ...headers,
      "Content-Type": "multipart/form-data"
    }
    var config = {
      headers: headers,
      mode: "cors",
      withCredentials: true
    }

    let formData = new FormData();
    formData.append('brochure', file);
    formData.append('propertyAddRequest', JSON.stringify(propertyData));
    const response = await axios.post(API_URL + "createproperty", formData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_update_property = async (propertyData, file) => {
  try {
    var headers = authHeader();
    headers = {
      ...headers,
      "Content-Type": "multipart/form-data"
    }
    var config = {
      headers: headers,
      mode: "cors",
      withCredentials: true
    }
    if (propertyData.status === '') {
      propertyData.status = null;
    }
    if (propertyData.city === '') {
      propertyData.city = null;
    }
    if (propertyData.classification === '') {
      propertyData.classification = null;
    }
    if (propertyData.propertyType === '') {
      propertyData.propertyType = null;
    }
    if (propertyData.region === '') {
      propertyData.region = null;
    }

    let formData = new FormData();
    formData.append('brochure', file);
    formData.append('propertyUpdateRequest', JSON.stringify(propertyData));
    const response = await axios.post(API_URL + "updateproperty", formData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_propertyById = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.post(API_URL + "findpropertybyid", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_addPropertyForm = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
    }
    const response = await axios.get(API_URL + "addpropertyform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_addOwnerForm = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
    }
    const response = await axios.get(API_URL + "addownerform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_addClientForm = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
    }
    const response = await axios.get(API_URL + "addclientform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_editingClientForm = async (id) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: id }

    }
    const response = await axios.get(API_URL + "editingclientform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_editingPropertyForm = async (propertyId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: propertyId }

    }
    const response = await axios.get(API_URL + "editingpropertyform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_update_request = async (userData) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "updaterequest", userData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_addRequestForm = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "addrequestform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_editingRequestForm = async (id) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: id }

    }
    const response = await axios.get(API_URL + "editingrequestform", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_downloadBrochure = async (id) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: id },
      responseType: 'blob'

    }

    const response = await axios.get(API_URL + "downloaddoc/" + id, config);
    return response;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_uploadPropertyImages = async (propertyId, files) => {
  try {
    var headers = authHeader();
    headers = {
      ...headers,
      "Content-Type": "multipart/form-data"
    }
    var config = {
      headers: headers,
      mode: "cors",
      withCredentials: true
    }

    let formData = new FormData();
    files.forEach(img => {
      formData.append("files", img.file);
    })
    formData.append("propertyId", propertyId);

    const response = await axios.post(API_URL + "uploadpropertyimages", formData, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_regionListByCountryCode = async (countryCode) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { country: countryCode }
    }

    const response = await axios.get(API_URL + "regionlistbycountrycode", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_getPropertyImages = async (propertyId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { propertyId: propertyId }
    }
    const response = await axios.get(API_URL + "getpropertyimages", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_deletePropertyImage = async (imageId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "deletepropertyimage", imageId, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_agentByPropertyId = async (propertyId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: propertyId }
    }
    const response = await axios.get(API_URL + "agentbypropertyid", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_cityListByRegionId = async (regionId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: regionId }
    }
    const response = await axios.get(API_URL + "citylistbyregionid", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_cityListByCountryCode = async (countryCode) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { country: countryCode }
    }
    const response = await axios.get(API_URL + "citylistbycountrycode", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_requestsforproprierty = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "requestforproperties", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_requestsforpropriertybyid = async (id) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { id: id }
    }
    const response = await axios.get(API_URL + "requestforpropertiesbyid", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_requestforpropertieshomepage = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "requestforpropertieshomepage", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_propertiesForMap = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "propertiesformap", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_propertiesSiblingsByRequest = async (requestId) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { requestId: requestId }
    }
    const response = await axios.get(API_URL + "propertiessiblingsbyrequest", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_searchParams = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "searchparams", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_search = async (searchRequest) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "search", searchRequest, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}


export const adminService_searchRequest = async (searchRequest) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }

    const response = await axios.post(API_URL + "searchrequest", searchRequest, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_downloadImage = async (id) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      responseType: 'blob'
    }
    const response = await axios.get(API_URL + "downloadimage/" + id, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_mapProvider = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "mapprovider", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}
export const adminService_urlCatasto = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "urlcatasto", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_fiscalCodeServiceConf = async () => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
    }
    const response = await axios.get(API_URL + "fiscalcodeserviceconf", config);
    console.log("adminService_fiscalCodeServiceConf");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const findnumber_by_fiscal_code = async (cf) => {
  const responseConfig = await adminService_fiscalCodeServiceConf();
  const data ={
    api_key: responseConfig.key,
    'fc[]': cf
  };
const options = {
  method: 'POST',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  data: qs.stringify(data),
  url: responseConfig.url
};  
  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const checkNumber_by_fiscal_code = async (cf) => {
  const responseConfig = await adminService_fiscalCodeServiceConf();
  const data ={
    api_key: responseConfig.key,
    'fc[]': cf
  };
const options = {
  method: 'POST',
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
  data: qs.stringify(data),
  url: responseConfig.url
};  
  try {
    const response = await axios(options);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

// export const findnumber_by_fiscal_code = async (cf) => {
//   const data ={
//     api_key: 'e4OsqRRm-Omdc-mZx9-N3ta-JWoH4Bic6Wwg',
//     'fc[]': cf
//   };
// const options = {
//   method: 'POST',
//   headers: { 'content-type': 'application/x-www-form-urlencoded' },
//   data: qs.stringify(data),
//   url: 'https://api.cercanumeripro.it/fiscal.php/'
// };  
//   try {
//    const response = await axios(options);
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// }

// export const checkNumber_by_fiscal_code = async (cf) => {
//   const data ={
//     api_key: 'e4OsqRRm-Omdc-mZx9-N3ta-JWoH4Bic6Wwg',
//     'fc[]': cf
//   };
// const options = {
//   method: 'POST',
//   headers: { 'content-type': 'application/x-www-form-urlencoded' },
//   data: qs.stringify(data),
//   url: 'https://api.cercanumeripro.it/fiscal-count.php/'
// };  
//   try {
//    const response = await axios(options);
//     console.log("response.data")
//     console.log(response.data)
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// }

export const adminService_calendarEventsByMonth = async (month) => {
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true,
      params: { month: month }

    }
    const response = await axios.get(API_URL + "calendarevent", config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

export const adminService_calendar_by_agents = async (agents) => {
  
  
  try {
    var config = {
      headers: authHeader(),
      mode: "cors",
      withCredentials: true
        }

    const response = await axios.post(API_URL + "calendareventbyagents",agents, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw (error);
  }
}

