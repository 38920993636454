import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, FormText, Container, Row, Col } from 'reactstrap';
import { MenuItem, Box, Typography, TextField, Select, FormControl, Stack, Chip, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from "react";
import { requestsforproperty, createRequest, addRequestForm, agentByPropertyId } from "../actions/admin";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Add from '@mui/icons-material/Add';
import DatePicker from "react-datepicker";
import moment from "moment";
import FormLabel from '@mui/joy/FormLabel';
import Dropzone from 'react-dropzone-uploader';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';


const NewRequestModal = (props) => {
    const filter = createFilterOptions();
    const [isOwnerSubmitDisabled, setIsOwnerSubmitDisabled] = useState(false);
    const [isClientDisable, setIsClientDisable] = useState(true);
    const [isAgentDisable, setIsAgentDisable] = useState(true);
    const [clientData, setClientData] = useState({

        clientId: "",
        clientFirstname: "",
        clientLastname: "",
        clientCell: [""],
        clientEmail: "",
        clientCountry: "",
        info: ""

    });

    const dispatch = useDispatch();
    const [apiData, setApiData] = useState({});

    useEffect(() => {
        dispatch(addRequestForm())

            .then((data) => {
                setApiData({
                    ...apiData,
                    clientList: data.clientList,
                    propertyList: data.propertyList,
                    countryList: data.countryList,
                    tagList: data.tagList,
                    agentList: data.userList,
                    regionList: data.regionList
                })
            })
            .catch((error) => {
                alert(error.response.data.message);
            });

    }, []);
    const handleClientChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        setClientData({
            ...clientData,
            [name]: value
        });

    };
    const removeCell = (index) => {
        let cells = clientData.clientCell;
        cells.splice(index, 1);
        setClientData({
            ...clientData,
            clientCell: cells
        })
    };

    const addCell = () => {
        let cells = clientData.clientCell;
        cells.push("");
        setClientData({
            ...clientData,
            clientCell: cells
        })
    }

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        props.setFormData({
            ...props.formData,
            [name]: value
        });

    };
    const handleChangeTagsNew = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            tagSelected: typeof value === 'string' ? value.split(',') : value
        });
    };

    // const handleChangePropertyPorpose = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     props.setFormData({
    //         ...props.formData,
    //         propertyIdProposeList: typeof value === 'string' ? value.split(',') : value
    //     });
    // };

    const handleChangePropertyProposeAutoComplete = (event, val) => {
        props.setFormData({
            ...props.formData,
            propertyIdProposeList: val.map(v => v.id)
        });
    };

    const handleChangeRegion = (event) => {
        const {
            target: { value },
        } = event;
        props.setFormData({
            ...props.formData,
            regionList: typeof value === 'string' ? value.split(',') : value
        });
    };


    const handleChangeProperty = (event) => {

        if (event != null) {
            let propName = apiData.propertyList.filter(t => t.id == event.id).map(t => t.name);
            dispatch(agentByPropertyId(event.id))
                .then((data) => {
                    props.setFormData({
                        ...props.formData,
                        propertyId: event.id,
                        propertyName: propName[0],
                        agentName: data.username
                    });
                })
                .catch((error) => {
                    alert(error.response.data.message);
                });
        }

    };

    const handleChangeNewFollowUpDate = (event) => {
        props.setFormData({
            ...props.formData,
            createDateAgent: event
        });
    };
    const handleChangeCell = (index, event) => {
        let data = clientData.clientCell;
        data[index] = event.target.value;
        setClientData({
            ...clientData,
            clientCell: data
        });
    };

    const handleChangeNewDateAgent = (event) => {
        props.setFormData({
            ...props.formData,
            createDateAgent: event
        });
    };

    const validateCell = (index, e) => {
        var passwdRex = /^\d+$/;
        let ret = true;
        let validate = props.formData.formErrors;
        if (passwdRex.test(e.target.value)) {
            validate.clientCell[index] = 'has-success';
        } else {
            validate.clientCell[index] = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };


    const handleSubmit = (event) => {

        let clientObj = props.formData.clientObj;
        if (clientObj === undefined) {
            clientObj = { id: null };
        }
        if (!clientObj.id || clientObj.id === null) {
            clientObj.id = clientData.clientId;
            clientObj.firstname = clientData.clientFirstname;
            clientObj.lastname = clientData.clientLastname;
            clientObj.cell = clientData.clientCell;
            clientObj.email = clientData.clientEmail;
            clientObj.countryId = clientData.clientCountry;
            clientObj.info = clientData.clientInfo;
            props.formData.clientObj = clientObj;
        }
        if (props.formData.followupDate) {
            props.formData.followupDate = moment(props.formData.followupDate).format("YYYY-MM-DD");
        }
        if (props.formData.createDateAgent) {
            props.formData.createDateAgent = moment(props.formData.createDateAgent).format("YYYY-MM-DD");
        }
        dispatch(createRequest(props.formData))
            .then((data) => {
                props.toggleModalNew();
                dispatch(requestsforproperty())
                    .then((data) => {
                        props.realoadList(data);
                        setClientData({
                            clientId: "",
                            clientFirstname: "",
                            clientLastname: "",
                            clientCell: [" "],
                            clientEmail: "",
                            clientCountry: "",
                            info: ""
                        });
                    })
                    .catch(() => {
                        console.log("Error");
                    });
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
        event.preventDefault();
    };
    const validateEmailAfter = (e) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let ret = true;
        let validate = props.formData.formErrors;

        if (emailRex.test(e)) {
            validate.clientEmail = 'has-success';
        } else {
            validate.clientEmail = 'has-danger';
            ret = false;
        }

        props.setFormData({
            ...props.formData,
            formErrors: validate
        });
        return ret;
    };

    return (

        <div>
            <Modal id="modalNew" className="modal-dialog-centered modal-xl" isOpen={props.showModalNew} toggle={props.toggleModalNew} >
                <ModalHeader>Nuova richiesta</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Container>
                            <Row xs="1" sm="1" md="2" lg="3" xl="3">
                                <Col>
                                    <FormGroup>
                                        <Label for="createDateAgent">
                                            Data
                                        </Label>
                                        <DatePicker

                                            selected={props.formData.createDateAgent}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(e) => {
                                                handleChangeNewDateAgent(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Label for="propertyId">
                                        Immobile
                                    </Label>
                                    <Autocomplete
                                        value={props.formData.propertyName || ""}
                                        onChange={(e, newValue) => {
                                            handleChangeProperty(newValue);
                                        }}

                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="propertyId"
                                        options={apiData.propertyList}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.name;
                                        }}
                                        renderOption={(props, option) => (
                                            <AutocompleteOption {...props}>
                                                {option.name}
                                            </AutocompleteOption>
                                        )}
                                        sx={{ minWidth: 230 }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                            />
                                        )}


                                    />
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="agentName">
                                            Agente
                                        </Label>
                                        <Select
                                            type="select"
                                            name="agentName"
                                            id="agentName"
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.agentName || ""}
                                            onChange={(e) => {
                                                handleChange(e);

                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {apiData.agentList && apiData.agentList.map(t => (
                                                <MenuItem
                                                    key={t.agentName}
                                                    value={t.username}
                                                >
                                                    {t.username}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>

                                {/* <Col>
                                    <FormGroup>
                                        <Label for="propertyIdProposeList">
                                            Immobili proposti
                                        </Label>
                                        <Select
                                            type="select"
                                            name="propertyIdProposeList"
                                            id="propertyIdProposeList"
                                            autocomplete
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.propertyIdProposeList || []}
                                            onChange={handleChangePropertyPorpose}
                                            renderValue={(selected) => (
                                                <Stack gap={1} direction="row" flexWrap="wrap">
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={apiData.propertyList.filter(t => t.id == value).map(t => t.name)} />
                                                    ))}
                                                </Stack>
                                            )}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {apiData.propertyList && apiData.propertyList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormGroup>
                                </Col> */}
                                <Col>
                                    <FormGroup>
                                        <Label for="propertyIdProposeList">
                                            Immobili proposti
                                        </Label>
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            <Autocomplete
                                                values={props.formData.propertyIdProposeList || []}
                                                onChange={handleChangePropertyProposeAutoComplete}
                                                multiple
                                                sx={{ minWidth: 230 }}
                                                name="propertyIdProposeList"
                                                id="propertyIdProposeList"

                                                options={apiData.propertyList}
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    // Regular option
                                                    return option.name;
                                                }}

                                                isOptionEqualToValue={(option, value) => option.name === value.name}

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label=""
                                                        placeholder=""
                                                    />
                                                )}
                                            />

                                        </Stack>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="topRequest">
                                            Cliente Top
                                        </Label>
                                        <Checkbox
                                            name="topRequest"
                                            id="topRequest"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Label>Email Cliente</Label>
                                    <Autocomplete
                                        value={clientData.clientEmail || ""}
                                        onChange={(e, newValue) => {
                                            if (typeof newValue === 'string') {
                                                if (newValue.startsWith('Aggiungi ')) {
                                                    newValue = newValue.substring(9);
                                                }
                                                if (!validateEmailAfter(newValue)) {
                                                    setIsOwnerSubmitDisabled(true);
                                                } else {
                                                    setIsOwnerSubmitDisabled(false);
                                                }
                                                validateEmailAfter(newValue);
                                                setClientData({
                                                    ...clientData,
                                                    clientEmail: newValue
                                                });


                                            } else if (newValue && newValue.inputValue) {

                                                setClientData({
                                                    clientId: newValue.id,
                                                    clientEmail: newValue.email,
                                                    clientFirstname: newValue.firstname,
                                                    clientLastname: newValue.lastname,
                                                    clientCountry: newValue.countryId,
                                                    clientCell: newValue.cell || [""],
                                                    clientInfo: newValue.info
                                                });


                                            } else {
                                                if (!newValue) {
                                                    setIsOwnerSubmitDisabled(true);
                                                    return;
                                                }
                                                if (newValue.email.startsWith('Aggiungi ')) {

                                                    setIsClientDisable(false);
                                                    newValue.email = newValue.email.substring(9);
                                                }
                                                if (!validateEmailAfter(newValue.email)) {
                                                    setIsOwnerSubmitDisabled(true);
                                                } else {
                                                    setIsOwnerSubmitDisabled(false);
                                                }


                                                setClientData({
                                                    clientId: newValue.id,
                                                    clientEmail: newValue.email,
                                                    clientFirstname: newValue.firstname,
                                                    clientLastname: newValue.lastname,
                                                    clientCountry: newValue.countryId,
                                                    clientCell: newValue.cell || [""],
                                                    clientInfo: newValue.info
                                                });
                                            }


                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = apiData.clientList.some((option) => inputValue === option.email);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    email: `Aggiungi ${inputValue}`
                                                });
                                            }
                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="clientEmail"
                                        options={apiData.clientList}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.email;
                                        }}
                                        renderOption={(props, option) => (
                                            <AutocompleteOption {...props}>
                                                {option.email?.startsWith('Aggiungi ') && (
                                                    <ListItemDecorator>
                                                        <Add />
                                                    </ListItemDecorator>
                                                )}

                                                {option.email}
                                            </AutocompleteOption>
                                        )}
                                        sx={{ minWidth: 230 }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                error={props.formData.formErrors.clientEmail === 'has-danger'}
                                            //helperText="Email errata"
                                            />
                                        )}

                                    />
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label for="clientFirstname">
                                            Nome Cliente
                                        </Label>
                                        <Input
                                            type="text"
                                            name="clientFirstname"
                                            id="clientFirstname"
                                            required
                                            disabled={isClientDisable}
                                            value={(clientData.clientFirstname) || ""}
                                            onChange={(e) => {
                                                handleClientChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="clientLastname">
                                            Cognome Cliente
                                        </Label>
                                        <Input
                                            type="text"
                                            name="clientLastname"
                                            id="clientLastname"
                                            disabled={isClientDisable}
                                            required
                                            value={clientData.clientLastname || ""}
                                            onChange={(e) => {
                                                handleClientChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="clientCell">
                                            Cellulare Cliente
                                            <IconButton onClick={addCell}>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Label>
                                        {
                                            clientData.clientCell && clientData.clientCell.map((cellu, index) => (
                                                <>
                                                    <Row>
                                                        <Col xs="9">
                                                            <Input
                                                                type="text"
                                                                name="clientCell"
                                                                id="clientCell"
                                                                disabled={isClientDisable}
                                                                //valid={props.formData.clientCell[index] === 'has-success'}
                                                                //invalid={props.formData.clientCell[index] === 'has-danger'}
                                                                value={cellu ? cellu : ""}
                                                                onChange={(e) => {
                                                                    //validateCell(index, e);
                                                                    handleChangeCell(index, e);
                                                                }}
                                                            />
                                                            {/* <FormFeedback>
                                                        Numero di telefono non valido
                                                    </FormFeedback>
                                                    <FormFeedback valid>
                                                        Numero di telefono valido
                                                    </FormFeedback> */}
                                                        </Col>
                                                        <Col xs="3">
                                                            {index > 0 &&
                                                                <IconButton color="error" onClick={() => { removeCell(index) }}>
                                                                    <Delete />
                                                                </IconButton>
                                                            }
                                                        </Col>

                                                    </Row>
                                                </>
                                            ))
                                        }
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="clientCountry">
                                            Paese Cliente
                                        </Label>
                                        <Select
                                            type="select"
                                            name="clientCountry"
                                            id="clientCountry"
                                            disabled={isClientDisable}
                                            sx={{ minWidth: 230 }}
                                            value={clientData.clientCountry || ""}
                                            onChange={(e) => {
                                                handleClientChange(e);

                                            }}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {apiData.countryList && apiData.countryList.map(t => (
                                                <MenuItem
                                                    key={t.code}
                                                    value={t.code}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="clientInfo">
                                            Info Cliente
                                        </Label>
                                        <Input
                                            type="text"
                                            disabled={isClientDisable}
                                            name="clientInfo"
                                            id="clientInfo"
                                            value={clientData.clientInfo || ""}
                                            onChange={(e) => {
                                                handleClientChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="regionList">
                                            Regione
                                        </Label>
                                        <Select
                                            type="select"
                                            name="regionList"
                                            id="regionList"
                                            multiple
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.regionList || []}
                                            onChange={(e) => {
                                                handleChangeRegion(e);

                                            }}
                                            renderValue={(selected) => (
                                                <Stack gap={1} direction="row" flexWrap="wrap">
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={apiData.regionList.filter(t => t.id == value).map(t => t.name)} />
                                                    ))}
                                                </Stack>
                                            )}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {apiData.regionList && apiData.regionList.map(t => (
                                                <MenuItem
                                                    key={t.name}
                                                    value={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Col>

                                <Col >
                                    <FormGroup>
                                        <Label for="radius">
                                            Raggio Km
                                        </Label>
                                        <Input
                                            type="number"
                                            name="radius"
                                            id="radius"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="minMq">
                                            Min MQ
                                        </Label>
                                        <Input
                                            type="number"
                                            name="minMq"
                                            id="minMq"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="maxMq">
                                            Max MQ
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxMq"
                                            id="maxMq"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="minPrice">
                                            Prezzo Min
                                        </Label>
                                        <Input
                                            type="number"
                                            name="minPrice"
                                            id="minPrice"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="maxPrice">
                                            Prezzo Max
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxPrice"
                                            id="maxPrice"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label for="maxRooms">
                                            Camere da letto
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxRooms"
                                            id="maxRooms"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="tags">
                                            Tags
                                        </Label>
                                        <Select
                                            type="select"
                                            name="tags"
                                            id="tags"
                                            multiple
                                            Autocomplete
                                            sx={{ minWidth: 230 }}
                                            value={props.formData.tagSelected || []}
                                            onChange={handleChangeTagsNew}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                            ></MenuItem>
                                            {apiData.tagList && apiData.tagList.map(t => (
                                                <MenuItem
                                                    key={t.tag}
                                                    value={t.id}
                                                >
                                                    {t.tag}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormGroup>
                                </Col>
                                <Row >

                                    <FormGroup>
                                        <Label for="followupDate">
                                            Data Follow Up
                                        </Label>
                                        <DatePicker

                                            selected={props.formData.followupDate}
                                            dateFormat="yyyy-MM-dd"
                                            onChange={(e) => {
                                                handleChangeNewFollowUpDate(e);
                                            }}

                                        />
                                    </FormGroup>
                                </Row >
                                <Row >
                                    <FormGroup>
                                        <Label for="description">
                                            Note
                                        </Label>
                                        <textarea className="form-control" id="description" name="description" rows="5"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                        ></textarea>
                                    </FormGroup>



                                </Row>

                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-primary custom-btn" type="submit">
                            Salva
                        </Button>{' '}
                        <Button className="btn btn-primary custom-btn" onClick={() => {
                            props.toggleModalNew(); setClientData({
                                clientId: "",
                                clientFirstname: "",
                                clientLastname: "",
                                clientCell: [""],
                                clientEmail: "",
                                clientCountry: "",
                                info: ""
                            });
                            setIsClientDisable(true);
                        }}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div >
    )
}

export default NewRequestModal;